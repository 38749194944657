import React, { Component , useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Myb2b from '../../services/myb2b';
import { Markup } from 'react-render-markup';
import i18next from 'i18next';

const Filactualite = () => {
    const [items,setItems] = useState([]);
    useEffect(() => {
        Myb2b.getPosts().then((res)=>{
            if(res.data.get){
                var newitems=[];
                res.data.data.forEach(newitem => {
                    newitems.push(<Item data={newitem} />);
                });
                setItems(newitems);
            }
        });
    }, []);

    return (<>
        <div className="loadMore" id="my-posts">
            {items}
        </div>
        <div className="central-meta item">
            <div className="user-post">
                <div className="friend-info">
                    <figure>
                    </figure>
                    <div className="friend-name">
                    <span>
                    {localStorage.getItem("langb2b")==="en" ?"No posts" : "Aucune publication"}
                    </span>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Filactualite;



const Item = (props) => {
    return (<>
        <div className="central-meta item">
            <div className="user-post">
                <div className="friend-info">
                    <figure>
                        <img onContextMenu={(e)=> e.preventDefault()} src={props.data.img_company} alt="" />
                    </figure>
                    <div className="friend-name">
                        <ins>
                            <Link to={props.data.url}><Markup markup={props.data.title} />	</Link>	
                            <span className="label label-info produit">{props.data.type}</span>
                        </ins>
                        <span>
                        {props.data.date}	
                        </span>
                    </div>
                    <div className="post-meta">
                        <Link to={props.data.url}>
                        <img onContextMenu={(e)=> e.preventDefault()} src={props.data.img} alt={props.data.title} className="w-100" />
                        </Link>	
                        <div className="we-video-info">
                            <ul>
                                <li id="like_button">
                                    <span className="like" data-toggle="tooltip" title data-original-title="like">
                                        <i className="fa fa-heart-o" />													
                                        <ins>0</ins>
                                    </span>
                                </li>
                                <li>
                                    <span className="comment" data-toggle="tooltip" title data-original-title="Comments">
                                        <i className="fa fa-comments-o" />
                                        <ins id="ins_3911">0</ins>
                                    </span>
                                </li>
                                <li id="share_button">
                                    <span className="views" data-toggle="tooltip" title data-original-title="Partage">
                                        <i className="fa fa-share-alt" />														
                                        <ins>0</ins>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="description">
                            <div className="coment-area">
                                <ul className="we-comet" id="comments_3911" style={{maxHeight: '224px', overflow: 'auto'}}>
                                </ul>
                                <div className="post-comt-box">
                                    <input type="text" placeholder="Commenter" id="comment_3911" style={{paddingLeft: '15px', background: '#f3f3f3 none repeat scroll 0 0', borderColor: 'transparent', borderRadius: '20px', color: '#000000', fontSize: '13.5px', fontWeight: 500, height: '40px', lineHeight: '16px', width: '94%'}} />
                                    <i id="send_comment" className="fa fa-send" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

