
import React, { Component } from 'react';
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Experts from '../../services/experts';
import Modal, { closeStyle } from 'simple-react-modal';
import ReactPlayer from "react-player"
import MetaTags from 'react-meta-tags';
import Auth from '../../services/Auth'
import Login from '../header/loginheader';
import { useParams, Redirect } from 'react-router-dom';
import { Markup } from 'react-render-markup';
import ProposerProjet from './proposerProjet';
import ProposerEmploi from './proposerEmploi';
import Slider from './sliderListeEexpert';
import Typed from 'react-typed';
import { b2bNameSite, b2bDescriptionSite, backUrl } from '../../config.json';
import BtnPartage from '../Slider/btnPartage';
import i18next from 'i18next';
import "./css/basic.css";
import "./css/layout.css";
import "./css/blogs.css";
import "./css/ionicons.css";
import "./css/magnific-popup.css";
import "./css/animate.css";
import "./css/owl.carousel.css";
import "./css/gradient.css";
import "./css/new-skin/new-skin.css";
import "./css/demos/demo-1-colors.css";
import './detail.css';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga'
import parse from 'react-html-parser'
function Detail(prod) {
    let { slug } = useParams();
    return (
        <Index t={prod.t} slug={slug} />
    );
}
export default withTranslation()(Detail)
class Index extends Component {
    constructor(props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        super(props)
        this.state = {
            slug: props.slug,
            data: null,
            redirect: <></>,
            accueilShow: true,
            videosShow: false,
            formationShow: false,
            experienceShow: false,
            competencesShow: false,
            contactShow: false,
            htmlModaleYoutube: <></>,
            modaleyoutube: false,
            modaleLoginContact: false,
            modaleLoginProposerEmploi: false,
            modaleLoginProposerProjet: false,
            htmlModaleLogin: <></>,
            htmlModaleProposerEmploi: <></>,
            htmlModaleProposerProjet: <></>,
            userLogin: false,
            dataUser: null,
            proposer: "",
            prop_emploi: null
        }
        Auth.isLogin().then((res) => {
            if (res.data != false) {
                this.setState({
                    userLogin: true,
                    dataUser: res.data,
                    proposer: "proposer"
                });
            } else {
                this.setState({
                    userLogin: false,
                    dataUser: null,
                    proposer: ""
                });
            }
        });

    }
    async componentDidMount() {
        Experts.getDetail(this.state.slug).then((res) => {
            if (res.data.get) {
                this.setState({
                    data: res.data.data
                });
                if (this.state.dataUser) {

                    Experts.prop_emploi(res.data.data.userId, this.state.dataUser.id_user).then((res) => {

                        
                        
                        if (res.data.get) {
                            
                            this.setState({
                                prop_emploi: true
                            });
                        }
                    });
                }
            } else {
                this.setState({ redirect: <Redirect to={"/" + i18next.language + '/experts'} /> });
            }
        });

    }
    selectItem = (e) => {
        var items = document.getElementsByClassName("active");
        var i;
        for (i = 0; i < items.length; i++) {
            items[i].classList.remove("active");
        }
        e.currentTarget.classList.add('active');
    }
    clickAccueil = (e) => {
        this.selectItem(e);
        this.setState({
            accueilShow: true,
            videosShow: false,
            formationShow: false,
            experienceShow: false,
            competencesShow: false,
            contactShow: false,
        });
        window.scrollTo(0, document.getElementById("about-card").offsetTop - 80);
    }
    clickVideos = (e) => {
        this.selectItem(e);
        this.setState({
            accueilShow: false,
            videosShow: true,
            formationShow: false,
            experienceShow: false,
            competencesShow: false,
            contactShow: false,
        });
        window.scrollTo(0, document.getElementById("works-card").offsetTop - 80);
    }
    clickFormation = (e) => {
        this.selectItem(e);
        this.setState({
            accueilShow: false,
            videosShow: false,
            formationShow: true,
            experienceShow: false,
            competencesShow: false,
            contactShow: false,
        });
        window.scrollTo(0, document.getElementById("resume-card").offsetTop - 80);
    }
    clickExperience = (e) => {
        this.selectItem(e);
        this.setState({
            accueilShow: false,
            videosShow: false,
            formationShow: false,
            experienceShow: true,
            competencesShow: false,
            contactShow: false,
        });
        window.scrollTo(0, document.getElementById("experience-card").offsetTop - 80);
    }
    clickCompetences = (e) => {
        this.selectItem(e);
        this.setState({
            accueilShow: false,
            videosShow: false,
            formationShow: false,
            experienceShow: false,
            competencesShow: true,
            contactShow: false,
        });
        window.scrollTo(0, document.getElementById("blog-card").offsetTop - 80);
    }
    clickContact = (e) => {
        if (this.state.userLogin) {
            this.selectItem(e);
            this.setState({
                accueilShow: false,
                videosShow: false,
                formationShow: false,
                experienceShow: false,
                competencesShow: false,
                contactShow: true,
            });
            window.scrollTo(0, document.getElementById("contacts-card").offsetTop - 80);
        } else {
            this.setState({
                modaleLoginContact: true,
                htmlModaleLogin: <Login close={this.close.bind(this)} />
            });
        }

    }

    close = () => {
        this.setState({
            modaleyoutube: false,
            modaleLoginContact: false,
            modaleLoginProposerEmploi: false,
            modaleLoginProposerProjet: false,
            htmlModaleYoutube: <></>,
            htmlModaleLogin: <></>
        })
    }

    handleLoginContactClick = () => {
        this.setState({
            modaleLoginContact: true,
            htmlModaleLogin: <Login close={this.close.bind(this)} />
        });
    }
    handleLoginProposerEmploiClick = () => {
        if (this.state.userLogin) {
            if (this.state.dataUser.valid_b2b == "ok") {
                this.setState({
                    modaleLoginProposerEmploi: true,
                    htmlModaleLogin: <ProposerEmploi expertid={this.state.data.userId} />
                });
            } else {
                this.setState({
                    modaleLoginProposerEmploi: true,
                    htmlModaleLogin: <EncoursValidation t={this.props.t} />
                });
            }

        } else {
            this.setState({
                modaleLoginProposerEmploi: true,
                htmlModaleLogin: <Login close={this.close.bind(this)} />
            });
        }
    }
    handleLoginProposerProjetClick = () => {

        if (this.state.userLogin) {
            if (this.state.dataUser.valid_b2b == "ok") {
                this.setState({
                    modaleLoginProposerProjet: true,
                    htmlModaleLogin: <ProposerProjet expertid={this.state.data.userId} />
                });
            } else {
                this.setState({
                    modaleLoginProposerProjet: true,
                    htmlModaleLogin: <EncoursValidation t={this.props.t} />
                });
            }
        } else {
            this.setState({
                modaleLoginProposerProjet: true,
                htmlModaleLogin: <Login close={this.close.bind(this)} />
            });
        }
    }

    render() {

        const { t } = this.props
        if (this.state.data) {

            return (
                <>
                    <div className="page-expert">
                        {this.state.redirect}

                        <MetaTags>
                            <title>{this.state.data ? this.state.data.first_name + " " + this.state.data.last_name + " | " : <></>}{b2bNameSite} | {this.state.data ? parse(this.state.data.description.replace(/<[^>]*>?/gm, '').substring(0, 50)) + '...' : ""} </title>
                            <meta property="og:image" content={this.state.data ? this.state.data.image : <></>} />
                            <meta name="description" content={this.state.data ? <>{b2bNameSite} | {this.state.data.description}</> : <>{b2bNameSite}</>} />
                        </MetaTags>
                        <div className="modal-login-hadeer">
                            <Modal
                                closeOnOuterClick={true}
                                show={this.state.modaleLoginContact}
                                onClose={this.close.bind(this)}
                            >
                                <div className="div-login-expert">
                                    <div className="connxtion_header">
                                        <h2 id="to_text_pupup--" className="size-titre-b2b grie-b2b">{t("VEUILLEZ-VOUS CONNECTER POUR POUVOIR CONTACTER")} <span className="place_post_title">{this.state.data ? this.state.data.first_name + " " + this.state.data.last_name : <></>}</span></h2>
                                        <div className="divbtnclose">
                                            <span className="close closemodalheader" onClick={this.close.bind(this)}>×</span>
                                        </div>
                                    </div>
                                    {this.state.htmlModaleLogin}
                                </div>
                            </Modal>
                        </div>
                        <div className={"modal-login-hadeer " + this.state.proposer}>
                            <Modal
                                closeOnOuterClick={true}
                                show={this.state.modaleLoginProposerProjet}
                                onClose={this.close.bind(this)}
                            >
                                <div className="div-login-expert">
                                    <div className="connxtion_header">
                                        <h2 id="to_text_pupup--" className="size-titre-b2b grie-b2b">{this.state.userLogin ? t("PROPOSER UN PROJET À") : t("VEUILLEZ-VOUS CONNECTER POUR POUVOIR PROPOSER UN PROJET À")} <span className="place_post_title">{this.state.data ? this.state.data.first_name + " " + this.state.data.last_name : <></>}</span></h2>
                                        <div className="divbtnclose">
                                            <span className="close closemodalheader" onClick={this.close.bind(this)}>×</span>
                                        </div>
                                    </div>
                                    {this.state.htmlModaleLogin}
                                </div>
                            </Modal>
                        </div>
                        <div className={"modal-login-hadeer " + this.state.proposer}>
                            <Modal
                                closeOnOuterClick={true}
                                show={this.state.modaleLoginProposerEmploi}
                                onClose={this.close.bind(this)}
                                style={{ with: "100%" }}
                            >
                                <div className="div-login-expert">
                                    <div className="connxtion_header">
                                        <h2 id="to_text_pupup--" className="size-titre-b2b grie-b2b">{this.state.userLogin ? t("PROPOSER UN EMPLOI POUR") : t("VEUILLEZ-VOUS CONNECTER POUR POUVOIR PROPOSER UN EMPLOI POUR")} <span className="place_post_title">{this.state.data ? this.state.data.first_name + " " + this.state.data.last_name : <></>}</span></h2>
                                        <div className="divbtnclose">
                                            <span className="close closemodalheader" onClick={this.close.bind(this)}>×</span>
                                        </div>
                                    </div>
                                    {this.state.htmlModaleLogin}
                                </div>
                            </Modal>
                        </div>
                        <div className="modal-login-hadeer modale-youtube">
                            <Modal
                                closeOnOuterClick={true}
                                show={this.state.modaleyoutube}
                                onClose={this.close.bind(this)}
                            >
                                {this.state.htmlModaleYoutube}
                            </Modal>
                        </div>
                        <div className="container opened" data-animation-in="fadeInLeft" data-animation-out="fadeOutLeft">
                            <header className="header-expert ">
                                <div className="profile">
                                    <div className="title">{this.state.data.first_name + " " + this.state.data.last_name}</div>
                                    <div className="div-content-typed">
                                        <Typed
                                            strings={[
                                                this.state.data.gouvernorat + " , " + this.state.data.ville,
                                                this.state.data.diplome + "(" + this.state.data.domaine + ")",
                                                this.state.data.type_compte,
                                                t("Experience") + ": " + this.state.data.experience,
                                            ]}
                                            typeSpeed={40}
                                            backSpeed={50}
                                            loop
                                        />
                                    </div>
                                </div>
                                <div className="top-menu">
                                    <ul>
                                        <li className="active" onClick={this.clickAccueil}>
                                            <a href="javascript:void(0)">
                                                <span className="icon span_accueil" />
                                                <span className="link">{t("PROFIL")}</span>
                                            </a>
                                        </li>
                                        <li onClick={this.clickFormation}>
                                            <a href="javascript:void(0)">
                                                <span className="icon span_formation" />
                                                <span className="link">{t("FORMATION")}</span>
                                            </a>
                                        </li>
                                        <li onClick={this.clickExperience}>
                                            <a href="javascript:void(0)">
                                                <span className="icon span_experience" />
                                                <span className="link">{t("EXPÉRIENCES")}</span>
                                            </a>
                                        </li>
                                        <li onClick={this.clickCompetences}>
                                            <a href="javascript:void(0)">
                                                <span className="icon span_portfolio" />
                                                <span className="link">{t("Compétences")}</span>
                                            </a>
                                        </li>
                                        <li onClick={this.clickVideos}>
                                            <a href="javascript:void(0)">
                                                <span className="icon span_videos" />
                                                <span className="link text-menu-video-mobile">{t("VIDÉO DE PRÉSENTATION")}</span>
                                                <span className="link text-menu-video">{t("VIDÉO DE PRÉSENTATION DE PRÉSENTATION")}</span>
                                            </a>
                                        </li>
                                        {(this.state.dataUser && this.state.dataUser.type == "societe") ?
                                            <li className="contact" onClick={this.clickContact}>
                                                <a href="javascript:void(0)">
                                                    <span className="icon span_contact" />
                                                    <span className="link">{t("CONTACT")}</span>
                                                </a>
                                            </li>
                                            : <></>}
                                        {(!this.state.userLogin) ?
                                            <li className="contact" onClick={this.clickContact}>
                                                <a href="javascript:void(0)">
                                                    <span className="icon span_contact" />
                                                    <span className="link">{t("CONTACT")}</span>
                                                </a>
                                            </li>
                                            : <></>}
                                    </ul>
                                </div>
                            </header>
                            
                            <BlockImageInfo t={t} prop_emploi={this.state.prop_emploi} data={this.state.data} dataUser={this.state.dataUser} clickProposerEmploi={this.handleLoginProposerEmploiClick} clickProposerProjet={this.handleLoginProposerProjetClick} isLogin={this.state.userLogin} />
                            <div id="BlockInfoExpert">
                                <Presentation t={t} active={this.state.accueilShow} data={this.state.data} />
                                <EducationsFormations t={t} active={this.state.formationShow} data={this.state.data} />
                                <ExperiencesProfessionnelles t={t} active={this.state.experienceShow} data={this.state.data} />
                                <Competences t={t} active={this.state.competencesShow} data={this.state.data} />
                                <VideoPresentation t={t} active={this.state.videosShow} data={this.state.data} />
                                {(this.state.dataUser && this.state.dataUser.type == "societe") ?
                                    <Contact t={t} active={this.state.contactShow} dataUser={this.state.dataUser} isLogin={this.state.userLogin} data={this.state.data} clickLoginContact={this.handleLoginContactClick} />
                                    : <></>}
                            </div>
                        </div>
                        <Slider />
                    </div>
                    <div id="bottom_buttons" style={{ backgroundColor: 'rgb(204, 64, 64)', width: '100%', height: '52px', textAlign: 'center', position: 'fixed', bottom: '0px', zIndex: 2147483647 }}>
                        {/* <a className="telephone_b2b" href={(this.state.userLogin && this.state.data && this.state.data.phone!="" )?"tel:+"+this.state.data.codegsm+this.state.data.phone:"#"}>
                        <div style={{backgroundColor: 'rgb(175, 170, 164)', width: '49.5%', height: '52px', display: 'inline-block', color: 'white', paddingTop: '5px', fontSize: '22px', marginLeft: '-5px'}}>
                            <i className="fa fa-phone" /> Appeler
                        </div>
                    </a> */}
                        <a href="#contact" onClick={this.clickContact} id="contacterlink2" role="tab" data-toggle="tab">
                            <div style={{ backgroundColor: 'rgb(204, 64, 64)', width: '48%', height: '52px', display: 'inline-block', color: 'white', paddingTop: '5px', fontSize: '22px' }}>
                                <i className="fa fa-envelope-o" /> {t("Contacter")}
                            </div>
                        </a>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    {this.state.redirect}
                    <Slider />
                </>
            );
        }

    }
}


class BlockImageInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    clickEmploi = (e) => {
        this.props.clickProposerEmploi();
    }
    clickProjet = (e) => {
        this.props.clickProposerProjet();
    }
    render() {
        const { t } = this.props
        if (this.props.data) {
            
            var url = "";
            if (i18next.language == "fr" || i18next.language == "fr-FR") {
                
                url=this.props.data.urlPartage;
            } else if (i18next.language == "en") {
                
                url=this.props.data.urlPartage.replace("/fr/", "/en/");
            } else {
                
                url=this.props.data.urlPartage;
            }
            return (
                <div className="card-started mb-1 mt-1 " id="home-card" style={{ height: "auto" }}>
                    <div className="profile no-photo pt-0">
                        {/* <div className="slide" style={{backgroundImage: 'url('+this.props.data.image+')'}} /> */}
                        <div className='continer-profile-photo'>
                            <figure className="profile-photo-expert">
                                <div className="profile-photo" style={{ backgroundImage: 'url(' + this.props.data.image + ')' }} />
                                <div className="profile-bg" />
                            </figure>
                        </div>
                        <div className="title text-uppercase">{this.props.data.first_name} {this.props.data.last_name}</div>
                        <div className="div-content-typed">
                            <Typed
                                strings={[
                                    this.props.data.poste_recherche,
                                    this.props.data.gouvernorat + " , " + this.props.data.ville,
                                    this.props.data.diplome + "(" + this.props.data.domaine + ")",
                                    this.props.data.type_compte,
                                    t("Experience") + ":" + this.props.data.experience,
                                ]}
                                typeSpeed={40}
                                backSpeed={50}
                                loop
                            />
                        </div>
                        {this.props.isLogin ? <>
                            {this.props.dataUser && this.props.data && this.props.dataUser.code_user != this.props.data.userId ? <>
                                {(this.props.data.expert_compte == "demandeurdemploi" && this.props.data.interes_freelance == "oui") ?
                                    <div className="contentent-btn-offre">
                                        {(this.props.dataUser && this.props.dataUser.type == "societe") ? <>
                                            
                                                {
                                                    this.props.prop_emploi == true ?
                                                    <div className="contact-button__wrapper" style={{ width: '60%', margin: '5px 0px 0px 0px' }}>
                                                        <button className="freelance-button btn-b2b btn-offre-p" disabled >{t("Vous avez déjà proposé un emploi à cet EXPERT")}</button>
                                                        </div>
                                                        :
                                                        <div className="contact-button__wrapper" style={{ width: '49%', margin: '5px 0px 0px 0px' }}>

                                                        <button className="freelance-button btn-b2b btn-offre-p" onClick={this.clickEmploi} >{t("Proposer un emploi")}</button>
                                                        </div>
                                                }
                                            
                                            <div className="btn-offre-hr" />
                                        </> : <></>}
                                        <div className="contact-button__wrapper" style={{ width: '49%', margin: '5px 0px 0px 0px' }}>
                                            <button className="freelance-button btn-b2b btn-offre-p" onClick={this.clickProjet}>{t("Proposer un projet")}</button>
                                        </div>
                                    </div>
                                    : <>
                                        {(this.props.data.expert_compte == "freelance" && this.props.data.interes_emploi == "oui") ?
                                            <div className="contentent-btn-offre">
                                                <div className="contact-button__wrapper" style={{ width: '49%', margin: '5px 0px 0px 0px' }}>
                                                    <button className="freelance-button btn-b2b btn-offre-p" onClick={this.clickProjet}>{t("Proposer un projet")}</button>
                                                </div>
                                                {(this.props.dataUser && this.props.dataUser.type == "societe") ? <>
                                                    <div className="btn-offre-hr" />
                                                    
                                                        {
                                                            this.props.prop_emploi == true ?
                                                            <div className="contact-button__wrapper" style={{ width: '60%', margin: '5px 0px 0px 0px' }}>
                                                                <button className="freelance-button btn-b2b btn-offre-p" disabled >{t("Vous avez déjà proposé un emploi à cet EXPERT")}</button>
                                                                </div>
                                                                :
                                                                <div className="contact-button__wrapper" style={{ width: '49%', margin: '5px 0px 0px 0px' }}>

                                                                    <button className="freelance-button btn-b2b btn-offre-p" onClick={this.clickEmploi} >{t("Proposer un emploi")}</button>
                                                                </div>
                                                        }
                                                    
                                                </> : <></>}
                                            </div>
                                            : <>
                                                {(this.props.data.expert_compte == "demandeurdemploi") ? <>
                                                    {(this.props.dataUser && this.props.dataUser.type == "societe") ? <>
                                                        <div className>
                                                            <div className="contact-button__wrapper" style={{ width: '100%', margin: '5px 0px 0px 0px' }}>
                                                                {
                                                                    this.props.prop_emploi == true ?
                                                                        <button className="freelance-button btn-b2b btn-offre-p" disabled >{t("Vous avez déjà proposé un emploi à cet EXPERT")}</button>
                                                                        :
                                                                        <button className="freelance-button btn-b2b btn-offre-p" onClick={this.clickEmploi} >{t("Proposer un emploi")}</button>
                                                                }

                                                            </div>
                                                        </div>
                                                    </> : <></>}
                                                </> : <>
                                                    {(this.props.data.expert_compte == "freelance") ?
                                                        <div className>
                                                            <div className="contact-button__wrapper" style={{ width: '100%', margin: '5px 0px 0px 0px' }}>
                                                                <button className="freelance-button btn-b2b btn-offre-p" onClick={this.clickProjet}>{t("Proposer un projet")}</button>
                                                            </div>
                                                        </div>
                                                        : <></>}
                                                </>}
                                            </>}
                                    </>}
                            </> : <></>}
                        </> : <>
                            {this.props.data ? <>
                                {(this.props.data.expert_compte == "demandeurdemploi" && this.props.data.interes_freelance == "oui") ?
                                    <div className="contentent-btn-offre">
                                        <div className="contact-button__wrapper" style={{ width: '49%', margin: '5px 0px 0px 0px' }}>
                                            <button className="emploi-button btn-b2b btn-offre-p" onClick={this.clickEmploi}>{t("Proposer un emploi")}</button>
                                        </div>
                                        <div className="btn-offre-hr" />
                                        <div className="contact-button__wrapper" style={{ width: '49%', margin: '5px 0px 0px 0px' }}>
                                            <button className="freelance-button btn-b2b btn-offre-p" onClick={this.clickProjet}>{t("Proposer un projet")}</button>
                                        </div>
                                    </div>
                                    : <>
                                        {(this.props.data.expert_compte == "freelance" && this.props.data.interes_emploi == "oui") ?
                                            <div className="contentent-btn-offre">
                                                <div className="contact-button__wrapper" style={{ width: '49%', margin: '5px 0px 0px 0px' }}>
                                                    <button className="freelance-button btn-b2b btn-offre-p" onClick={this.clickProjet}>{t("Proposer un projet")}</button>
                                                </div>
                                                <div className="btn-offre-hr" />
                                                <div className="contact-button__wrapper" style={{ width: '49%', margin: '5px 0px 0px 0px' }}>
                                                    <button className="emploi-button btn-b2b btn-offre-p" onClick={this.clickEmploi}>{t("Proposer un emploi")}</button>
                                                </div>
                                            </div>
                                            : <>
                                                {(this.props.data.expert_compte == "demandeurdemploi") ?
                                                    <div className>
                                                        <div className="contact-button__wrapper" style={{ width: '100%', margin: '5px 0px 0px 0px' }}>
                                                            <button className="freelance-button btn-b2b btn-offre-p" onClick={this.clickEmploi}>{t("Proposer un emploi")}</button>
                                                        </div>
                                                    </div>
                                                    : <>
                                                        {(this.props.data.expert_compte == "freelance") ?
                                                            <div className>
                                                                <div className="contact-button__wrapper" style={{ width: '100%', margin: '5px 0px 0px 0px' }}>
                                                                    <button className="freelance-button btn-b2b btn-offre-p" onClick={this.clickProjet}>{t("Proposer un projet")}</button>
                                                                </div>
                                                            </div>
                                                            : <></>}
                                                    </>}
                                            </>}
                                    </>}
                            </> : <></>}
                        </>}

                        <div className="social mt-1">
                            <h3 className="mb-1">{localStorage.getItem("langb2b") === "fr" ? t("PARTAGER CE PROFIL SUR ") : t("SHARE THIS PROFILE ON ")}:</h3>
                            <BtnPartage
                                image={this.props.data.image}
                                title={this.props.data.first_name + " " + this.props.data.last_name}
                                content=""
                                url={url} />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (<div className="card-started mb-1 mt-1 " id="home-card">
                <div className="profile no-photo">
                    <div className="slide" />
                    <div className="title"><span className="loder-span"></span></div>


                    <div className="social">
                        <h3>{t("Partagez ce profil sur")}:</h3>
                        <div className="essb_links essb_counter_modern_right essb_displayed_shortcode essb_share essb_template_circles-retina essb_template_blocks-retina essb_675748097 print-no" id="essb_displayed_shortcode_675748097" data-essb-postid={3} data-essb-position="shortcode" data-essb-button-style="icon" data-essb-template="circles-retina essb_template_blocks-retina" data-essb-counter-pos="right" data-essb-url="https://www.b2b.tn/fr/expert/ghazi-ben-mahmoud/" data-essb-twitter-url="https://www.b2b.tn/fr/expert/ghazi-ben-mahmoud/" data-essb-instance={675748097}>

                        </div>
                    </div>
                </div>
            </div>);
        }
    }
}
class Presentation extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    render() {
        const { t } = this.props
        if (this.props.data) {
            return (
                <div className={"card-inner animated mb-1 mt-1 " + (this.props.active ? "active" : "")} id="about-card">
                    <div className="card-wrap pt-2 pb-2">
                        <div className="content about">
                            {/* title */}
                            <div className="title-expert">{t("PRÉSENTATION DE")} <span className='grie-b2b'>{this.props.data.first_name + " " + this.props.data.last_name}</span>: </div>
                            {/* content */}
                            <div className="row">
                                <div className="col col-d-12 col-t-12 col-m-12 border-line-v resume-block">
                                    <div className="text-box">
                                        <div className="row" id="block-info-expert">
                                            <div className="col-md-7 p-0">
                                                <div className="info-list">
                                                    <ul>
                                                        <li>
                                                            <i className='icon-profil-expert' style={{ backgroundImage: "url(/assets/img/icon/profil.png)" }}></i>
                                                            <div>
                                                                <strong className='place_post_title'>{this.props.data.poste_recherche != "" ? this.props.data.poste_recherche : this.props.data.domaine} </strong>
                                                                <strong className='place_post_title'>{this.props.data.age} ans</strong>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <i className='icon-profil-expert' style={{ backgroundImage: "url(/assets/img/icon/diplome.png)" }}></i>
                                                            <div>
                                                                <strong className='grie-b2b'>{t("Diplôme")} : </strong>
                                                                <strong className='place_post_title'>{this.props.data.diplome}</strong>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <i className='icon-profil-expert' style={{ backgroundImage: "url(/assets/img/icon/niveau-dexp.png)" }}></i>
                                                            <div>
                                                                <strong className='grie-b2b'>{t("Experience")} : </strong>
                                                                <strong className='place_post_title' style={{ textTransform: 'lowercase' }}>{this.props.data.experience}</strong>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <i className='icon-profil-expert' style={{ backgroundImage: "url(/assets/img/icon/disponibilite.png)" }}></i>
                                                            <div>
                                                                <strong className='grie-b2b'>{t("Disponibilité")} : </strong>
                                                                <strong className='place_post_title'>{this.props.data.disponibilite}</strong>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <i className='icon-profil-expert' style={{ backgroundImage: "url(/assets/img/icon/salaire-actuel.png)" }}></i>
                                                            <div>
                                                                <strong className='grie-b2b'>{t("Salaire Actuel")} : </strong>
                                                                <strong className='place_post_title' >{this.props.data.salaire_actuel != "" ? this.props.data.salaire_actuel : "-"}</strong>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-5 p-0">
                                                <div className="info-list">
                                                    <ul>
                                                        <li>
                                                            <i className='icon-profil-expert' style={{ backgroundImage: "url(/assets/img/icon/civilite.png)" }}></i>
                                                            <div>
                                                                <strong className='grie-b2b'>{t("Etat Civil")} : </strong>
                                                                <strong className='place_post_title' style={{ textTransform: 'initial' }}>{this.props.data.etat_civil}</strong>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <i className='icon-profil-expert' style={{ backgroundImage: "url(/assets/img/icon/type-de-contrat.png)" }}></i>
                                                            <div>
                                                                <strong className='grie-b2b'>{t("Type de contrat")}: </strong>
                                                                <strong className='place_post_title'>{this.props.data.type_emploi}</strong>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <i className='icon-profil-expert' style={{ backgroundImage: "url(/assets/img/icon/domaine.png)" }}></i>
                                                            <div>
                                                                <strong className='grie-b2b'>{t("Domaines")} : </strong>
                                                                <strong className='place_post_title'>{this.props.data.domaine != "" ? this.props.data.domaine : "-"}</strong>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <i className='icon-profil-expert' style={{ backgroundImage: "url(/assets/img/icon/permis.png)" }}></i>
                                                            <div>
                                                                <strong className='grie-b2b'>{t("Permis de conduite")} : </strong>
                                                                <strong className='place_post_title'>{this.props.data.conduite} </strong>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <i className='icon-profil-expert' style={{ backgroundImage: "url(/assets/img/icon/salaire-demande.png)" }}></i>
                                                            <div>
                                                                <strong className='grie-b2b'>{t("Salaire souhaité")} : </strong>
                                                                <strong className='place_post_title'>{this.props.data.salaire_souhaite}</strong>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <h4 className="subhead grie-b2b mb-1 size-titre-b2b"><span className="black-b2b text-uppercase" >{this.props.data.first_name} {this.props.data.last_name}</span> {t("en quelques mots")}</h4>
                                        <p className="grie-b2b mt-0 ml-4">{this.props.data.description}</p>
                                    </div>
                                </div>
                                <div className="clear" />
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={"card-inner animated mb-1 mt-1 " + (this.props.active ? "active" : "")} id="about-card">
                    <div className="card-wrap pt-2 pb-2">
                        <div className="content about">
                            <div className="title-expert">{t("PRÉSENTATION")} : </div>
                            <div className="row">
                                <div className="col col-d-12 col-t-12 col-m-12 border-line-v resume-block">
                                    <div className="text-box">
                                        <div className="row" id="block-info-expert">
                                            <div className="col-md-6">
                                                <div className="info-list">
                                                    <ul>
                                                        <li><strong>{t("Diplôme")} : </strong><span className="loder-span"></span></li>
                                                        <li><strong>{t("Experience")} : </strong><span className="loder-span"></span></li>
                                                        <li><strong>{t("Date de naissance")} : </strong><span className="loder-span"></span></li>
                                                        <li><strong>{t("Etat Civil")} : </strong><span className="loder-span"></span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="info-list">
                                                    <ul>
                                                        <li><strong>{t("Type de compte")}: </strong><span className="loder-span"></span></li>
                                                        <li><strong>{t("Disponibilité")} : </strong><span className="loder-span"></span></li>
                                                        <li><strong>{t("Permis de conduite")} : </strong><span className="loder-span"></span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <h4 className="subhead grie-b2b mb-1 size-titre-b2b"><span className="black-b2b"></span> {t("en quelques mots")}</h4>
                                        <p className="grie-b2b mt-0 ml-4">
                                            <span className="loder-p"></span>
                                            <span className="loder-p"></span>
                                            <span className="loder-p"></span>
                                            <span className="loder-p"></span>
                                            <span className="loder-p"></span>
                                            <span className="loder-p"></span>
                                            <span className="loder-p"></span>
                                            <span className="loder-p"></span>
                                            <span className="loder-p"></span>
                                            <span className="loder-p"></span>
                                        </p>
                                    </div>
                                </div>
                                <div className="clear" />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

class VideoPresentation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            blockyoutube: <div className="image">
                <a href="javascript:void(0)" onClick={this.playerYoutube} className="has-popup-video">
                    <img onContextMenu={(e) => e.preventDefault()} src={"http://i3.ytimg.com/vi/" + this.props.data.video_id + "/maxresdefault.jpg"} alt={this.props.data.post_title} />
                    <span className="info">
                        <span className="fa fa-youtube-play" style={{ color: "#fff", fontSize: "82px" }} />
                    </span>
                </a>
            </div>
        }
    }
    playerYoutube = (e) => {
        this.setState({
            blockyoutube: <ReactPlayer
                url={'https://www.youtube.com/embed/' + this.props.data.video_id + '?enablejsapi=1&origin=https%3A%2F%2Fwww.b2b.tn&widgetid=1'}
                className='react-player'
                playing
                width='100%'
                height='400px'
                controls={1}
            />
        })
    }
    render() {
        const { t } = this.props
        return (
            <div className={"card-inner  mb-1 mt-1 animated " + (this.props.active ? "active" : "")} id="works-card">
                <div className="card-wrap pt-2 pb-2">
                    <div className="content works mb-0 mt-0">
                        <div className="title-expert">{t("VIDÉO DE PRÉSENTATION")} : </div>
                        <div className="row grid-items" style={{ position: 'relative', minHeight: '300px' }}>
                            <div className="col col-d-12 col-t-12 col-m-12  px-1">
                                <div className="box-item">
                                    {this.state.blockyoutube}
                                </div>
                            </div>
                            <div className="clear" />
                        </div>
                        <div className="clear" />
                    </div>
                </div>
            </div>
        );
    }
}
class EducationsFormations extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { t } = this.props
        if (this.props.data) {
            var formations = [];
            this.props.data.expert_formations.forEach(formation => {
                formations.push(
                    <div className={"resume-item border-line-h active"}>
                        <div className="date">{formation.date} - {formation.date2} </div>
                        <div className="name">{formation.name}</div>
                        <div className="company">{formation.company}</div>
                        <p>{formation.description}</p>
                    </div>
                )
            });
            return (
                <div className={"card-inner animated " + (this.props.active ? "active" : "")} id="resume-card">
                    <div className="card-wrap pt-2 pb-2">
                        <div className="content resume mb-2">
                            <div className="title-expert"><span>{t("Éducations et formations")} : </span></div>
                            <div className="row">
                                <div className="col col-d-12 col-t-12 col-m-12 border-line-v resume-block pt-0 pb-0">
                                    <div className="resume-items">
                                        {formations}
                                    </div>
                                </div>
                                <div className="clear" />
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (<></>);
        }

    }
}
class ExperiencesProfessionnelles extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { t } = this.props
        if (this.props.data) {
            var expert_experiences = [];
            this.props.data.expert_experiences.forEach(experience => {
                expert_experiences.push(
                    <div className="resume-item border-line-h active">
                        <div className="date">{experience.date} - {experience.date2}</div>
                        <div className="name">{experience.name}</div>
                        <div className="company">{experience.company}</div>
                        <p>{experience.description}</p>
                    </div>
                )
            });
            return (
                <div className={"card-inner mb-1 mt-1 animated " + (this.props.active ? "active" : "")} id="experience-card">
                    <div className="card-wrap pt-2 pb-2">
                        <div className="content resume mb-2">
                            <div className="title-expert">{t("Expériences professionnelles")} : </div>
                            <div className="row">
                                <div className="col col-d-12 col-t-12 col-m-12 border-line-v resume-block pt-0">
                                    <div className="resume-items">
                                        {expert_experiences}
                                    </div>
                                </div>
                                <div className="clear" />
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }
}
class Competences extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { t } = this.props
        if (this.props.data) {
            var competence_techniques = [];
            this.props.data.competence_techniques.forEach(competence => {
                competence_techniques.push(
                    <div className="skills-list circles col-md-3">
                        <ul>
                            <li>
                                <div className="name">{competence.name}</div>
                                <div className={"progress p" + competence.progress}>
                                    <span>{competence.progress}%</span>
                                    <div className="slice">
                                        <div className="bar" />
                                        <div className="fill" />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                );
            });
            var html_competence_techniques = "";
            var html_competence = "";
            if (competence_techniques.length > 0) {
                html_competence_techniques = <>
                    <div className="skill-title border-line-h">
                        <div className="name title-expert">{t("Compétences Techniques")} :</div>
                    </div>
                    <div className="row mb-5">
                        {competence_techniques}
                    </div>
                </>;
            }
            if (this.props.data.competences != "") {
                html_competence = <>
                    <div className="title-expert">
                        <span>{t("Compétences")} :</span>
                    </div>
                    <div className="row">
                        <div className="col col-d-12 col-t-12 col-m-12 resume-block">
                            <div className="row">
                                <div className="competence-freelance">
                                    <Markup markup={this.props.data.competences} />
                                </div>
                            </div>
                        </div>
                        <div className="clear" />
                    </div>
                </>;
            }

            var domaines = this.props.data.domaine.split('-');
            var divdomaines = [];
            domaines.forEach(domaine => {
                divdomaines.push(<div className='domaine-emp'>{domaine}</div>)
            });
            return (
                <div className={"card-inner blog animated " + (this.props.active ? "active" : "")} id="blog-card">
                    <div className="card-wrap pt-3 pb-2">
                        <div className="content blog">

                            <div className="title-expert">
                                <span>{t("Domaines")} :</span>

                            </div>
                            <div className="row">
                                <div className="col col-d-12 col-t-12 col-m-12 resume-block pt-0 mb-1">
                                    <div className="row">
                                        <div className="competence-freelance">
                                            {divdomaines}
                                        </div>
                                    </div>
                                </div>
                                <div className="clear" />
                            </div>
                            {(html_competence_techniques == "" && html_competence == "") ? <>
                                <div className="title-expert">
                                    <span>{t("Compétences")} :</span>
                                </div>
                                <div className="row">
                                    <div className="col col-d-12 col-t-12 col-m-12 resume-block">
                                        <div className="row">
                                            <div className="competence-freelance">
                                                <p color="#ff0000"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clear" />
                                </div>
                            </> : <>
                                {this.props.data.expert_compte == "demandeurdemploi" ? html_competence_techniques : <>{this.props.data.expert_compte == "freelance" ? html_competence : <></>}</>}
                                {this.props.data.interes_freelance == "oui" ? html_competence : <>{this.props.data.interes_emploi == "oui" ? html_competence_techniques : <></>}</>}
                            </>}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }
}
class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modaleLoginContact: false,
            htmlModaleLogin: <></>
        }
    }
    clickContact = (e) => {
        this.props.clickLoginContact()
    }
    text2Binary = (string) => {
        return string.split('').map(function (char) {
            return char.charCodeAt(0).toString(2);
        }).join(' ');
    }

    render() {
        const { t } = this.props
        if (this.props.data) {
            if (this.props.isLogin && this.props.data) {

                return (
                    <div className={"card-inner contacts animated undefined " + (this.props.active ? "active" : "")} id="contacts-card" style={{ height: "fit-content" }}>
                        <div className="card-wrap resume-block">
                            <div className="content contacts" style={{ minHeight: 'unset' }}>
                                <div className="title-expert">{t("CONTACT")} : </div>
                                <div className="row">
                                    <div className="col col-d-12 col-t-12 col-m-12 border-line-v ">
                                        <div className="info-list">
                                            <ul>
                                                <li><strong>{t("Adresse")} :</strong> {this.props.data.addr1}</li>
                                                <li><strong>{t("Email")} :</strong>{this.props.data.email}
                                                    {/* <img onContextMenu={(e) => e.preventDefault()} src={backUrl + "/b2b/api/texte.php?text=" + this.text2Binary(this.props.data.email)} style={{ height: "23px" }} /> */}
                                                </li>
                                                <li><strong>{t("Téléphone")} :</strong>{this.props.data.phone}
                                                    {/* <img onContextMenu={(e) => e.preventDefault()} src={backUrl + "/b2b/api/texte.php?text=" + this.text2Binary("+" + this.props.data.codegsm + " " + this.props.data.phone)} style={{ height: "23px" }} /> */}
                                                </li>
                                                {this.props.data.ltf ?
                                                    <li><strong>{t("Suivre")} {this.props.data.first_name + " " + this.props.data.last_name} . . . . .</strong>
                                                        <div class="social-share">
                                                            {this.props.data.linkedin != "" ? <a style={{ width: "50px", display: "inline-grid", textAlign: "center" }} target='_blank' href={this.props.data.linkedin} class='share-btn share-btn-linkedin' title='Linkedin'><i class='fa fa-linkedin'></i></a> : <></>}
                                                            {this.props.data.twitter != "" ? <a style={{ width: "50px", display: "inline-grid", textAlign: "center" }} target='_blank' href={this.props.data.twitter} class='share-btn share-btn-twitter' title='Twitter'><i class='fa fa-twitter'></i></a> : <></>}
                                                            {this.props.data.facebook != "" ? <a style={{ width: "50px", display: "inline-grid", textAlign: "center" }} target='_blank' href={this.props.data.facebook} class='share-btn share-btn-facebook' title='Facebook'><i class='fa fa-facebook'></i></a> : <></>}
                                                        </div>
                                                    </li>
                                                    : <></>}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="clear" />
                                </div>
                            </div>
                            {this.props.dataUser && this.props.data && this.props.dataUser.code_user != this.props.data.userId ? <>
                                <div className="content contacts" style={{ minHeight: 'unset' }}>
                                    <div className="title-expert">{t("ÉCRIRE UN MESSAGE A")} {this.props.data ? this.props.data.first_name + " " + this.props.data.last_name : <></>} : </div>
                                    <div className="row">
                                        <div className="col col-d-12 col-t-12 col-m-12 border-line-v">
                                            <div className="contact_form">
                                                {this.props.dataUser.valid_b2b == "ok" ?
                                                    <FormContact t={t} expertid={this.props.data.userId} />
                                                    : <>
                                                        {t("Vous ne pouvez pas effectuer cette action avant la validation de votre compte.")}
                                                    </>}

                                            </div>
                                        </div>
                                        <div className="clear" />
                                    </div>
                                </div>
                            </> : <></>}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className={"card-inner contacts animated " + (this.props.active ? "active" : "")} id="contacts-card">
                        <div className="card-wrap resume-block">
                            <div className="content contacts" style={{ minHeight: 'unset' }}>
                                <div className="title-expert">{t("CONTACT")} : </div>
                                <div className="row">
                                    <div className="col col-d-12 col-t-12 col-m-12 border-line-v">
                                        <div style={{ textAlign: 'center' }}>
                                            <h2 id="text_connecter_contact">{t("Veuillez-vous connecter pour pouvoir contacter")} <span className="place_post_title">{this.props.data.last_name} {this.props.data.first_name}</span> :</h2>
                                            <button onClick={this.clickContact} className="btn  big-btn  color-bg flat-btn btn-b2b">{t("Se Connecter")} </button>
                                        </div>
                                    </div>
                                    <div className="clear" />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            return (
                <></>
            );
        }
    }
}



function FormContact(props) {

    const [loderFrome, setLoderFrome] = useState("d-none");
    const [sund, setSund] = useState(false);
    const [expertid, setExpertid] = useState(props.expertid);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        setLoderFrome("loder-from");
        Experts.registerMessage(expertid, data).then((res) => {
            if (res.data.register) {
                reset();
                setSund(true);
                setLoderFrome("d-none");
            }
        });
    }
    const { t } = props
    useEffect(() => {

    }, []);
    return (<>
        {sund ?
            <div className="sund-form-sucsses">
                <span>{t("Votre Message a été enregistrée avec succès")}</span>
            </div> : <></>}
        <div className={loderFrome}>
            <span className='loading-login'></span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row block-form-msg">
                <div className="col-md-12">
                    <div className="group-val">
                        <input placeholder="Objet du message" {...register("sujet", { required: true })} />
                        {errors.sujet ? <span className="error d-block">{t("Ce champ est obligatoire")}</span> : <></>}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="group-val">
                        <textarea placeholder="Message" {...register("message", { required: true })} />
                        {errors.message ? <span className="error d-block">{t("Ce champ est obligatoire")}</span> : <></>}
                    </div>
                </div>
            </div>
            <div className="align-left">
                <button type="submit" className="button btn-b2b">
                    <span className="text">{t("Envoyer le message")}</span>
                </button>
            </div>
        </form>
    </>
    );
}

class EncoursValidation extends Component {
    render() {
        const { t } = this.props
        return (

            <div className="connxtion_body" style={{ position: "relative" }}>

                <div className="content-ihc">
                    <h3>{t("Vous ne pouvez pas effectuer cette action avant la validation de votre compte.")}</h3>
                </div>
            </div>
        );
    }
}
