import React, { Component , useEffect , useState } from 'react';
import Maselectionentreprises from './maSelectionEntreprises'
import Mesabonnes from './mesabonnes'
import Entreprisesenvedette from './entreprisesenvedette'
import Produitsenvedette from './produitsenvedette'
import Ajoutezavotrefilactualite from './ajoutezavotrefilactualite'
import Evenementsavenir from './evenementsavenir'
import FilactualiteNonConnected from './filactualitenonconnected';
import { b2bNameSite,b2bDescriptionSite } from  "../../config.json"; 
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import Login from '../header/loginheader';
import MetaTags from 'react-meta-tags';
import Modal, {closeStyle} from 'simple-react-modal';
import './style.css';

export default function MyB2b(){
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    return (
        <Index offset={offset}/>
    );
}
class Index extends Component {
    constructor (props) {
        super(props)
        
        this.state = {
            modaleLogin:true,
        }
    }
    close=()=>{
        this.setState({
            modaleLogin:false,
        })

        setTimeout(() => {
            this.setState({
                modaleLogin:true,
            }) 
        }, 2000); 
      
        
    }
    render() { 
        
        return (<>
            <MetaTags>
            <title>{localStorage.getItem("langb2b")==="en" ?"MY B2B" : "Mon B2B"} | {b2bNameSite} | {localStorage.getItem("langb2b")==="en" ? "B2B marketplace and B2B networking platform in Tunisia and abroad" : b2bDescriptionSite}</title>
            </MetaTags>
            <div className="modal-login-hadeer modal-login-myb2b">
                <Modal
                   
                    closeOnOuterClick={true}
                    show={this.state.modaleLogin}
                    onClose={this.close.bind(this)}
                    >
                    <div classNameName="div-loginn">
                        <div className="connxtion_header">
                            <h2 id="to_text_pupup--" className="size-titre-b2b grie-b2b">
                            {localStorage.getItem("langb2b")==="fr" ? "VOUS ÊTES DÉJÀ INSCRIT ? CONNECTEZ-VOUS :" : "ARE YOU ALREADY REGISTERED ? LOG IN :"}
                            </h2>
                            <div className="divbtnclose">
                                <span className="close closemodalheader" onClick={this.close.bind(this)}>×</span>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 pr-0'>
                                <div className="block-text-myb2b">
                                {localStorage.getItem("langb2b")==="fr" ?
                                 (<p>Votre espace <span>« My B2B »</span> est le fil d’actualité qui résume l’ensemble de votre activité sur « {b2bNameSite} ». <br />Son objectif est de vous montrer les choses qui vous intéressent le plus à chaque visite (selon votre choix et vos intérêts). <br />Le fil d’actualité, qui évolue constamment, est une collection personnalisée d’entreprises, de produits/services et d’actualités publiés par les entreprises avec lesquels vous êtes connecté(e) sur « {b2bNameSite} ».</p>)
                                 :
                                  (<p>Your space <span>« My B2B »</span> is the news feed that summarizes all of your activity on « {b2bNameSite} ». <br />Its goal is to show you the things that interest you the most on each visit (depending on your choice and interests). <br />The ever-evolving News Feed is a personalized collection of businesses, products/services, and news posted by the businesses you are connected with on « {b2bNameSite} ».</p>)
                                  }
                                </div>
                            </div>
                            <div className='col-md-6 block-login-myb2b'><Login close={this.close.bind(this)}/></div>
                        </div>
                    </div>
                </Modal>
            </div>
            <div className="page-content page-myb2b"> 
                <div className="container ">
                    <div className="row pt-3">
                        <div className='col-lg-3 p-0'>
                            <aside className="sidebar static">
                                <Maselectionentreprises />
                                <Mesabonnes />
                                <Entreprisesenvedette />
                            </aside>
                        </div>
                        <div className='col-lg-6'>
                            <FilactualiteNonConnected />
                        </div>
                        <div className='col-lg-3 p-0'>
                            <aside className="sidebar static">
                                <Produitsenvedette />
                                <Ajoutezavotrefilactualite />
                                <Evenementsavenir />
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
            <div className="is_user_logged_in" style={{}}>
            {localStorage.getItem("langb2b")==="fr" ? 
            (<h2 className="grie-b2b">Voir plus de contenu de <span style={{color: 'red'}}>« My B2B »</span> sur « {b2bNameSite} » </h2>)
            : (<h2 className="grie-b2b">See more content from <span style={{color: 'red'}}>« My B2B »</span> on « {b2bNameSite} » </h2>)}
                <div className="row justify-content-center d-xl-grid">
                    <Link style={{maxWidth:"230px"}} className="col-md-4 btn btn-b2b myb2b-btn" to={"/"+i18next.language+"/signin/"}><i className="icone-btn-connecter" /> {localStorage.getItem("langb2b")==="fr" ? "Connexion" : "Login"}</Link>
                    <span style={{maxWidth:"130px"}} className="col-md-4 myb2b-ou">ou</span> 
                    <Link style={{maxWidth:"230px"}} className="col-md-4 btn btn-b2b myb2b-btn" to={"/"+i18next.language+"/inscription/"}><i className="icone-btn-connecter" />{localStorage.getItem("langb2b")==="fr" ? "Créer un compte" : "Create an account"}</Link>
                </div>
            </div>
        </>);
    }
}
 
