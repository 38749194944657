import http from "./httpService";
import { apiUrl } from  "../config.json";
import i18next from 'i18next';
const login = (email,password) => {
    const apiEndoint =  apiUrl+"/security/login.php";
    const data = new FormData();
    data.append("email",email);
    data.append("password",password);
    return http.post(apiEndoint,data);
}

const logout = (token) => {
    const apiEndoint =  apiUrl+"/security/logout.php";
    const data = new FormData();
    data.append("token",token);
    return http.get(apiEndoint);
}
const isLogin = () => {
    const apiEndoint =  apiUrl+"/security/isLogin.php";
    const data = new FormData();
    data.append("token",localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,data);
}
const registerEnteprise = (email,password,entreprise=null) => {
    const apiEndoint =  apiUrl+"/security/registerEnteprise.php";
    const data = new FormData();
    data.append("email",email);
    data.append("password",password);
    data.append("lang",i18next.language);
    data.append("entreprise",entreprise);
    return http.post(apiEndoint,data);
}
const registerExpert = (email,password) => {
    const apiEndoint =  apiUrl+"/security/registerExpert.php";
    const data = new FormData();
    data.append("email",email);
    data.append("password",password);
    return http.post(apiEndoint,data);
}
const addEnteprise = (data) => {
    const apiEndoint =  apiUrl+"/security/addEntreprise.php";
    return http.post(apiEndoint,data);
}
const confirmerEnteprise = (account_activation,email) => {
    const apiEndoint =  apiUrl+"/security/confirmerEnteprise.php";
    const data = new FormData();
    data.append("email",email);
    data.append("account_activation",account_activation);
    return http.post(apiEndoint,data);
}
const inscriptionExpert1 = (data,paysCode) => {
    const apiEndoint =  apiUrl+"/security/inscriptionExpert.php";
    const formData = new FormData();
    formData.append("etape1", "oui");
    formData.append("codegsm", paysCode);
    formData.append("token", localStorage.getItem("tokenb2b"));
    Object.entries(data).forEach(([key, value]) => {
        if(key=="file"){
            formData.append(key, value[0]);
        }else{
            formData.append(key, value);
        }
        

    });
    return http.post(apiEndoint,formData);
}
const inscriptionExpert2 = (data,freelance,typeContrat,competence_emploi,competence_p_emploi,number_of_competence_emploi,disponibilite,categorieseTravail) => {
    const apiEndoint =  apiUrl+"/security/inscriptionExpert.php";
    const formData = new FormData();
    formData.append("etape2", "oui");
    formData.append("token", localStorage.getItem("tokenb2b"));
    formData.append("freelance", freelance);
    formData.append("typeContrat", typeContrat);
    formData.append("competence_emploi", competence_emploi);
    formData.append("competence_p_emploi", competence_p_emploi);
    formData.append("number_of_competence_emploi", number_of_competence_emploi);
    formData.append("disponibilite", disponibilite);
    formData.append("categorieseTravail", categorieseTravail);
    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return http.post(apiEndoint,formData);
}
const inscriptionExpert3 = (data,formations,experiences,freelance,typeContrat,competence_emploi,competence_p_emploi,number_of_competence_emploi,disponibilite,interes_freelance,interes_emploi) => {
    const apiEndoint =  apiUrl+"/security/inscriptionExpert.php";
    const formData = new FormData();
    formData.append("etape3", "oui");
    formData.append("formations", formations);
    formData.append("experiences", experiences);
    formData.append("token", localStorage.getItem("tokenb2b"));
    if(interes_freelance){
        formData.append("freelance", freelance);
        formData.append("interes_emploi", "non");
        formData.append("interes_freelance", "oui");
    }
    if(interes_emploi){
        formData.append("interes_emploi", "oui");
        formData.append("interes_freelance", "non");
        formData.append("typeContrat", typeContrat);
        formData.append("competence_emploi", competence_emploi);
        formData.append("competence_p_emploi", competence_p_emploi);
        formData.append("number_of_competence_emploi", number_of_competence_emploi);
        formData.append("disponibilite", disponibilite);
    }
    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return http.post(apiEndoint,formData);
}
const inscriptionExpertMail = () => {
    const apiEndoint =  apiUrl+"/security/mailExpert.php";
    const formData = new FormData();
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}
const getDataInscriptionExpert = () => {
    const apiEndoint =  apiUrl+"/security/getExpert.php";
    const formData = new FormData();
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}
const sundEmailRestPass = (email) => {
    const apiEndoint =  apiUrl+"/security/sundEmailRestPass.php";
    const formData = new FormData();
    formData.append("email", email);
    return http.post(apiEndoint,formData);
}
const UpdatePass = (password,rest_password,user) => {
    const apiEndoint =  apiUrl+"/security/setlRestPass.php";
    const formData = new FormData();
    formData.append("user", user);
    formData.append("rest_password", rest_password);
    formData.append("password", password);
    return http.post(apiEndoint,formData);
}

export default 
{
    login,
    logout,
    registerExpert,
    registerEnteprise,
    addEnteprise,
    isLogin,
    confirmerEnteprise,
    inscriptionExpert1,
    inscriptionExpert2,
    inscriptionExpert3,
    inscriptionExpertMail,
    getDataInscriptionExpert,
    sundEmailRestPass,
    UpdatePass
}