import http from "./httpService";
import { apiUrl } from  "../config.json";

const getActualites = (paged,mot_cle=null) => {
    const apiEndoint =  apiUrl+"/actualites/getActualites.php?paged="+paged+"&mot_cle="+mot_cle;
    return http.get(apiEndoint);
}
const getDetail = (slug) => {
    const apiEndoint =  apiUrl+"/actualites/getDetail.php?slug="+slug;
    return http.get(apiEndoint);
}

export default 
{
    getActualites,
    getDetail
}