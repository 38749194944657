
import React, { Component } from 'react';
import Experts from '../../services/experts';
import AliceCarousel from 'react-alice-carousel';
import i18next from 'i18next';
import 'react-alice-carousel/lib/alice-carousel.css';
import './style.css';
export default class Index extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            data : null,
            expertsItems : [<Item />,<Item />,<Item />,<Item />,<Item />]
        }
        Experts.getExperts().then((res)=>{
            if(res.data.get){
                var items =[];
                res.data.data.forEach(expert => {
                    items.push(<Item url={expert.url} img={expert.img} name={expert.prenom+" "+expert.nom} diplome={expert.diplome} experience={expert.experience} />)
                });
                this.setState({
                    data : res.data.data,
                    expertsItems : items
                });
            }
        });
    }
    
    render() {
        return (
            <>
                
                <div className="container" style={{margin:"auto",height:"320px"}}>
                    <div className="row w-100 m-0 p-0">
                        <div className="col-md-12 mt-2 px-1">
                            <div className="list-single-main-item"> 
                                <div className="list-single-main-item-title fl-wrap">
                                    <h3 className="size-titre-b2b grie-b2b mb-1">VOUS AIMEREZ AUSSI :</h3>
                                </div>
                                <div className="user-aussi-list no-padding" width="100%">
                                    <div id="list_user-aussi" className="owl-carousel owl-theme w-100 owl-loaded owl-drag">
                                        <AliceCarousel 
                                            mouseTracking 
                                            autoPlay={true}
                                            items={this.state.expertsItems}
                                            infinite
                                            autoPlayInterval = {4000}
                                            responsive={{1200: {items: 5},1000: {items: 4},800: {items: 2},300: {items: 1}}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

class Item extends Component {
    constructor (props) {
        super(props)
       
    }
    
    render(){
        if(this.props.url && this.props.img && this.props.name ){
            return(
                <a className="user-aussi-item item" href={"/"+i18next.language+this.props.url}>
                    <figure>
                        <img onContextMenu={(e)=> e.preventDefault()} width={150} height={150}  src={this.props.img} className="user-aussi-image" alt={this.props.name} />
                    </figure>
                    <h6>{this.props.name}</h6>
                    <p className="grid-only">{this.props.experience}</p>
                    <p className="red-only">{this.props.diplome}</p>
                </a>
            )
        }else{
            return(
                <a className="user-aussi-item item" href="#">
                    <figure>
                        <img onContextMenu={(e)=> e.preventDefault()} width={150} height={150}  className="user-aussi-image lazyload" alt="" />
                    </figure>
                    <h6>moujahed dallel</h6>
                    <p className="grid-only">entre 3 et 5 ans</p>
                    <p className="red-only">Maitrise, Bac+4</p>
                </a>
            )
        }
       
    }
}