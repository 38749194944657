import React, { Component } from 'react';
import SousTritance from '../../services/sousTritance';
import { Link } from 'react-router-dom';
import { Markup } from 'react-render-markup';
import {b2bNameSite,b2bDescriptionSite} from '../../config.json';
import Pagination from "react-js-pagination";
import Modal, {closeStyle} from 'simple-react-modal';
import Login from '../header/loginheader';
import jwt_decode from "jwt-decode";
import Auth from '../../services/Auth';
import MetaTags from 'react-meta-tags';
import i18next from 'i18next';
import ReactGA from 'react-ga';
import { withTranslation } from 'react-i18next';
import './style.css';
class Index extends Component {
    constructor (props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        const { innerWidth: width, innerHeight: height } = window;
        super(props)
        var userLogin=false;
        var dataUser=null;
        if(localStorage.getItem('tokenb2b') && localStorage.getItem('tokenb2b')!="undefined" && localStorage.getItem('tokenb2b')!=""){
           userLogin=true;
           dataUser=jwt_decode(localStorage.getItem('tokenb2b'));
        }
        this.state = {
            items : [<ItemLoader t={this.props.t}/>,<ItemLoader  t={this.props.t}/>,<ItemLoader  t={this.props.t}/>,<ItemLoader  t={this.props.t}/>,<ItemLoader  t={this.props.t}/>,<ItemLoader  t={this.props.t}/>,<ItemLoader  t={this.props.t}/>,<ItemLoader  t={this.props.t}/>,<ItemLoader  t={this.props.t}/>,<ItemLoader  t={this.props.t}/>],
            search_mot_cle : "" ,
            search_type:[],
            search_domaines:[],
            search_localisation:[],
            dataUser:dataUser,
            userLogin:userLogin,
            activePage : 1 ,
            totalItemsCount : 0,
            itemsPerPage :6,
            progressPage : 0,
            showProgressPage:true,
            modaleLogin:false,
            type:"",
            nb_demande:0, 
            nb_offre:0,
            domaines:[],
            localisation:[],
            showfiltre:width>700?"0":"-100",
        }
        Auth.isLogin().then((res)=>{
            if(res.data!=false){
               this.setState({
                  userLogin:true,
                  dataUser:res.data
               });
            }else{
               this.setState({
                  userLogin:false,
                  dataUser:null
               });
            }
         });
        setInterval(() => {
            if(this.state.progressPage<80){
                this.setState({progressPage:this.state.progressPage++});
            }
        }, 100);
        
        window.scrollTo(0, 0);
        this.updatedata(1,this.state.search_mot_cle,this.state.search_type,this.state.search_domaines,this.state.search_localisation);
        SousTritance.getInfoSousTritance().then((res)=>{
            if(res.data.get){
               this.setState({
                    nb_demande:res.data.nbdemande, 
                    nb_offre:res.data.nboffre,
                    domaines:res.data.domaines,
                    localisation:res.data.localisation
               })
            }
        });
        
    }
    changeSearch = (e)=>{
        this.setState({
            search_mot_cle : e.target.value
        });
    }
    submitSearch= ()=>{
        this.updatedata(1,this.state.search_mot_cle,this.state.search_type,this.state.search_domaines,this.state.search_localisation);
    }
    handlePageChange(pageNumber) {
        window.scrollTo(0, 0);
        this.updatedata(pageNumber,this.state.search_mot_cle,this.state.search_type,this.state.search_domaines,this.state.search_localisation);
    }
    
    initItems(){
        this.setState({items : [
            <ItemLoader  t={this.props.t}/>,
            <ItemLoader  t={this.props.t}/>,
            <ItemLoader  t={this.props.t}/>,
            <ItemLoader  t={this.props.t}/>,
            <ItemLoader  t={this.props.t}/>,
            <ItemLoader  t={this.props.t}/>, 
            <ItemLoader  t={this.props.t}/>,
            <ItemLoader  t={this.props.t}/>
        ]});
    }
    close=()=>{
        this.setState({
            modaleLogin : false
        })
    }
    open=()=>{
        this.setState({
            modaleLogin : true
        })
    }
    changetype=(event)=>{
        var type = this.state.search_type;
        
        if(!type.includes(event.target.value)){
            type.push(event.target.value)
        }else{
            type.splice(type.indexOf(event.target.value), 1);
        }
        this.setState({search_type: type });
        this.updatedata(1,this.state.search_mot_cle,type,this.state.search_domaines,this.state.search_localisation);
    }
    changeDomaines=(event)=>{
        var domaines = this.state.search_domaines;
        if(!domaines.includes(event.target.value)){
            domaines.push(event.target.value)
        }else{
            domaines.splice(domaines.indexOf(event.target.value), 1);
        }
        this.setState({search_domaines: domaines });
        this.updatedata(1,this.state.search_mot_cle,this.state.search_type,domaines,this.state.search_localisation);
    }
    changelocalisation=(event)=>{
        var localisation = this.state.search_localisation;
        
        if(!localisation.includes(event.target.value)){
            localisation.push(event.target.value)
        }else{
            localisation.splice(localisation.indexOf(event.target.value), 1);
        }
        this.setState({search_localisation: localisation });
        this.updatedata(1,this.state.search_mot_cle,this.state.search_type,this.state.search_domaines,localisation);
    }
    updatedata=(activePage,search_mot_cle,search_type,search_domaines,search_localisation)=>{
        this.initItems();
        this.setState({activePage: activePage});
        SousTritance.getSousTritance(activePage,search_mot_cle,search_type,search_domaines,search_localisation).then((res)=>{
            if(res.data.get){
                var items = [];
                res.data.data.forEach(actualite => {
                    items.push(<Item 
                        t={this.props.t}
                        userLogin={this.state.userLogin}
                        key={actualite.id} 
                        id={actualite.id} 
                        url={actualite.url} 
                        img={actualite.img} 
                        title={actualite.title} 
                        date={actualite.date}
                        category={actualite.domaine}
                        type={actualite.post_type}
                        content={actualite.content}
                        open={this.open}
                        />);
                }); 
                this.setState({items : []});
                this.setState({items : items});
                this.setState({totalItemsCount : res.data.nbposts});
            }
        });
    }
    showfiltre= ()=>{
        if(this.state.showfiltre=="-100"){
            this.setState({
                showfiltre:"0"
            })
        }else{
            this.setState({
                showfiltre:"-100"
            })
        }
       
    }
    render() {
        const {t} =this.props; 
        var localisation=[];
        if(this.state.localisation){
            this.state.localisation.forEach(element => {
                localisation.push(
                <div>
                    <input type="checkbox" className="click-submit" id={"checkbox_localisation_"+element.id} value={element.id} onChange={this.changelocalisation}/>
                    <label for={"checkbox_localisation_"+element.id} className="l" >{element.name} ({element.cunt})</label> 
                    <br />
                </div>)
            });
        }
        var domaines=[];
        if(this.state.domaines){
            this.state.domaines.forEach(element => {
                domaines.push(
                <div>
                    <input type="checkbox" className="click-submit" id={"checkbox_domaines_offre_"+element.id} value={element.id} onChange={this.changeDomaines}/>
                    <label for={"checkbox_domaines_offre_"+element.id} className="l" >{element.name} ({element.nboffre})</label> 
                    <br />
                </div>)
            });
        }
       
        return (
            <>
            {this.state.showProgressPage?<div class="progress-div" style={{width: "100%"}}><div style={{width: this.state.progressPage+"%"}} class="progress"></div></div>:<></>}
            <MetaTags>
            <title>{t('Sous-tritance B2B')} | {b2bNameSite} | {localStorage.getItem("langb2b")==="en" ? "B2B marketplace and B2B networking platform in Tunisia and abroad" : b2bDescriptionSite }</title>
            </MetaTags>
            <div className="modal-login-hadeer">
                <Modal
                    closeOnOuterClick={true}
                    show={this.state.modaleLogin}
                    onClose={this.close.bind(this)}
                    >
                    <div classNameName="div-loginn">
                        <div className="connxtion_header">
                            <h2 id="to_text_pupup--" className="size-titre-b2b grie-b2b">
                                {t("VEUILLEZ-VOUS CONNECTER POUR POUVOIR ACCÉDER AUX DÉTAILS DE CETTE ANNONCE:")} :
                            </h2>
                            <div className="divbtnclose">
                                <span className="close closemodalheader" onClick={this.close.bind(this)}>×</span>
                            </div>
                        </div>
                        <Login />
                    </div>
                </Modal>
            </div>
            <div className="page-content page-offre-sous-tritance">  
                <section id="list-places-wrapper" style={{marginTop: 0, backgroundColor: '#fff'}}>
                    <div className="container" style={{backgroundColor: '#fff'}}>
                        <br /><br />
                        <div className="row">
                            <div className={"col-md-3 block-fillter-expert block-fillter-mobile p"+this.state.showfiltre} id="block-fillter-mobile" style={{left:this.state.showfiltre+"%"}}> 
                                <div className='masque-close-fillter' onClick={this.showfiltre}><span>x</span></div>
                                <div className="borde-block">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4 className="h4 ">{t("Type")}: </h4>
                                            <div>
                                                <input type="checkbox" className="click-submit" id="checkbox_type_offre" value="offre" onChange={this.changetype}/>
                                                <label for="checkbox_type_offre" className="l mr-3" >{t("Offre")}({this.state.nb_offre})</label> 
                                            </div>
                                            <div>
                                                <input type="checkbox" className="click-submit" id="checkbox_type_demande" value="demande" onChange={this.changetype}/>
                                                <label for="checkbox_type_demande" className="l" >{t("Demande")}({this.state.nb_demande})</label> 
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <h4 className="h4 ">{t("Catégories")}: </h4>
                                            {domaines}  
                                        </div>
                                        <div className="col-md-12">
                                            <h4 className="h4 ">{t("Localisation")} : </h4>
                                            {localisation}  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">    
                                <form role="search" className="place_search_form" action method="GET" style={{marginBottom: '30px'}}>
                                    <div className="row" style={{position:"relative"}}>
                                        <div className="col-md-11 col-10 de-search-form " id="search-places" style={{border:"none"}}>
                                            <div className="col-md-12" style={{border: '1px solid #b8c7c8'}}>
                                                <div className="de-scontrol">
                                                    <input type="text" name="mot_cle" onChange={this.changeSearch} className="keyword_search" placeholder={t('Entrez un mot-clé')+"..."}  value={this.state.search_mot_cle} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 col-2 block-search-btn " style={{marginLeft: '-15px'}}>
                                            <button className="btn btn-primary search-btn" type="button" onClick={this.submitSearch} style={{"background-color":"#ED2829","border":"none","height":"65px","width": "100%",marginBottom: "1px"}}>
                                                <i className="fa fa-search fa-lg"></i>
                                            </button>
                                            <button className="btn btn-primary show-mobile" type="button" onClick={this.showfiltre} style={{"background-color":"#ED2829","border":"none","height":"65px","width": "100%",borderRadius: "0px 0px 25px 25px"}}>
                                                <i className="fa fa-filter fa-lg"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div className="row">
                                    {this.state.items}
                                </div>
                                <div className="paginations-wrapper">
                                    <div className="paginations">
                                        {this.state.totalItemsCount>this.state.itemsPerPage?
                                        <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={this.state.itemsPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={5}
                                        onChange={this.handlePageChange.bind(this)}
                                        />
                                        :<></>}
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>
            </div>
            </>
        );
    }
}

export default withTranslation()(Index);


class Item extends Component
{
    constructor (props) {
        super(props);  

    }
    clickSousTritance=()=>{
        if(!this.props.userLogin){
            this.props.open()
        }
    }
    render() {
        const {t} =this.props; 
        const url = "/"+i18next.language+"/sous-traitance/"+(this.props.type=="offre-sous-traitance"?"offre/":"demande/")+this.props.url
        return ( 
            <div className="col-md-6" style={{marginBottom: '20px'}}>
                <div className="wpnaw-news-overlay-main-wrap">
                    <Link className="wpnaw-link-overlay" to={this.props.url==""?false:url} onClick={this.clickSousTritance}/>     
                        <div className="wpnaw-news-image-bg" style={{}}>
                            <img onContextMenu={(e)=> e.preventDefault()} className="wpnaw-news-image" src={this.props.img} alt={this.props.title} />
                        </div>
                        <div className="wpnaw-news-grid-content">
                            <div className="wpnaw-news-categories">
                                {/* <span>{this.props.type=="offre-sous-traitance"?t('OFFRE'):t('DEMANDE')}</span> */}
                            </div>
                        </div>
                </div>
                <div className="wpnaw-news-content">
                    <h2 className="wpnaw-news-title">
                        <Link to={this.props.url==""?false:url} target="_self" style={{fontSize: '17px', textTransform: 'uppercase'}}><Markup markup={this.props.title} /></Link>
                    </h2>
                    <div className="wpnaw-news-short-content" style={{height: '40px', fontFamily: 'calibri', fontSize: '15px'}}><Markup markup={this.props.content} /></div>
                    <Link to={this.props.url==""?false:url} target="_self" className="readmorebtn" style={{float: 'right', color: '#e11a18'}}>{t('Lire la suite')}</Link>
                </div>
            </div>
        );
    }
}



class ItemLoader extends Component
{
    render() {
        const {t} =this.props; 
        return ( 
            <div className="col-md-6" style={{marginBottom: '20px'}}>
                <div className="wpnaw-news-overlay-main-wrap">
                    <a className="wpnaw-link-overlay" href="#" />     
                        <div className="wpnaw-news-image-bg" style={{}}>
                           <div className="lowder-image-post"></div>
                        </div>
                        <div className="wpnaw-news-grid-content">
                            <div className="wpnaw-news-categories">
                                <span>{t('OFFRE')}</span>
                            </div>
                            <div className="wpnaw-news-date" style={{float: 'right', padding: '4px 0', fontSize: '12px'}}>     </div>
                            <div className="wpnaw-title-content">
                                <h2 className="wpnaw-news-title">
                                    <a href="#" target="_self" style={{fontSize: '17px', textTransform: 'uppercase'}}>    </a>
                                </h2>
                            </div>
                        </div>
                </div>
                <div className="wpnaw-news-content">
                    <div className="wpnaw-news-short-content lowder-text-post" ></div>
                    <a href="#" target="_self" className="readmorebtn" style={{float: 'right', color: '#e11a18'}}>{t('Lire la suite')}</a>
                </div>
            </div>
        );
    }
}
