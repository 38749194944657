import React, { Component } from 'react';

import Actualites from '../../services/actualites';
import AliceCarousel from 'react-alice-carousel';
import { Link,NavLink } from 'react-router-dom';
import 'react-alice-carousel/lib/alice-carousel.css';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
class BlockActualites extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            items : [<ItemActualite />,<ItemActualite />,<ItemActualite />,<ItemActualite />,<ItemActualite />,<ItemActualite />]
        }
        setTimeout(() => {
            Actualites.getActualites(1,"").then((res)=>{
                if(res.data.get){
                    var items = [];
                    res.data.data.forEach(actualite => {
                        items.push(<ItemActualite actualite={actualite} />);
                    }); 
                    this.setState({items:items});
                }
            });
        }, 2000);
       
    }
    render() {
        const {t}= this.props;
        if(this.props.data){
            
            return (
                <div className="list-single-main-item"> 
                    <div className="list-single-main-item-title fl-wrap">
                        <h3 className="size-titre-b2b grie-b2b">{t("ACTUALITÉS B2B")} :</h3>
                    </div>
                    <div className=" product-list no-padding" width="100%">
                        <AliceCarousel 
                            mouseTracking 
                            autoPlay={true}
                            items={this.state.items}
                            infinite
                            renderDotsItem={false}
                            autoPlayInterval = {2000}
                            responsive={{1200: {items: 4},1000: {items: 3},800: {items: 2},300: {items: 2}}} />
                    </div>
                </div>
            );
        }else{
            return (
                <div className="list-single-main-item"> 
                    <div className="list-single-main-item-title fl-wrap">
                        <h3 className="size-titre-b2b grie-b2b">{t("ACTUALITÉS B2B")} :</h3>
                    </div>
                    <div className=" product-list no-padding" width="100%">
                        <AliceCarousel 
                            mouseTracking 
                            autoPlay={true}
                            items={this.state.items}
                            infinite
                            renderDotsItem={false}
                            autoPlayInterval = {2000}
                            responsive={{1200: {items: 4},1000: {items: 3},800: {items: 2},300: {items: 2}}} />
                    </div>
                </div>
            );
        }
        
    }
}
export default withTranslation()(BlockActualites);
class ItemActualite extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            
        }
    }

    render() {
        const {t}= this.props;
        if(this.props.actualite ){ //
            return (
                <div className="col-md-12 px-1">
                    <Link to={"/"+i18next.language+this.props.actualite.url}>
                        <div className="col-md-12 px-0" style={{height: '170px'}}>
                            <img onContextMenu={(e)=> e.preventDefault()} src={this.props.actualite.img} alt={this.props.actualite.title} style={{height: '100%', width: '100%', objectFit: 'cover'}} />
                            <h4 style={{position: 'absolute', top: 0, left: '0px', color: '#fff', background: '#ed2829', fontSize: '12px', padding: '1px 5px'}}>{this.props.actualite.category}</h4>
                        </div>
                        <div className="col-md-12 px-1" style={{fontFamily: 'calibri', textAlign: 'justify'}}> 
                            <h4 style={{color: '#000', textTransform: 'capitalize', fontSize: 12, lineHeight: '12px', marginTop: '12px'}}>{this.props.actualite.title}</h4>
                        </div>
                    </Link>
                </div>
            );
        }else{
            return (
                <div className="partenaires-item item">
                    <figure>
                        <div width="200" height="200" className="loder-image"/>
                    </figure>
                    <h6><span className="loder-title"></span></h6>
                </div>
            );
        }
    }
}