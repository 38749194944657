import React from "react";
import styled from "styled-components";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import axios from "axios";
import http from "../../services/httpService";
function LinkedInPage() {
  const { linkedInLogin } = useLinkedIn({
    clientId: "77a52lyqlseb1x",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
        console.log(code);
        setCode(code);
        setErrorMessage("");
        const apiEndoint =  "https://www.linkedin.com/oauth/v2/accessToken";
        const formData = new FormData();
        formData.append("grant_type", "authorization_code");
        formData.append("code", code);
        formData.append("redirect_uri", `${window.location.origin}/linkedin`);
        formData.append("client_id", "77a52lyqlseb1x");
        formData.append("client_secret", "6QT2epN71eUxT2S8");
        http.post(apiEndoint,formData,{headers: {'Content-Type': 'application/x-www-form-urlencoded'}}).then((res)=>{
            console.log(res);
        });
    },
    scope: "r_emailaddress r_liteprofile",
    onError: (error) => {
      console.log(error);
      setCode("");
      setErrorMessage(error.errorMessage);
    },
  });
  const [code, setCode] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  return (
    <Wrapper>
      <img
        onClick={linkedInLogin}
        src={linkedin}
        alt="Log in with Linked In"
        style={{ maxWidth: "180px", cursor: "pointer" }}
      />

      {!code && <div>No code</div>}
      {code && (
        <div>
          <div>Authorization Code: {code}</div>
          <div>
            Follow{" "}
            <Link
              target="_blank"
              href="https://docs.microsoft.com/en-us/linkedin/shared/authentication/authorization-code-flow?context=linkedin%2Fconsumer%2Fcontext&tabs=HTTPS#step-3-exchange-authorization-code-for-an-access-token"
              rel="noreferrer"
            >
              this
            </Link>{" "}
            to continue
          </div>
        </div>
      )}
      {errorMessage && <div>{errorMessage}</div>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Link = styled.a`
  font-size: 20px;
  font-weight: bold;
`;

export default LinkedInPage;