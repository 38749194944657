import React, { Component, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next';
import { Markup } from 'react-render-markup';
import { useForm } from "react-hook-form";
import Enteprises from '../../services/enteprises';
class ModalContact extends Component {
    constructor(props) {
        super(props);
        var urlsite = props.data.url;
        urlsite = urlsite.replace("https://", "");
        urlsite = urlsite.replace("http://", "");

        this.state = {
            urlsite: urlsite,
            showDivHorairesTravail: false
        }
    }

    render() {
        const { t } = this.props;
        if (this.props.data) {

            return (

                <div className="connxtion_body" style={{ position: "relative", height: "700px" }}>

                    <div className="col-md-12 ">
                        <div className="list-single-main-item-title fl-wrap mt-3" style={{marginBottom:"-20px",}}>
                            <h3 className="grie-b2b">{t("ÉCRIRE À")}  <span className="place_post_title"><Markup markup={this.props.data.post_title} /></span> :</h3>
                        </div>
                        <div className="" style={{ "margin-top": "20px", "border": "0", "height": "190px" }}>

                            <FormContactP t={t} entrepriseUser={this.props.data.post_author} entrepriseID={this.props.data.ID} />

                        </div>
                    </div>
                </div>
            );
        }
    }
}
export default withTranslation()(React.memo(ModalContact));
function FormContactP(props) {

    const [loderFrome, setLoderFrome] = useState("d-none");
    const [sund, setSund] = useState(false);

    const [entrepriseuser, setEntrepriseuser] = useState(props.entrepriseUser);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        setLoderFrome("loder-from");
        Enteprises.registerMessageOf(props.entrepriseUser, data).then((res) => {
            if (res.data.register) {
                reset();
                setSund(true);
                setLoderFrome("d-none");
            }
        });
    }
    const { t } = props;
    useEffect(() => {

    }, []);
    return (<>
        {sund ?
            <div className="sund-form-sucsses">
                <span>{t("Votre Message a été enregistrée avec succès")}</span>
            </div> : <></>}
        <div className={loderFrome}>
            <span className='loading-login'></span>
        </div>
        <form className="add-comment custom-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-12 form-group">
                <label htmlFor="nom">{t("Nom")}* :</label>
                <input {...register("nom", { required: true })} className="form-control" />
                {errors.nom ? <span className="error d-block">{t("Ce champ est obligatoire")}</span> : <></>}
            </div>
            <div className="col-md-12 form-group">
                <label htmlFor="prenom">{t("Prénom")}* :</label>
                <input {...register("prenom", { required: true })} className="form-control" />
                {errors.prenom ? <span className="error d-block">{t("Ce champ est obligatoire")}</span> : <></>}
            </div>
            <div className="col-md-12 form-group">
                <label htmlFor="email">{t("Email")}* :</label>
                <input type="email" {...register("email", { required: true })} className="form-control" />
                {errors.email ? <span className="error d-block">{t("Ce champ est obligatoire")}</span> : <></>}
            </div>
            <div className="col-md-12 form-group">
                <label htmlFor="telephone">{t("Téléphone")}* :</label>
                <input type="tel" {...register("telephone", { required: true, maxLength: 10 })} className="form-control" />
                {errors.telephone ? <span className="error d-block">{t("Ce champ est obligatoire")}</span> : <></>}
            </div>
            <div className="col-md-12 form-group">
            <label htmlFor="sujet">{t("Sujet")}* :</label>
            <select {...register("sujet", { required: true })} className="bs-select-hidden sujet-form-contact">
                <option value="Demande d'information">{t("Demande d'information")}</option>
                <option value="Demande de devis">{t("Demande de devis")}</option>
                <option value="Demande contact entreprise">{t("Demande contact entreprise")}</option>
            </select>
            {errors.sujet ? <span className="error d-block">{t("Ce champ est obligatoire")}</span> : <></>}
            </div>
            <div className="col-md-12 form-group">
            <label htmlFor="message">{t("Message")}* :</label>
            <textarea cols="40" rows="3" placeholder={t("Message")} {...register("message", { required: true })} style={{ "height": "100px", "padding": "15px 20px", "border-radius": "5px" }} className="form-control"></textarea>
            </div>
            <button style={{ "border": "1px solid #e11a18", "background": "#e11a18", "float": "right", "margin": "10px 0px" }} className="site-button btn-b2b" type="submit">{t("Envoyer")}</button>
        </form>
    </>
    );
}