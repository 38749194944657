import React, { Component } from 'react';
import Conference from '../../services/conference';
import { Link } from 'react-router-dom';
import { Markup } from 'react-render-markup';
import ItemLoader from './_item';
import Item from './item';
import { b2bNameSite, b2bDescriptionSite } from '../../config.json';
import Pagination from "react-js-pagination";
import Modal, { closeStyle } from 'simple-react-modal';
import Login from '../header/loginheader';
import jwt_decode from "jwt-decode";
import Auth from '../../services/Auth';
import MetaTags from 'react-meta-tags';
import i18next from 'i18next';
import ReactGA from 'react-ga';
import { withTranslation } from 'react-i18next';
import './conference.css';
import Slider from '../Slider/SliderFacebook';
class Index extends Component {
    constructor(props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        super(props)
        var userLogin = false;
        var dataUser = null;
        if (localStorage.getItem('tokenb2b') && localStorage.getItem('tokenb2b') != "undefined" && localStorage.getItem('tokenb2b') != "") {
            userLogin = true;
            dataUser = jwt_decode(localStorage.getItem('tokenb2b'));
        }
        this.state = {
            items: [<ItemLoader t={this.props.t} />, <ItemLoader t={this.props.t} />, <ItemLoader t={this.props.t} />, <ItemLoader t={this.props.t} />, <ItemLoader t={this.props.t} />, <ItemLoader t={this.props.t} />, <ItemLoader t={this.props.t} />, <ItemLoader t={this.props.t} />, <ItemLoader t={this.props.t} />, <ItemLoader t={this.props.t} />],
            search_mot_cle: "",
            dataUser: dataUser,
            userLogin: userLogin,
            activePage: 1,
            totalItemsCount: 0,
            itemsPerPage: 6,
            progressPage: 0,
            showProgressPage: true,
            modaleLogin: false,
        }
        Auth.isLogin().then((res) => {
            if (res.data != false) {
                this.setState({
                    userLogin: true,
                    dataUser: res.data
                });
            } else {
                this.setState({
                    userLogin: false,
                    dataUser: null
                });
            }
        });
        setInterval(() => {
            if (this.state.progressPage < 80) {
                this.setState({ progressPage: this.state.progressPage++ });
            }
        }, 100);

        Conference.getConference(1, "").then((res) => {
            if (res.data.get) {
                var items = [];
                res.data.data.forEach(actualite => {
                    items.push(<Item
                        t={this.props.t}
                        userLogin={this.state.userLogin}
                        key={actualite.id}
                        data={actualite}
                        open={this.open}
                    />);
                });
                this.setState({ progressPage: 100 });
                setTimeout(() => {
                    this.setState({ showProgressPage: false });
                }, 1000);
                this.setState({ items: [] });
                this.setState({ items: items });
                this.setState({ totalItemsCount: res.data.nbposts });
            }
        });

    }
    changeSearch = (e) => {
        this.setState({
            search_mot_cle: e.target.value
        });
    }
    submitSearch = () => {
        Conference.getConference(this.state.activePage, this.state.search_mot_cle).then((res) => {
            if (res.data.get) {
                var items = [];
                res.data.data.forEach(actualite => {
                    items.push(<Item
                        t={this.props.t}
                        userLogin={this.state.userLogin}
                        key={actualite.id}
                        data={actualite}
                        open={this.open}
                    />);
                });
                this.setState({ items: [] });
                this.setState({ items: items });
                this.setState({ totalItemsCount: res.data.nbposts });
            }
        });
    }
    handlePageChange(pageNumber) {

        this.setState({ activePage: pageNumber });
        this.initItems();
        Conference.getConference(pageNumber, this.state.search_mot_cle).then((res) => {
            if (res.data.get) {
                var items = [];
                res.data.data.forEach(actualite => {
                    items.push(<Item
                        t={this.props.t}
                        userLogin={this.state.userLogin}
                        key={actualite.id}
                        data={actualite}
                        open={this.open}
                    />);
                });
                this.setState({ items: [] });
                this.setState({ items: items });
                this.setState({ totalItemsCount: res.data.nbposts });
            }
        });
    }

    initItems() {
        window.scrollTo(0, 0);
        this.setState({
            items: [
                <ItemLoader t={this.props.t} />,
                <ItemLoader t={this.props.t} />,
                <ItemLoader t={this.props.t} />,
                <ItemLoader t={this.props.t} />,
                <ItemLoader t={this.props.t} />,
                <ItemLoader t={this.props.t} />,
                <ItemLoader t={this.props.t} />,
                <ItemLoader t={this.props.t} />
            ]
        });
    }
    close = () => {
        this.setState({
            modaleLogin: false
        })
    }
    open = () => {
        this.setState({
            modaleLogin: true
        })
    }

    render() {
        const { t } = this.props;

        return (
            <>
                {this.state.showProgressPage ? <div class="progress-div" style={{ width: "100%" }}><div style={{ width: this.state.progressPage + "%" }} class="progress"></div></div> : <></>}
                <MetaTags>
                    <title>{t('CONFÉRENCE B2B')} | {b2bNameSite} | {b2bDescriptionSite}</title>
                </MetaTags>
                <div className="modal-login-hadeer">
                    <Modal
                        closeOnOuterClick={true}
                        show={this.state.modaleLogin}
                        onClose={this.close.bind(this)}
                    >
                        <div classNameName="div-loginn">
                            <div className="connxtion_header">
                                <h2 id="to_text_pupup--" className="size-titre-b2b grie-b2b">
                                    {t("VEUILLEZ-VOUS CONNECTER POUR POUVOIR ACCÉDER AUX DÉTAILS DE CETTE ANNONCE:")} :
                                </h2>
                                <div className="divbtnclose">
                                    <span className="close closemodalheader" onClick={this.close.bind(this)}>×</span>
                                </div>
                            </div>
                            <Login />
                        </div>
                    </Modal>
                </div>
                <div className="page-content page-conference">
                    <section id="list-places-wrapper" style={{ marginTop: 0, backgroundColor: '#fff' }}>
                        <div className="container" style={{ backgroundColor: '#fff' }}>
                            <br /><br />
                            <div className="row">
                                <div className="col-md-8">
                                    <form role="search" className="place_search_form" action method="GET" style={{ marginBottom: '30px' }}>
                                        <div className="row" style={{ position: "relative" }}>
                                            <div className="col-md-11 de-search-form " id="search-places" style={{ border: "none" }}>
                                                <div className="col-md-12" style={{ border: '1px solid #b8c7c8' }}>
                                                    <div className="de-scontrol">
                                                        <input type="text" name="mot_cle" onChange={this.changeSearch} className="keyword_search" placeholder={t('Entrez un mot-clé') + "..."} value={this.state.search_mot_cle} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-1 p-0 btn-search-actualite" style={{ marginLeft: '-15px' }}>
                                                <button className="btn btn-primary" type="button" onClick={this.submitSearch} style={{ backgroundColor: '#ED2829', border: 'none', height: '60px', width: '100%', float: 'right' }}>
                                                    <i className="fa fa-search fa-lg" /></button>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="row">
                                        {this.state.items}
                                    </div>
                                    <div className="paginations-wrapper">
                                        <div className="paginations">
                                            {this.state.totalItemsCount > this.state.itemsPerPage ?
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={this.state.itemsPerPage}
                                                    totalItemsCount={this.state.totalItemsCount}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChange.bind(this)}
                                                />
                                                : <></>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="borde-block">
                                        <Slider />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

export default withTranslation()(Index);



