import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Experts from '../../services/experts';
import Enteprises from '../../services/enteprises';
import Button from '../Comptes/expert/button';

export default function ProposerEmploi(props) {
    var today = new Date();
    const [maxdate, setMaxdate] = useState(today.getFullYear()+'-'+(today.getMonth()>10?today.getMonth()+1:"0"+(today.getMonth()+1))+'-'+today.getDate());
    const [expertid, setExpertid] = useState(props.expertid);
    const [type_demploi, setType_demploi] = useState();
    const [budget, setBudget] = useState();
    const [emplacement, setEmplacement] = useState([]);
    const [domaine, setDomaine] = useState(); 
    const [loderFrome, setLoderFrome] = useState("d-none");
    const [domaines, setDomaines] = useState(null);

    const [selectCompetences, setSelectCompetences] = useState([]);
    const [optionCompetences, setOptionCompetences] = useState([]);
    const [sund, setSund] = useState(false);
    const { register, handleSubmit, reset , formState: { errors } } = useForm();
    const onSubmit = (data) => 
    {
        setLoderFrome("loder-from");
        Experts.registerProposerEmploi(expertid,data,selectCompetences).then((res)=>{
            if(res.data.register){
               reset(); 
               setSund(true);
               setLoderFrome("d-none");
            }
        });
    }
    const updateCompetences= (val,stat)=>{
        var tab =[];
        if(stat=="add"){
            tab = selectCompetences;
            tab.push(val);
        }else if(stat=="del"){
            var index = selectCompetences.indexOf(val);
            tab = selectCompetences;
            tab.splice(index, 1)
        } 
        console.log(tab);
        setSelectCompetences(tab);
    }
    const chingeDomaine = (id)=>{
        domaines.forEach(domaine => {
            if(domaine.id==id){
                var tags=[];
                domaine.childes.forEach(childe => {
                    tags.push(<Button tag={childe.name} id={childe.id} update={updateCompetences}/> ) 
                });
                setSelectCompetences([]);
                setOptionCompetences(tags);
            }
        });

    }
    useEffect(() => {
        Enteprises.getRegions().then((res)=>{
            if(res.data.get){
                var emplacements = [];
                res.data.data.forEach(val => {
                    emplacements.push(<option value={val.id}>{val.name}</option>);
                });
                res.data.childrens.forEach(val => {
                    emplacements.push(<option value={val.id}>{val.name}</option>);
                });
                setEmplacement(emplacements);
            }   
        });
        Experts.getProposerPM().then((res)=>{
            if(res.data.get){
                console.log("---------------------------------------getProposerPM-------------------------------------------");
                console.log(res.data);
                console.log("---------------------------------------getProposerPM-------------------------------------------");
                var types_demploi = [];
                res.data.type_demploi.forEach(val => {
                    types_demploi.push(
                    <fieldset className="radio" >
                        <input type="checkbox" id={"type_demploi"+val.id} value={val.id} {...register("type_demploi",{ required: true })} style={{display: "none"}}/>   
                        <label for={"type_demploi"+val.id} >{val.name}</label>
                    </fieldset>
                    );
                });
                setType_demploi(types_demploi);

                var budgets = [];
                res.data.budgets.forEach(val => {
                    budgets.push(
                    <fieldset className="radio" >
                        <input type="radio" id={"budget"+val.id} value={val.id} {...register("budget",{ required: true })} style={{display: "none"}}/>   
                        <label for={"budget"+val.id} >{val.name}</label>
                    </fieldset>
                    );
                });
                setBudget(budgets);

                var domaines = [];
                res.data.domaines_de_travail.forEach(val => {
                    domaines.push(<option value={val.id}>{val.name}</option>);
                });
                setDomaine(domaines);
                setDomaines(res.data.domaines_de_travail);
                //console.log(res.data.domaines_de_travail);
            }
        });
    }, []);
    return (
        <div className="connxtion_body" style={{position:"relative"}}>

            <div className="content-ihc">
                {sund?
                <div style={{textAlign: 'center', paddingTop: '7em'}}>
                    <h2>Votre demande a été enregistrée avec succès</h2>
                    <i className="fa fa-check" style={{fontSize:"90px",color:"green"}}/>
                </div>:<>
                <div className={loderFrome}>
                    <span className='loading-login'></span>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-12 form-group">
                            <label>Poste proposée<span className="star">*</span> :</label>
                            <input  className="form-control required" {...register("titre", { required: true  })} style={{border: '1px solid #ccc', borderRadius: 0, padding: '0.5rem',height:"40px" , marginBottom: "10px"}} />
                            {errors.titre?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                        <div className="col-md-12 form-group">
                            <label>Type de contrat<span className="star">*</span> :</label>
                            <div className="flex-space-between">{type_demploi}</div>
                            {errors.type_demploi?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                        <div className="col-md-12 form-group">
                            <label>Salaire approximatif proposé<span className="star">*</span> :</label>
                            <div className="flex-space-between">{budget}</div>
                            {errors.budget?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Lieu de travail proposé<span className="star">*</span> :</label>
                            <select {...register("emplacement",{ required: true } )}className="form-control">
                                <option value="">Choisir</option>
                                {emplacement}
                            </select>
                            {errors.emplacement?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                        <div className="col-md-6 form-group">
                            <label htmlFor="domaine">Domaine d’expertises* :</label>
                            <select {...register("domaine",{ required: true ,onChange:(e) => {chingeDomaine(e.target.value)}})} className="form-control">
                                <option value="">Choisir</option>
                                {domaine}
                            </select>
                            {errors.domaine?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                        <div className="col-md-12 form-group">
                            <label>Compétences recherchées<span className="star">*</span> :</label>
                            <div id="responsecontainer">
                                {optionCompetences}
                            </div>
                        </div>
                        <div className="col-md-12 form-group">
                            <label>Date d'expiration<span className="star">*</span> :</label>
                            <input type="date" className="form-control required" min={maxdate} {...register("date_fin", { required: true })} style={{border: '1px solid #ccc', borderRadius: 0, padding: '0.5rem',height:"40px" , marginBottom: "10px"}} />
                            {errors.date_fin?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                        <div className="col-md-12 form-group">
                            <label>Déception détaillé de l’offre<span className="star">*</span> :</label>
                            <textarea type="date" className="form-control required" {...register("description", { required: true })}  style={{border: '1px solid #ccc', borderRadius: 0, padding: '0.5rem',height:"100px" , marginBottom: "10px"}}/>
                            {errors.description?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                        </div>
                    </div>
                    <input type="submit" value="Proposer l’emploi" className="btn btn-b2b"/>
                </form>
                </>}
            </div>
        </div>
    );
}
