import http from "./httpService";
import { apiUrl } from  "../config.json";

const getCountry = () => {
    const apiEndoint =  apiUrl+"/devis/getCountry.php";
    return http.get(apiEndoint);
}
const getInfo = () => {
    const apiEndoint =  apiUrl+"/devis/getInfoDevis.php";
    return http.get(apiEndoint);
}
const registerDevieOffline = (data,prodId,parent) => {
    console.log("parentoff");console.log(parent);
    const formData = new FormData();
    formData.append("envoyer_devis", "oui");
    formData.append("produitID", prodId);
    formData.append("produitCompany", parent);
    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });
    const apiEndoint =  apiUrl+"/devis/registerDevieOffline.php";
    return http.post(apiEndoint,formData);
}
const registerDevie = (data,prodId,parent) => {
    console.log("parent");console.log(parent);
    const formData = new FormData();
    formData.append("envoyer_devis", "oui");
    formData.append("produitID", prodId);
    formData.append("produitCompany", parent);
    formData.append("token", localStorage.getItem("tokenb2b"));
    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });
    const apiEndoint =  apiUrl+"/devis/registerDevie.php";
    return http.post(apiEndoint,formData);
}

const registerDevieExpress = (data) => {
    const formData = new FormData();
    if(localStorage.getItem("tokenb2b") && localStorage.getItem("tokenb2b")!=""){
        formData.append("token", localStorage.getItem("tokenb2b"));
    }
    Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
    });
    const apiEndoint =  apiUrl+"/devis/registerDevieExpress.php";
    return http.post(apiEndoint,formData);
}
const getCurrentUser = () => {
    const formData = new FormData();
    if(localStorage.getItem("tokenb2b") && localStorage.getItem("tokenb2b")!=""){
        formData.append("token", localStorage.getItem("tokenb2b"));
    }
    const apiEndoint =  apiUrl+"/devis/getCurrentUser.php";
    return http.post(apiEndoint,formData);
}
export default 
{
    getCountry,
    registerDevieOffline,
    registerDevie,
    getInfo,
    registerDevieExpress,
    getCurrentUser
}