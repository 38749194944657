import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './conference.css';
class ItemLoader extends Component
{
    render() {
        const {t} =this.props; 
        return ( 
            <div className="col-md-6" style={{marginBottom: '20px'}}>
                <div className="wpnaw-news-overlay-main-wrap">
                    <a className="wpnaw-link-overlay" href="#" />     
                        <div className="wpnaw-news-image-bg" style={{}}>
                           <div className="lowder-image-post"></div>
                        </div>
                        <div className="wpnaw-news-grid-content">
                           
                            <div className="wpnaw-news-date" style={{float: 'right', padding: '4px 0', fontSize: '12px'}}>     </div>
                            <div className="wpnaw-title-content">
                                <h2 className="wpnaw-news-title">
                                    <a href="#" target="_self" style={{fontSize: '17px', textTransform: 'uppercase'}}>    </a>
                                </h2>
                            </div>
                        </div>
                </div>
                <div className="wpnaw-news-content">
                    <div className="wpnaw-news-short-content lowder-text-post" ></div>
                    <a href="#" target="_self" className="readmorebtn" style={{float: 'right', color: '#e11a18'}}>{t('Lire la suite')}</a>
                </div>
            </div>
        );
    }
}
export default withTranslation()(ItemLoader);