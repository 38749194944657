import './style.css';
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Auth from '../../services/Auth';
import { Link } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import {b2bNameSite,b2bDescriptionSite} from '../../config.json';
import { Redirect } from 'react-router';
import i18next from 'i18next';
import ReactGA from 'react-ga'
export default class Index extends Component
{

    constructor (props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        super(props)
        this.state = {
           
        } 
      
    }
    render() {
        return ( 
            <>
            <MetaTags>
                <title>Confirmer votre compte | {b2bNameSite} | {b2bDescriptionSite}</title>
            </MetaTags>
            <div className="page-content">
                
                <section style={{backgroundColor: '#fff', minHeight: '450px', marginBottom: '-40px'}}>
                    <div className="container">
                        <div className="col-md-12 widget de_widget_categories prod-list" style={{backgroundColor: '#fff'}}>
                            <div style={{margin: '50px auto'}}>
                            <section className="activation-notification" style={{backgroundColor: '#fff'}}>
                                <div className="activation-notification-content">
                                    <p>
                                        <span>
                                            <i className=""  style={{
                                                backgroundImage:"url(/assets/img/confirme-compte.png)",
                                                width:"100px",
                                                height:"100px",
                                                display:"block",
                                                margin: "auto",
                                                backgroundPosition: "center",
                                                backgroundSize: "contain"
                                            }}/>
                                        </span>
                                    </p>
                                    <h1 className="title__bordered" style={{fontSize:"22px"}}>VOTRE COMPTE EST ENCOURS DE VALIDATION.</h1>
                                    <p className="" style={{fontSize:"22px"}}>Vous recevrez un email dès que votre ENTREPRISE sera validée et affichée sur B2B.TN©</p>
                                    <p style={{fontSize:"18px"}}>Nous tenons à vous informer que l'activation de votre compte ainsi que l'affichage de votre page entreprise sur B2B.TN© sont assurés manuellement ; est ce dans le but de vous fournir autant de sécurité et de fiabilité dans la mesure de vos attentes, et pour garantir le maximum possible de crédibilité aux utilisateurs de notre réseau international de plateformes de mise en relation B2B.</p>
                                    <p style={{fontSize:"18px"}}>Nous vous remercions d’avance pour votre confiance.</p>
                                </div>
                            </section>
                            </div>
                        </div>
                    </div>
                   
                </section>
            </div>
            </>
        );
    }
}


