import React, { Component } from 'react';
import { useEffect } from "react";
import { useState } from "react";
import OffresMission from '../../services/offresMission';
import OffresEmploi from '../../services/offresEmploi';
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import i18next from 'i18next';
import testOnline from '../../services/testOnline';
import { Redirect } from 'react-router'
import { withTranslation } from 'react-i18next';
import './style.css';

function TestOnline(props) {
    const { postid } = useParams();

    const [redirect, setRedirect] = useState(<></>);
    useEffect(() => {
        // window.scrollTo(0, 0);
        // if(document.getElementById("header_b2b"))document.getElementById("header_b2b").style.display="block";
        // if(localStorage.getItem('tokenb2b') && localStorage.getItem('tokenb2b')!="undefined" && localStorage.getItem('tokenb2b')!=""){
        //     var data_user = jwt_decode(localStorage.getItem('tokenb2b'));
        //     if(data_user && data_user.code_user>0){}else{
        //         setRedirect(<Redirect to={"/"+i18next.language+"/404"} />);
        //     }
        // }else{
        //     setRedirect(<Redirect to={"/"+i18next.language+"/404"} />);
        // }
    }, []);
    return (<>
        {redirect}
        <Index postid={props.postid>0?props.postid:postid}/>
    </>);
}
 
export default withTranslation()(TestOnline);

class Index extends Component
{

    constructor (props) {
        super(props)
 
        var userLogin=false;
        var data_user=null;
        var redirect=<></>;
        if(document.getElementById("header_b2b"))document.getElementById("header_b2b").style.display="block";
        if(localStorage.getItem('tokenb2b') && localStorage.getItem('tokenb2b')!="undefined" && localStorage.getItem('tokenb2b')!=""){
            var data_user = jwt_decode(localStorage.getItem('tokenb2b'));
            if(data_user && data_user.code_user>0){
                userLogin=true;
                data_user=data_user;
            }else{
                redirect = <Redirect to={"/"+i18next.language+"/404"} />;
            }
        }else{
            redirect = <Redirect to={"/"+i18next.language+"/404"} />;
        }

        
        this.state = {
            startTest:false,
            numquestion:0,
            blockQuestion:<></>,
            userLogin:userLogin,
            dataUser:data_user,
            dataTest:null,
            redirect:redirect,
            reponses:[]
        }
        testOnline.getTestOnline(props.postid).then((res)=>{
            if(res.data.get){
                this.setState({dataTest:res.data.data});
            }else{
                if(res.data.qsm=='ok'){
                    this.setState({
                        dataTest:res.data.data,
                        startTest:true,
                        blockQuestion:<div className='test-fin'>Votre demande est en cours de traitement</div>
                    });
                }else if(res.data.type=="expert"){
                    this.setState({
                        dataTest:res.data.data,
                        startTest:true,
                        blockQuestion:<div className='test-fin' style={{color:"red"}}>Votre compte ne correspond pas à cette offre</div>
                    });
                }else{
                    this.setState({redirect:<Redirect to={"/"+i18next.language+"/404"} />})
                }
            }
        });
    }
    envoyerquestions=(reponse)=>{
        var reponses = this.state.reponses;
        reponses.push(reponse);
        var join_reponses = reponses.join(';##;');
        this.setState({
            reponses:reponses,
            blockQuestion:<VosMotivations reponses={join_reponses} offreid={this.props.postid} testId={this.state.dataTest.ID} fintest={
                ()=>{
                    this.setState({
                        blockQuestion:<div className='test-fin'>Bravo, Finition du Test </div>
                    });  
                }
            } />
        });
    }
    nextquestion=(reponse,num)=>{

        var reponses = this.state.reponses;
        reponses.push(reponse);
        this.setState({
            reponses:reponses,
            blockQuestion:<></>
        });
        //var num = this.state.numquestion+1;
        this.setState({numquestion:++num});
        var fin=false;
        console.log("-------------num---------------------");
        console.log(num);
        console.log(this.state.dataTest.questions.question.length);
        console.log(num+1>=this.state.dataTest.questions.question.length);
        console.log("------------fin-num---------------------");
        if(num+1>=this.state.dataTest.questions.question.length)fin=true;
        setTimeout(() => {
            this.setState({blockQuestion:<Question fin={fin} data={ this.state.dataTest.questions} question={num} next={this.nextquestion} envoyer={this.envoyerquestions} />});
        }, 1);
    }
    clickStartTest=()=>{
        this.setState({
            numquestion:0,
            startTest:true,
            blockQuestion:<Question fin={false} data={this.state.dataTest.questions} question={0} next={this.nextquestion} envoyer={this.envoyerquestions}/>
        })
    }
   
    render() {
        const {t}=this.props;
        
        return (<>
            {this.state.redirect}
            {this.state.dataTest?<>
            <section id="list-places-wrapper p-0">
                <div className="container" style={{backgroundColor: '#fff'}}>
                    <div className="col-md-12 widget de_widget_categories prod-list p-0 m-0">
                        <h3 className="widgettitle p-0 m-0 text-center">{this.state.dataTest.post_title}</h3>
                    </div>
                    <br /><br />
                    {this.state.startTest?<>{this.state.blockQuestion}</>:<>
                    <div id="divcommencer" className='text-center'>
                        <div className='btn btn-b2b' onClick={this.clickStartTest}>Commencer le Test  maintenant</div>
                        {/* <h3>votre demande est en cours de traitement</h3> */}
                    </div>
                    </>}
                </div>
            </section>
            </>:<></>}
            
        </>)
    }
}
class Question extends Component
{
    
    constructor (props) {
        super(props)
        this.state = {
            numquestion:props.question,
            duree:this.props.data.duree[this.props.question],
            question:this.props.data.question[this.props.question],
            reponse:"",
            countTime:"00:00",
            clearMyInterval:this.props.data.duree[this.props.question]!=null?true:false
        }
        console.log("-----state-------");
        console.log(this.state);
        console.log(this.props.data.question);
        console.log(this.props.fin);
        console.log("-----fin-state-------");
        if(this.state.duree!=null){
            var duree = parseInt(this.state.duree);
            var mm=0;
            var ss=0;
            const countTimeInterval = setInterval(() => {
               // if(this.state.clearMyInterval)
                    if(duree==0){
                        this.clicknext()
                        clearInterval(countTimeInterval);
                    }else{
                        duree --; 
                        mm=Math.floor(duree/60);
                        ss=duree%60;
                        if(mm<10) mm = "0"+mm;
                        if(ss<10) ss = "0"+ss;
                        this.setState({countTime:mm+":"+ss});
                    }         
            }, 1000);
        }
    }
    clicknext=()=>{
        this.setState({clearMyInterval:false});
        if(this.props.fin){
            this.props.envoyer(this.state.reponse)
        }else{
            this.props.next(this.state.reponse,this.state.numquestion)
        }
    }
   
    render() {
        const {t}=this.props;
        return (
         <div className="block-question-active">
            <div className='row'>
                
                <div className='col-md-12'>
                    <div className="form-group">
                        <p>Question {this.state.numquestion+1} <span>*</span></p>   
                        <div className='d-flex align-items-center' style={{border:"solid 1px #aaa",borderRadius:"5px"}}>
                            <span className="icon-case"><i className="fa fa-question" /></span>
                            <span id="question" className="input">{this.state.question}</span>
                        </div> 
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className="form-group">
                        <p>Votre Réponse <span>*</span></p>
                        <div className='d-flex align-items-center' style={{border:"solid 1px #aaa",borderRadius:"5px"}}>
                            <span className="icon-case mr-0"><i className="fa fa-comments-o" /></span>
                            <textarea rows={14}  onChange={(e)=>this.setState({reponse:e.target.value})}/>
                        </div> 
                    </div>
                </div>
                <div className='col-md-12 d-flex align-items-center justify-content-end'>
                    {this.state.duree==null?<>
                        <span className="btn btn-b2b" onClick={this.clicknext}><span className="px-4">Next</span></span>
                    </>:<>
                    <div style={{display: "grid"}}>
                        <span className="input_duree" style={{fontSize:"20px",fontWeight: "600"}}>Durée : <span id="duree_1"/>{this.state.countTime}</span>
                        {this.props.fin?<>
                        <span className="btn btn-b2b" onClick={this.clicknext}><span className="px-4">Next</span></span>
                        </>:<></>}
                        
                    </div>
                    </>}
                </div>
            </div>
        </div>
        )
    }
}


class VosMotivations extends Component
{
    
    constructor (props) {
        super(props)
        this.state = {
            reponse:""
        }
    }
    clicknext=()=>{
        if(this.state.reponse!=""){
            testOnline.registerTestOnline(this.props.testId,this.props.reponses).then((res)=>{
                if(res.data.add){
                    testOnline.getPostByID(this.props.offreid).then((res)=>{
                        if(res.data.get){
                            const data={
                                objet:"Candidature pour «"+res.data.post.post_title+"»",
                                message:this.state.reponse
                            }
                            if(res.data.post.post_type=="offre-emploi"){
                                OffresEmploi.registerMessage(res.data.post.company_ID,res.data.post.company_user,this.props.offreid,data).then((res)=>{
                                    if(res.data.register){
                                        this.props.fintest()
                                    }
                                });
                            }
                            if(res.data.post.post_type=="offre-freelance"){
                                OffresMission.registerMessage(res.data.post.company_ID,res.data.post.company_user,this.props.offreid,data).then((res)=>{
                                    if(res.data.register){
                                        this.props.fintest()
                                    }
                                });
                            }
                        }
                    });
                    
                }
            })
       }
    }
   
    render() {
        const {t}=this.props;
        return (
         <div className="block-question-active">
            <div className='row'>
                <div className='col-md-12'>
                    <div className="form-group">
                        
                        <div className='d-flex align-items-center' style={{border:"solid 1px #aaa",borderRadius:"5px"}}>
                            <span className="icon-case"><i className="fa fa-question" /></span>
                            <span id="question" className="input">QUELLES SONT VOS MOTIVATIONS POUR CE OFFRE ? (en quelques mots):</span>
                        </div> 
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className="form-group">
                        <p>Votre Réponse <span>*</span></p>
                        <div className='d-flex align-items-center' style={{border:"solid 1px #aaa",borderRadius:"5px"}}>
                            <span className="icon-case mr-0"><i className="fa fa-comments-o" /></span>
                            <textarea rows={14}  onChange={(e)=>this.setState({reponse:e.target.value})} placeholder="Pourquoi êtes-vous le candidat idéal? Qu’est-ce qui vous démarque des autres candidats? Quels sont vos qualités uniques? "/>
                        </div> 
                    </div>
                </div>
                <div className='col-md-12 d-flex align-items-center justify-content-end' >
                    <span className="btn btn-b2b" onClick={this.clicknext}><span className="px-4">Envoyer</span></span>
                </div>
            </div>
        </div>
        )
    }
}
