import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel';
import DataHome from '../../services/home';
import i18next from 'i18next';
import { Markup } from 'react-render-markup';
import './style.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class Actualites extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [<ItemLoader />, <ItemLoader />, <ItemLoader />]
        }
        DataHome.getTopPosts().then((res) => {
            if (res.data.get) {
                var items = [];
                res.data.data.forEach(post => {
                    if (i18next.language == "fr" || i18next.language == "fr-FR") {
                        items.push(<Item href={post.url} image={post.img} titre={post.title} categorie={post.categorie} datejj={post.datedd} dateMM={post.dateFF} description={post.content} />);
                    } else if (i18next.language == "en") {
                        items.push(<Item href={post.url} image={post.img} titre={post.en_title} categorie={post.categorie} datejj={post.datedd} dateMM={post.dateFF} description={post.en_content} />);
                    } else {
                        items.push(<Item href={post.url} image={post.img} titre={post.title} categorie={post.categorie} datejj={post.datedd} dateMM={post.dateFF} description={post.content} />);
                    }
                   
                });
                setTimeout(() => {
                    this.setState({ items: [] });
                    this.setState({ items: items });
                }, 1000);
            }
        });
    }
    render() {
        const { t } = this.props;
        const items = [<ItemLoader />, <ItemLoader />, <ItemLoader />];
        return (
            <div className="section-full overlay-black-middle bg-img-fix content-inner-1" style={{ backgroundColor: "#fff" }}>
                <div className="container">
                    <div className="section-head text-white text-center text-actu-b2b">
                        <h2 className="text-uppercase"> {t("ACTUALITÉS B2B")}</h2>
                        <div className="dlab-separator-outer ">
                            <div className="dlab-separator bg-secondry style-skew"></div>
                        </div>
                    </div>
                    <div className="section-content section-actualite">
                        <AliceCarousel
                            mouseTracking={true}
                            autoPlay={true}
                            items={this.state.items}
                            infinite
                            buttonsDisabled={true}
                            dotsDisabled={true}

                            autoPlayInterval={4000}
                            responsive={{ 1200: { items: 3 }, 1000: { items: 3 }, 800: { items: 2 }, 700: { items: 1 } }} />
                    </div>
                </div>
            </div>
        );
    }
}

class Item extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="item m-2 item-actualite" style={{ border: "1px gray solid" }}>
                <Link to={"/" + i18next.language + this.props.href}>
                    <div className="blog-post latest-blog-1 date-style-3 skew-date">
                        <div className="dlab-post-media dlab-img-effect p-t30 m-l30" >
                            <Link class="link-img" to={"/" + i18next.language + this.props.href}  ><img onContextMenu={(e) => e.preventDefault()} src={this.props.image} alt={this.props.titre} style={{ objectFit: "cover" }} /></Link>
                        </div>
                        <div className="dlab-post-info p-t20 m-l30 ow-entry-content" style={{ height: "200px", width: "83.5%" }}>
                            <div className="dlab-post-title ">
                                <h3 className="post-title"><Markup markup={this.props.titre} /></h3>
                            </div>
                            <div className="dlab-post-meta ">
                                <ul>
                                    <li className="post-date"> <i class="ti-calendar"></i><strong>{this.props.categorie}</strong> </li>
                                    {/* <li className="post-comment" style={{paddingLeft:"7px;"}}>
                            <span> {this.props.datejj}</span>
                            <strong>
                            {this.props.dateMM} </strong> 
                        </li> */}
                                </ul>
                            </div>
                            <div className="dlab-post-text">
                                <p><Markup markup={this.props.description} /></p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}

class ItemLoader extends Component {

    render() {

        return (
            <div className="item m-2">
                <div clasNames="blog-post latest-blog-1 date-style-3 skew-date">
                    <div className="dlab-post-media dlab-img-effect zoom-slow">
                        <div className="actualiter-loader-img"></div>
                    </div>
                    <div className="dlab-post-info p-t20 ow-entry-content" style={{ height: "270px" }}>
                        <div className="dlab-post-title ">
                            <h3 className="post-title actualiter-loader-title"></h3>
                        </div>
                        <div className="dlab-post-meta actualiter-loader-date">
                            <ul>
                                <li className="post-date"> <i class="ti-calendar"></i><strong className="actualiter-loader-categorie"></strong> </li>
                                <li className="post-comment " style={{ paddingLeft: "7px;" }}></li>
                            </ul>
                        </div>
                        <div className="dlab-post-text">
                            <p className="actualiter-loader-description"></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(React.memo(Actualites));