
import React, { Component } from 'react';
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import SousTritance from '../../services/sousTritance';
import Actualites from '../../services/actualites';
import AliceCarousel from 'react-alice-carousel';
import Slider from '../Slider/SliderEnVedette';

import Auth from '../../services/Auth'

import Login from '../header/loginheader';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useParams } from 'react-router-dom';
import { Markup } from 'react-render-markup';
import BtnPartage from '../Slider/btnPartage';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga'
function Detail(props) {
    let { slug } = useParams();
    const [offset, setOffset] = useState(0);
    const [userLogin, setuserLogin] = useState(false);
    const [dataUser, setdataUser] = useState(null);
    const [redirect, setredirect] = useState(false);
    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        Auth.isLogin().then((res) => {
            if (res.data !== false) {
                setuserLogin(true);
                setdataUser(res.data);
            } else {
                setuserLogin(false);
                setdataUser(null);

            }
            setredirect(true);
        });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    if (redirect) {
        if (userLogin) {
            return (
                <Index slug={slug} t={props.t} offset={offset} />
            );
        } else {
            return (
                <Redirect to={"/" + i18next.language + "/sous-tritance"} />
            );
        }
    } else {
        return (<></>);
    }
}
export default withTranslation()(React.memo(Detail));
class Index extends Component {
    constructor(props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        super(props)
        this.state = {
            slug: props.slug,
            blockmenu: <BlockPresentation t={this.props.t} data={null} />,
            data: null,
            lastoffre: null,
            userLogin: false,
            dataUser: null,
            modaleLogin: false,
            htmlModaleLogin: <></>,
            userpostule: false,
            sue_menu_entreprise_b2b: {}
        }
        SousTritance.getDetail(this.state.slug).then((res) => {
            if (res.data.get) {
                this.setState({
                    data: res.data.data,
                    lastoffre: res.data.lastoffre,
                    blockmenu: <BlockPresentation t={this.props.t} data={res.data.data} />,
                });
            }
        });
    }
    scrollMenu = (e) => {
        var win = window, doc = document, docElem = doc.documentElement, body = doc.getElementsByTagName('body')[0], x = win.innerWidth || docElem.clientWidth || body.clientWidth, y = win.innerHeight || docElem.clientHeight || body.clientHeight;
        document.getElementById("header_b2b").style.display = "none";
        this.setState({
            sue_menu_entreprise_b2b: {
                position: "fixed",
                zIndex: "999999",
                top: "0px",
                left: "0px",
                width: "100%",
                backgroundColor: "#fff",
            }
        })
        if (x < 700) {
            window.scrollTo(0, 550);
            setTimeout(() => {
                window.scrollTo(0, 550);
            }, 20);
        } else {
            window.scrollTo(0, 100);
            setTimeout(() => {
                window.scrollTo(0, 100);
            }, 20);
        }
    }
    selectItem = (e) => {
        var items = document.getElementsByClassName("active-item");
        var i;
        for (i = 0; i < items.length; i++) {
            items[i].classList.remove("active-item");
        }
        e.target.classList.add('active-item');
    }
    presentation = (e) => {
        this.selectItem(e);
        this.scrollMenu(e);
        this.setState({
            blockmenu: <BlockPresentation t={this.props.t} data={this.state.data} />
        });
    }
    entreprise = (e) => {
        this.selectItem(e);
        this.scrollMenu(e);
        this.setState({
            blockmenu: <BlockEntreprise t={this.props.t} data={this.state.data} />
        });
    }
    setuserpostule = (stat) => {
        this.setState({
            userpostule: stat
        });

    }

    postuler = (e) => {
        if (true) {
            this.selectItem(e);
            this.scrollMenu(e);
            this.setState({
                blockmenu: <BlockPostuler t={this.props.t} data={this.state.data} userLogin={this.state.userLogin} dataUser={this.state.dataUser} userpostule={this.state.userpostule} setuserpostule={this.setuserpostule} />
            });
        } else {
            this.setState({
                modaleLogin: true,
                htmlModaleLogin: <Login close={this.close.bind(this)} />
            });
        }
    }
    close = () => {
        this.setState({
            modaleLogin: false,
            htmlModaleLogin: <></>
        })
    }
    render() {
        let sue_menu_entreprise_b2b = {};
        let stylecontainer = {};
        if (this.props.offset < 10) {
            if (document.getElementById("header_b2b")) document.getElementById("header_b2b").style.display = "block";
            sue_menu_entreprise_b2b = {};
            stylecontainer = {};
        } else {
            if (document.getElementById("header_b2b")) document.getElementById("header_b2b").style.display = "none";
            stylecontainer = { marginTop: "145px" };
            sue_menu_entreprise_b2b = {
                position: "fixed",
                zIndex: "999999",
                top: "0px",
                left: "0px",
                width: "100%",
                backgroundColor: "#fff",
            };
        }

        const { t } = this.props;

        var btnPostuler = <div className="btn-postuler" onClick={this.postuler}>
            <span className="menu-item" href="#activites">{t("Postuler")}</span>
        </div>


        return (
            <div>

                <div className="container" style={stylecontainer}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="sue-menu" style={sue_menu_entreprise_b2b}>
                                <nav id="menu_desktop" className="scroll-nav scroll-init menu-desk">
                                    <ul>
                                        <li>
                                            <a onClick={this.presentation} href="#presentation" className="menu-item active-item grie-b2b">{t("DESCRIPTION DE L’OFFRE")} </a>
                                        </li>
                                        <li>
                                            <a onClick={this.entreprise} href="#entreprise" className="menu-item catalogue_button grie-b2b">{t("DESCRIPTION DE L’ENTREPRISE")}</a>
                                        </li>
                                        <li>
                                            <a onClick={this.postuler} href="#postuler" className="menu-item grie-b2b">{t("POSTULER")} </a>
                                        </li>

                                    </ul>
                                </nav>
                                <nav id="menu_mobile" className="scroll-nav scroll-init ">
                                    <ul className="row mx-0">
                                        <li className="col-xs-<?php echo $index_col; ?> px-1">
                                            <a onClick={this.presentation} href="#presentation" className="menu-item px-1 active-item grie-b2b">{t("DESCRIPTION")}</a>
                                        </li>
                                        <li className="col-xs-<?php echo $index_col; ?>  px-1">
                                            <a onClick={this.entreprise} href="#entreprise" className="menu-item px-1 catalogue_button">{t("L’ENTREPRISE")}</a>
                                        </li>
                                        <li className="col-xs-<?php echo $index_col; ?>  px-1">
                                            <a onClick={this.postuler} href="#postuler" className="menu-item px-1 grie-b2b">{t("POSTULER")}</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-md-4 px-1 pb-2">
                                    <div className="borde-block blockImageTitle-block" style={{ minHeight: "430px", height: "auto" }}>
                                        <BlockImageTitle t={t} data={this.state.data} btnpostuler={btnPostuler} dataUser={this.state.dataUser} userpostule={this.state.userpostule} setuserpostule={this.setuserpostule} />
                                    </div>
                                </div>
                                <div className="col-md-8 px-1 pb-2">
                                    <div className="borde-block blockmenu-block" style={{ minHeight: "430px", height: "auto" }}>
                                        {this.state.blockmenu}
                                    </div>
                                </div>

                                <div className="col-md-12 px-1 pb-2">
                                    <div className="borde-block">
                                        <BlockLastOffre t={t} data={this.state.data} lastoffre={this.state.lastoffre} />
                                    </div>
                                </div>
                                <div className="col-md-12 px-1 pb-2">
                                    <div className="borde-block">
                                        <BlockActualites t={t} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 px-1 ">
                            <div className="borde-block p-3">
                                <Slider />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class BlockImageTitle extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userpostule: props.userpostule
        }
        var timesRun = 0;
        var interval = setInterval(() => {
            if (this.props.data && this.props.dataUser) {
                this.props.data.users_postuler.forEach(userid => {
                    if (userid === this.props.dataUser.code_user) {
                        this.setState({ userpostule: true });
                        this.props.setuserpostule(true);
                    }
                });
            }
            timesRun += 1;
            if (timesRun === 10) {
                clearInterval(interval);
            }
        }, 1000);

    }
    render() {
        const { t } = this.props;
        if (this.props.data) {
            var content = "";
            var post_title = "";
            var url = "";
            if (i18next.language == "fr" || i18next.language == "fr-FR") {
                content = this.props.data.post_content;
                post_title = this.props.data.post_title;
                url = this.props.data.urlPartage;
            } else if (i18next.language == "en") {
                content = this.props.data.en_post_content;
                post_title = this.props.data.en_post_title;
                url = this.props.data.urlPartage.replace("/fr/", "/en/");
            } else {
                content = this.props.data.post_content;
                post_title = this.props.data.post_title;
                url = this.props.data.urlPartage;
            }
            return (
                <div className="list-single-main-item">
                    <h3 style={{ textAlign: 'center', color: 'red' }}>{this.props.data.post_title}</h3>
                    <figure style={{ textAlign: 'center' }}>
                        <img onContextMenu={(e) => e.preventDefault()} style={{ width: '100%', height: 'auto', objectFit: 'contain' }} src={this.props.data.image + ""} alt={this.props.data.post_title} />
                    </figure>
                    {this.state.userpostule ? <>
                        <p style={{ textAlign: "center" }}>{t("Vous avez déjà postulé pour cette offre de mission en Freelance")}</p>
                    </> : this.props.btnpostuler}
                    <p className="pb-0 mb-0">
                        <span style={{ fontWeight: 'bold', color: '#5d5f5e' }}>{t("Domaine")} : </span>
                        <div style={{ textAlign: "center", width: "calc(100% - 75px)", float: "right" }}>
                            <span>
                                <Link to={"/" + i18next.language + "/sous-tritance/?domaine=" + this.props.data.domaine.term_id}>
                                    <li className="li li-domaine">{this.props.data.domaine.name}</li>
                                </Link>
                            </span>
                        </div>
                    </p>
                    <div style={{ width: '100%', textAlign: 'center', display: 'flex', justifyContent: "center", alignItems: "center" }}>
                        <span style={{ width: '90px', height: '70px', display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>{t("PARTAGER SUR")} : </span>
                        <div style={{ width: 'calc(100% - 90px)', zoom: '80%' }}>
                            <BtnPartage
                                image={this.props.data.company_logo}
                                title={post_title}
                                content={content}
                                url={url} />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="list-single-main-item">
                    <h3 className="loader-entreprise-title"></h3>
                    <div className="loader-entreprise-image"></div>

                    <p className="pb-0 mb-0">
                        <span style={{ fontWeight: 'bold', color: '#5d5f5e' }}>{t("Domaine")} : </span>
                        <span><p className="loader-entreprise-text"></p></span>
                    </p>
                    <div >
                        <span style={{ fontWeight: 'bold' }}>{t("Partager")} : </span>
                        <p className="loader-entreprise-text"></p>
                    </div>
                </div>
            );
        }
    }
}
class BlockPresentation extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        const { t } = this.props;
        var domaines = [];

        if (this.props.data) {
            this.props.data.competences.forEach(domaine => {
                domaines.push(<p className="li li-domaine">- {domaine.name}</p>)
            });
            return (
                <div id="_presentation" className="presentation-body-item" style={{ height: "auto" }}>
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("DESCRIPTION DE L’OFFRE")} :</h3>
                    </div>
                    <div id="descr" className="col-md-12 block-descr px-1">
                        <div style={{ "text-align": "justify" }}>
                            <p><Markup markup={this.props.data.post_content} /></p>
                        </div>
                    </div>
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("DOMAINE DE L’OFFRE")} :</h3>
                    </div>
                    <div id="descr" className="col-md-12 block-descr px-1">
                        <div style={{ "text-align": "justify" }}>
                            {domaines}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div id="_presentation" className="presentation-body-item">
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("DESCRIPTION DE L’OFFRE")} :</h3>
                    </div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                </div>
            );
        }

    }
}
class BlockEntreprise extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        const { t } = this.props;
        if (this.props.data) {
            if (this.props.data.company_pack_nom === "pack_platinium" | this.props.data.company_pack_nom === "pack_premium") {
                return (<>
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("CHIFFRES CLÉS DE")}<span className="place_post_title"><Link to={"/" + i18next.language + "/entreprise/" + this.props.data.company_url}> <Markup markup={this.props.data.company_title} /> </Link></span> :</h3>
                    </div>
                    <div className="listing-features fl-wrap">
                        <div className="mb-0 pb-1"><i className="fa fa-calendar mr-1" aria-hidden="true" style={{ fontSize: '15px', color: 'red' }} /> <b>{t("Date Fondation")}</b> : {this.props.data.company_dateFondation}</div>
                        <div className="mb-0 pb-1"><i className="fa fa-users mr-1" aria-hidden="true" style={{ fontSize: '15px', color: 'red' }} /><b>{t("Effectifs")} :</b>{this.props.data.company_effectifs} {t("employés")}</div>
                        <div className="mb-0 pb-1"><i className="fa fa-cubes mr-1" aria-hidden="true" style={{ fontSize: '15px', color: 'red' }} /><b>{t("Produits et services")} :</b>{this.props.data.company_produits_services}</div>
                        {/* <div className="mb-0 pb-1"><b> Employés :</b>{this.props.data.nbr_employes}</div>                         */}
                    </div>
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("PRÉSENTATION DE")} <span className="place_post_title"><Link to={"/" + i18next.language + "/entreprise/" + this.props.data.company_url}><Markup markup={this.props.data.company_title} /></Link></span> :</h3>
                    </div>
                    <div id="descr" className="col-md-12 block-descr px-1">
                        <div style={{ "text-align": "justify" }}>
                            <p><Markup markup={this.props.data.company_content} /></p>
                        </div>
                    </div>
                </>
                );
            } else {
                return (
                    <div id="_presentation" className="presentation-body-item">
                        <div className="list-single-main-item-title fl-wrap mt-3">
                            <h3 className="grie-b2b">{t("DESCRIPTION DE L’ENTREPRISE")} :</h3>
                        </div>
                        <p>Vous bénéficiez actuellement du Pack CONNEXION, veuillez activer votre Pack BUSINESS ou Pack PRO pour bénéficier de cette fonctionnalité.</p>
                    </div>
                );
            }
        } else {
            return (
                <div id="_presentation" className="presentation-body-item">
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("DESCRIPTION DE L’ENTREPRISE")} :</h3>
                    </div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                </div>
            );
        }

    }
}
class BlockPostuler extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        const { t } = this.props;
        if (this.props.data) {
            if (this.props.data.company_pack_nom === "pack_platinium" | this.props.data.company_pack_nom === "pack_premium") {
                return (
                    <div id="_presentation" className="presentation-body-item">
                        <div className="list-single-main-item-title fl-wrap mt-3">
                            <h3 className="grie-b2b">{t("Postuler")} :</h3>
                        </div>
                        <div id="descr" className="col-md-12 block-descr px-1">
                            {(this.props.data.company_pack_nom === "pack_platinium" || this.props.data.company_pack_nom === "pack_premium") ? <>
                                {this.props.data.company_postuler === "non" ? <>
                                    {/* {this.props.dataUser && this.props.data && this.props.dataUser.code_user!=this.props.data.company_user?<>   */}
                                    <FormContact t={t} data={this.props.data} offreid={this.props.data.ID} />
                                    {/* </>} */}
                                </> : <div style={{ "text-align": "justify" }}>
                                    <p><Markup markup={this.props.data.company_postuler} /></p>
                                </div>}
                            </> : <>{t("Vous ne pouvez pas effectuer cette action avant la validation de votre compte.")}</>}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div id="_presentation" className="presentation-body-item">
                        <div className="list-single-main-item-title fl-wrap mt-3">
                            <h3 className="grie-b2b">{t("Postuler")} :</h3>
                        </div>
                        <p>Vous bénéficiez actuellement du Pack CONNEXION, veuillez activer votre Pack BUSINESS ou Pack PRO pour bénéficier de cette fonctionnalité.</p>
                    </div>
                );
            }
        } else {
            return (
                <div id="_activites" className="presentation-body-item">
                    <div className="list-single-main-item-title fl-wrap mt-3">
                        <h3 className="grie-b2b">{t("Postuler")} :</h3>
                    </div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                    <div className="block-descr-loder"></div>
                </div>
            );
        }

    }
}
class BlockLastOffre extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [<ItemLastOffre />, <ItemLastOffre />, <ItemLastOffre />, <ItemLastOffre />, <ItemLastOffre />, <ItemLastOffre />]
        }

    }
    render() {
        const { t } = this.props;
        if (this.props.lastoffre) {
            var items = [];
            this.props.lastoffre.forEach(actualite => {
                items.push(<ItemLastOffre actualite={actualite} />);
            });
            return (
                <div className="list-single-main-item">
                    <div className="list-single-main-item-title fl-wrap">
                        <h3 className="size-titre-b2b grie-b2b">{this.props.data.post_type === "offre-sous-traitance" ? t("OFFRES DE SOUS-TRAITANCE SIMILAIRES") : t("DEMANDE FOR SIMILAR SUBCONTRACTING")} :</h3>
                    </div>
                    <div className=" product-list no-padding" width="100%">
                        <AliceCarousel
                            mouseTracking
                            autoPlay={true}
                            items={items}
                            infinite
                            renderDotsItem={false}
                            autoPlayInterval={2000}
                            responsive={{ 1200: { items: 4 }, 1000: { items: 3 }, 800: { items: 2 }, 300: { items: 2 } }} />
                    </div>
                </div>
            );
        } else {
            return (<></>);
        }

    }
}
class ItemLastOffre extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        const { t } = this.props;
        if (this.props.actualite) { //
            return (
                <div className="col-md-12 px-1" style={{ borderLeft: "solid 1px #aaa" }}>
                    <Link to={"/" + i18next.language + this.props.actualite.url}>
                        <div className="col-md-12 px-0" style={{ height: '170px' }}>
                            <img onContextMenu={(e) => e.preventDefault()} src={this.props.actualite.img + ""} alt={this.props.actualite.title} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
                            {/* <h4 style={{position: 'absolute', top: 0, left: '0px', color: '#fff', background: '#ed2829', fontSize: '12px', padding: '1px 5px'}}>{this.props.actualite.domaine}</h4> */}
                        </div>
                        <div className="col-md-12 px-1" style={{ fontFamily: 'calibri', textAlign: 'justify' }}>
                            <h4 className='title-offres-sous-traitance-similaires'>{this.props.actualite.title}</h4>
                        </div>
                    </Link>
                </div>
            );
        } else {
            return (
                <div className="partenaires-item item">
                    <figure>
                        <div width="200" height="200" className="loder-image" />
                    </figure>
                    <h6><span className="loder-title"></span></h6>
                </div>
            );
        }
    }
}

class BlockActualites extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [<ItemActualite />, <ItemActualite />, <ItemActualite />, <ItemActualite />, <ItemActualite />, <ItemActualite />]
        }
        setTimeout(() => {
            Actualites.getActualites(1, "").then((res) => {
                if (res.data.get) {
                    var items = [];
                    res.data.data.forEach(actualite => {
                        items.push(<ItemActualite actualite={actualite} />);
                    });
                    this.setState({ items: items });
                }
            });
        }, 2000);

    }
    render() {
        const { t } = this.props;
        if (this.props.data) {

            return (
                <div className="list-single-main-item">
                    <div className="list-single-main-item-title fl-wrap">
                        <h3 className="size-titre-b2b grie-b2b">{t("ACTUALITÉS B2B")} :</h3>
                    </div>
                    <div className=" product-list no-padding" width="100%">
                        <AliceCarousel
                            mouseTracking
                            autoPlay={true}
                            items={this.state.items}
                            infinite
                            renderDotsItem={false}
                            autoPlayInterval={2000}
                            responsive={{ 1200: { items: 4 }, 1000: { items: 3 }, 800: { items: 2 }, 300: { items: 2 } }} />
                    </div>
                </div>
            );
        } else {
            return (
                <div className="list-single-main-item">
                    <div className="list-single-main-item-title fl-wrap">
                        <h3 className="size-titre-b2b grie-b2b">{t("ACTUALITÉS B2B")} :</h3>
                    </div>
                    <div className=" product-list no-padding" width="100%">
                        <AliceCarousel
                            mouseTracking
                            autoPlay={true}
                            items={this.state.items}
                            infinite
                            renderDotsItem={false}
                            autoPlayInterval={2000}
                            responsive={{ 1200: { items: 4 }, 1000: { items: 3 }, 800: { items: 2 }, 300: { items: 2 } }} />
                    </div>
                </div>
            );
        }

    }
}
class ItemActualite extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        const { t } = this.props;
        if (this.props.actualite) { //
            return (
                <div className="col-md-12 px-1">
                    <Link to={"/" + i18next.language + this.props.actualite.url}>
                        <div className="col-md-12 px-0" style={{ height: '170px' }}>
                            <img onContextMenu={(e) => e.preventDefault()} src={this.props.actualite.img + ""} alt={this.props.actualite.title} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
                            <h4 style={{ position: 'absolute', top: 0, left: '0px', color: '#fff', background: '#ed2829', fontSize: '12px', padding: '1px 5px' }}>{this.props.actualite.category}</h4>
                        </div>
                        <div className="col-md-12 px-1" style={{ fontFamily: 'calibri', textAlign: 'justify' }}>
                            <h4 style={{ color: '#000', textTransform: 'capitalize', fontSize: 12, lineHeight: '12px', marginTop: '12px' }}>{this.props.actualite.title}</h4>
                        </div>
                    </Link>
                </div>
            );
        } else {
            return (
                <div className="partenaires-item item">
                    <figure>
                        <div width="200" height="200" className="loder-image" />
                    </figure>
                    <h6><span className="loder-title"></span></h6>
                </div>
            );
        }
    }
}
function FormContact(props) {

    const [loderFrome, setLoderFrome] = useState("d-none");
    const [sund, setSund] = useState(false);
    const [postule, setPostule] = useState(props.userpostule);
    const [company, setcompany] = useState(props.data.company_id);
    const [offreid, setOffreid] = useState(props.offreid);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const { t } = props;
    useEffect(() => {
        //    if(props.user && props.data ){
        //     props.data.users_postuler.forEach(userid => {
        //         if(userid==props.user.code_user){
        //             setPostule(true);
        //             props.setuserpostule(true);
        //         }
        //     });
        //    }
    }, []);
    const onSubmit = (data) => {
        setLoderFrome("loder-from");
        SousTritance.postuler(offreid, data.message, data.objet, company).then((res) => {
            if (res.data.register) {
                reset();
                setSund(true);
                setLoderFrome("d-none");
                // props.setuserpostule(true);
                setPostule(true);
            }
        });
    }

    useEffect(() => {

    }, []);
    return (<>
        {postule ? <>
            {sund ?
                <div className="sund-form-sucsses">
                    <span>{t("Votre Message a été enregistrée avec succès")}</span>
                </div> :
                <p>{t("Vous avez déjà postulé pour cette offre")}</p>
            }
        </> : <>
            {sund ?
                <div className="sund-form-sucsses">
                    <span>{t("Votre Message a été enregistrée avec succès")}</span>
                </div> : <>
                    <div className={loderFrome}>
                        <span className='loading-login'></span>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className="f1 m-0 w-100" id="f1">
                        <div className="contentform p-0">
                            <div className="form-group">
                                <p className="grie-b2b" style={{ fontSize: '16px !important' }}>{t("Veuillez remplir le formulaire ci-dessous")} :</p>
                                <input type="text" placeholder={t("Objet")}  {...register("objet", { required: true })} className="form-control-objet" />
                                {errors.objet ? <span className="error d-block">{t("Ce champ est obligatoire")}</span> : <></>}
                                <textarea  {...register("message", { required: true })} rows={14} placeholder={t("Message")} className="mt-2" />
                                {errors.message ? <span className="error d-block">{t("Ce champ est obligatoire")}</span> : <></>}
                            </div>
                            <button type="submit" className="bouton-contact btn-b2b m-0" name="ok">{t("Postuler")}</button>
                        </div>
                    </form>
                </>}
        </>}
    </>);
}
