
import './style.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Experts from '../../services/experts';
import OffresEmploi from '../../services/offresEmploi';
import { Markup } from 'react-render-markup';
import Select from 'react-select'
import MetaTags from 'react-meta-tags';
import Pagination from "react-js-pagination";
import Slider from '../Slider/SliderFacebook';
import i18next from 'i18next';
import ReactGA from 'react-ga'
import {b2bNameSite,b2bDescriptionSite} from '../../config.json';
import { withTranslation } from 'react-i18next';
class Index extends Component
{
    constructor (props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        const { innerWidth: width, innerHeight: height } = window;
        super(props)
        this.state = {
            items : [<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>],
            categorieoptions : [{ value: '', label: '' }],
            search_mot_cle : "", 
            search_entreprises : 0, 
            search_categorie :[], 
            search_type_emploi : [], 
            search_experiences : [], 
            search_niveau_etudes : [], 
            search_regions : [], 
            search_disponibilie : [],
            activePage : 1 ,
            totalItemsCount : 0,
            itemsPerPage :10,
            type_emploi: [],
            experiences: [],
            niveau_etudes: [],
            regions: [],
            disponibilie : [],
            remunerationProposee : [] ,
            langue : [] ,
            progressPage : 0,
            showProgressPage:true,
            showfiltre:width>700?"0":"-100",
            l:localStorage.getItem("langb2b")
        }
        setInterval(() => {
            if(this.state.progressPage<80){
                this.setState({progressPage:this.state.progressPage++});
            }
        }, 100);
        Experts.getCategories().then((res)=>{
            if(res.data.get){
                var items = [{ value: '', label: this.props.t("Toutes les catégories") }];
                res.data.data.forEach(categorie => {
                    items.push({ value: categorie.id, label: categorie.name },);
                }); 
                this.setState({categorieoptions : []});
                this.setState({categorieoptions : items});
            }
        });
       
        OffresEmploi.getOffresDemploi().then((res)=>{
            this.updatedate(res);
            this.setState({progressPage : 100});
            setTimeout(() => {
                this.setState({showProgressPage:false}); 
            }, 1000);
        });
    }
    changeSearch = (e)=>{
        this.setState({
            search_mot_cle : e.target.value
        });
    }
    changeSearchCategories = (e)=>{
        this.setState({
            search_categorie : e.value
        });
        OffresEmploi.getOffresDemploi(
            this.state.activePage,
            this.state.search_mot_cle,
            e.value,
            this.state.search_type_emploi,
            this.state.search_experiences,
            this.state.search_niveau_etudes,
            this.state.search_regions,
            this.state.search_disponibilie
            ).then((res)=>{
            this.updatedate(res);
        });
    }
   
    submitSearch= ()=>{
        OffresEmploi.getOffresDemploi(
            this.state.activePage,
            this.state.search_mot_cle,
            this.state.search_categorie,
            this.state.search_type_emploi,
            this.state.search_experiences,
            this.state.search_niveau_etudes,
            this.state.search_regions,
            this.state.search_disponibilie
            ).then((res)=>{
            this.updatedate(res);
        });
    }
    handlePageChange(pageNumber) {
        
        this.setState({activePage: pageNumber});
        this.initItems();
        OffresEmploi.getOffresDemploi(
            pageNumber,
            this.state.search_mot_cle,
            this.state.search_categorie,
            this.state.search_type_emploi,
            this.state.search_experiences,
            this.state.search_niveau_etudes,
            this.state.search_regions,
            this.state.search_disponibilie).then((res)=>{
            this.updatedate(res);
        });
    }
    initItems(){
        window.scrollTo(0, 0);
        this.setState({items : [
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>,
            <ItemLoader/>
        ]});
    }
    updatedate(res) {
        if(res.data.get){
            var items = [];
            res.data.data.forEach(offres => {
                console.log("resultat : "+offres);
                items.push(<Item 
                    t = {this.props.t}
                    key={offres.id} 
                    id={offres.id} 
                    url={offres.url} 
                    img={offres.img} 
                    title={offres.title} 
                    gouvernorat={offres.gouvernorat}
                    content={offres.content}
                    secteur_activite={offres.secteur_activite}
                    date_debut={offres.date_debut}
                    date_fin={offres.date_fin}
                    date_expire={offres.date_expire}
                    />);
            }); 
            this.setState({items : []});
            this.setState({items : items});
            this.setState({
                items : items,
                type_emploi:res.data.type_emploi,
                experiences:res.data.experiences,
                niveau_etudes:res.data.niveau_etudes,
                regions:res.data.regions,
                langue:res.data.langue,  
                remunerationProposee:res.data.remunerationProposee,
                disponibilie:res.data.disponibilie,
                totalItemsCount : res.data.nbposts
            });
        }
    }
   
    changeTypeEmploi(event){
        var value = Array.from(this.state.search_type_emploi);
        if (event.target.checked == true){
            value.push(event.target.value);
            this.setState({search_type_emploi: value }); 
        }else{
            var index = value.indexOf(event.target.value)
            if (index !== -1) {
                value.splice(index, 1);
            }
            this.setState({search_type_emploi: value});
        }
        
        
        OffresEmploi.getOffresDemploi(
            this.state.activePage,
            this.state.search_mot_cle,
            this.state.search_categorie,
            value,
            this.state.search_experiences,
            this.state.search_niveau_etudes,
            this.state.search_regions,
            this.state.search_disponibilie).then((res)=>{
            this.updatedate(res);
        });
    }
    changeDisponibilie(event){
        var value = Array.from(this.state.search_disponibilie);
        if (event.target.checked == true){
            value.push(event.target.value);
            this.setState({search_disponibilie: value }); 
        }else{
            var index = value.indexOf(event.target.value)
            if (index !== -1) {
                value.splice(index, 1);
            }
            this.setState({search_disponibilie: value});
        }
        
        
        OffresEmploi.getOffresDemploi(
            this.state.activePage,
            this.state.search_mot_cle,
            this.state.search_categorie,
            this.state.search_type_emploi,
            this.state.search_experiences,
            this.state.search_niveau_etudes,
            this.state.search_regions,
            this.state.search_disponibilie,
            value).then((res)=>{
            this.updatedate(res);
        });
    }
    changeExperiences(event){
        var value = Array.from(this.state.search_experiences);
        if (event.target.checked == true){
            value.push(event.target.value);
            this.setState({search_experiences: value }); 
        }else{
            var index = value.indexOf(event.target.value)
            if (index !== -1) {
                value.splice(index, 1);
            }
            this.setState({search_experiences: value});
        }
    
        OffresEmploi.getOffresDemploi(
            this.state.activePage,
            this.state.search_mot_cle,
            this.state.search_categorie,
            this.state.search_type_emploi,
            value,
            this.state.search_niveau_etudes,
            this.state.search_regions,
            this.state.search_disponibilie).then((res)=>{
            this.updatedate(res);
        });
    }
    changeNiveauEtudes(event){
        var value = Array.from(this.state.search_niveau_etudes);
        if (event.target.checked == true){
            value.push(event.target.value);
            this.setState({search_niveau_etudes: value }); 
        }else{
            var index = value.indexOf(event.target.value)
            if (index !== -1) {
                value.splice(index, 1);
            }
            this.setState({search_niveau_etudes: value});
        }
        OffresEmploi.getOffresDemploi(
            this.state.activePage,
            this.state.search_mot_cle,
            this.state.search_categorie,
            this.state.search_type_emploi,
            this.state.search_experiences,
            value,
            this.state.search_regions,
            this.state.search_disponibilie).then((res)=>{
            this.updatedate(res);
        });
    }
    changeRegions(event){
        var value = Array.from(this.state.search_regions);
        if (event.target.checked == true){
            value.push(event.target.value);
            this.setState({search_regions: value }); 
        }else{
            var index = value.indexOf(event.target.value)
            if (index !== -1) {
                value.splice(index, 1);
            }
            this.setState({search_regions: value});
        }
        OffresEmploi.getOffresDemploi( 
            this.state.activePage,
            this.state.search_mot_cle,
            this.state.search_categorie,
            this.state.search_type_emploi,
            this.state.search_experiences,
            this.state.search_niveau_etudes,
            value,
            this.state.search_disponibilie).then((res)=>{
               
            this.updatedate(res);
        });
    }
    showfiltre= ()=>{
        if(this.state.showfiltre=="-100"){
            this.setState({
                showfiltre:"0"
            })
        }else{
            this.setState({
                showfiltre:"-100"
            })
        }
       
    }
     //****************************** Load update of change language ***************
componentDidUpdate(){
    if(this.state.l !== localStorage.getItem("langb2b")){
        OffresEmploi.getOffresDemploi().then((res)=>{
            this.updatedate(res);
            this.setState({progressPage : 100});
            setTimeout(() => {
                this.setState({showProgressPage:false}); 
            }, 1000);
        });
    this.setState({
        l:localStorage.getItem("langb2b")
    })
    }
   }
    render() {
        const { t } = this.props;

        var type_emploi=[];
        if(this.state.type_emploi){
            this.state.type_emploi.forEach(element => {
                type_emploi.push(
                <div>
                    <input type="checkbox" className="click-submit" id={"checkbox"+element.slug+"_"+element.id} value={element.id} onChange={this.changeTypeEmploi.bind(this)}/>
                    <label for={"checkbox"+element.slug+"_"+element.id} className="l" >{element.nom}</label> 
                    <br />
                </div>)
            });
        }
        var disponibilie=[];
        if(this.state.disponibilie){
            this.state.disponibilie.forEach(element => {
                disponibilie.push(
                <div>
                    <input type="checkbox" className="click-submit" id={"checkbox_disponibilie_"+element.slug+"_"+element.id} value={element.id} onChange={this.changeDisponibilie.bind(this)}/>
                    <label for={"checkbox_disponibilie_"+element.slug+"_"+element.id} className="l" >{element.nom}</label> 
                    <br />
                </div>)
            });
        }

        var experiences=[];
        if(this.state.experiences){
            this.state.experiences.forEach(element => {
                experiences.push(
                <div>
                    <input type="checkbox" className="click-submit" id={"checkbox"+element.slug+"_"+element.id} value={element.id} onChange={this.changeExperiences.bind(this)}/>
                    <label for={"checkbox"+element.slug+"_"+element.id} className="l" >{element.nom}</label> 
                    <br />
                </div>)
            });
        }

        var niveau_etudes=[];
        if(this.state.niveau_etudes){
            this.state.niveau_etudes.forEach(element => {
                niveau_etudes.push(
                <div>
                    <input type="checkbox" className="click-submit" id={"checkbox"+element.slug+"_"+element.id} value={element.id} onChange={this.changeNiveauEtudes.bind(this)}/>
                    <label for={"checkbox"+element.slug+"_"+element.id} className="l" >{element.nom}</label> 
                    <br />
                </div>)
            });
        }

        var regions=[];
        if(this.state.regions){
            this.state.regions.forEach(element => {
                regions.push(
                <div>
                    <input type="checkbox" className="click-submit" id={"checkbox"+element.slug+"_"+element.id} value={element.slug} onChange={this.changeRegions.bind(this)}/>
                    <label for={"checkbox"+element.slug+"_"+element.id} className="l" >{element.nom}</label> 
                    <br />
                </div>)
            });
        }
        
        var remunerationProposee=[];
        if(this.state.remunerationProposee){
            this.state.remunerationProposee.forEach(element => {
                remunerationProposee.push(
                <div>
                    <input type="checkbox" className="click-submit" id={"checkbox"+element.slug+"_"+element.id} value={element.id} onChange={this.changeTypeEmploi.bind(this)}/>
                    <label for={"checkbox"+element.slug+"_"+element.id} className="l" >{element.nom}</label> 
                    <br />
                </div>)
            });
        }
        
        var langue=[];
        if(this.state.langue){
            this.state.langue.forEach(element => {
                langue.push(
                <div>
                    <input type="checkbox" className="click-submit" id={"checkbox"+element.slug+"_"+element.id} value={element.id} onChange={this.changeTypeEmploi.bind(this)}/>
                    <label for={"checkbox"+element.slug+"_"+element.id} className="l" >{element.nom}</label> 
                    <br />
                </div>)
            });
        }
        return ( <>
            {this.state.showProgressPage?<div class="progress-div" style={{width: "100%"}}><div style={{width: this.state.progressPage+"%"}} class="progress"></div></div>:<></>}
            <MetaTags>
            <title>{localStorage.getItem("langb2b")==="en" ? "Job offers in Tunisia and abroad" :"Offres d’emploi en Tunisie et à l’international" } | {b2bNameSite} | {localStorage.getItem("langb2b")==="en" ? "B2B marketplace and B2B networking platform in Tunisia and abroad" : b2bDescriptionSite}</title>
            </MetaTags>
            <div className="page-content">
                <section id="list-places-wrapper" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 widget de_widget_categories prod-list" id="search-places">
                                <form role="search" className="place_search_form" action="" method="GET">
                                    <div className="row block-search-all ml-0">
                                        <div className="row col-md-11 col-10 de-search-form border-block" id="search-places" style={{"margin-left": "0px"}}>
                                            <div className="col-md-8 block-search-keyword">
                                                <div className="de-scontrol">
                                                    <input type="text" name="mot_cle" onChange={this.changeSearch} className="keyword-search" placeholder="Entrez un mot-clé ..." value={this.state.search_mot_cle} />
                                                </div>
                                            </div>
                                            <div className="col-md-4  block-search-categorie">
                                                <div className="de-scontrol">
                                                    <Select options={this.state.categorieoptions} placeholder={t("Toutes les catégories")} onChange={this.changeSearchCategories}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 col-2 block-search-btn" style={{"padding-right":"0"}}>
                                            <button className="btn btn-primary search-btn" type="button" onClick={this.submitSearch} style={{"background-color":"#ED2829","border":"none","height":"65px","width": "100%",marginBottom: "1px"}}>
                                                <i className="fa fa-search fa-lg"></i>
                                            </button>
                                            <button className="btn btn-primary show-mobile" type="button" onClick={this.showfiltre} style={{"background-color":"#ED2829","border":"none","height":"65px","width": "100%",borderRadius: "0px 0px 25px 25px"}}>
                                                <i className="fa fa-filter fa-lg"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className={"col-md-2 border-block block-fillter-expert block-fillter-mobile p"+this.state.showfiltre} id="block-fillter-mobile" style={{left:this.state.showfiltre+"%"}}>
                                <div className='masque-close-fillter' onClick={this.showfiltre}><span>x</span></div>
                                <div className="row p-2">
                                    {type_emploi?
                                    <div className="col-md-12">
                                        <h4 className="h4 ">{t("Type de contrat")} : </h4>
                                        {type_emploi}  
                                    </div>
                                    :<></>}
                                    {experiences?
                                    <div className="col-md-12">
                                        <h4 className="h4 ">{t("Niveau d'expérience")} : </h4>
                                        {experiences}  
                                    </div>
                                    :<></>}
                                    {disponibilie?
                                    <div className="col-md-12">
                                        <h4 className="h4 ">{t("Disponibilié")} : </h4>
                                        {disponibilie}  
                                    </div>
                                    :<></>}
                                    {niveau_etudes?
                                    <div className="col-md-12">
                                        <h4 className="h4 ">{t("Niveau d'étude")} : </h4>
                                        {niveau_etudes}  
                                    </div>
                                    :<></>}
                                    {regions?
                                    <div className="col-md-12">
                                        <h4 className="h4 ">{t("Gouvernorat")} : </h4>
                                        {regions}  
                                    </div>
                                    :<></>}
                                    {remunerationProposee?
                                    <div className="col-md-12">
                                        <h4 className="h4 ">{t("Rémunération")} <br/>{t("proposée")} : </h4>
                                        {remunerationProposee}  
                                    </div>
                                    :<></>}
                                    {langue?
                                    <div className="col-md-12">
                                        <h4 className="h4 ">{t("Langue")} : </h4>
                                        {langue}  
                                    </div>
                                    :<></>}
                                    
                                </div>
                            </div>
                            <div className="col-md-6">
                                {/* <div className="col-md-12 widget de_widget_categories prod-list" style={{"margin-bottom": "10px","text-transform":  "uppercase","text-align": "center"}}><h3></h3></div> */}
                                <div className="col-md-12">
                                    {this.state.items}
                                    <div className="paginations-wrapper">
                                        <div className="paginations">
                                            {this.state.totalItemsCount>this.state.itemsPerPage?
                                            <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.itemsPerPage}
                                            totalItemsCount={this.state.totalItemsCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                            />
                                            :<></>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 ">
                                <div className="border-block" style={{padding:"15px"}}><Slider /></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>);
    }
}

export default withTranslation()(Index);



class Item extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            id : props.id,
            url:props.url,
            img:props.img,
            title:props.title,
            gouvernorat:props.gouvernorat,
            content:props.content,
            secteur_activite:props.secteur_activite,
            date_debut:props.date_debut,
            date_fin:props.date_fin,
        }
        
    }
  
    render() {
        const { t } = this.props;
       
        return ( 
            <div className="row">
                <div className="col-md-12 " style={{backgroundColor: '#fff', marginBottom: '10px', paddingTop: '10px', border: '1px solid #b8c7c8'}}>
                    <div className="row">
                        <div className="col-md-3" style={{paddingLeft: 0, textAlign: 'center'}}>
                            <div className="cont-img-offre"> 
                            <Link to={"/"+i18next.language+this.state.url}>  <img onContextMenu={(e)=> e.preventDefault()} src={this.state.img} style={{width: '100%', height: 'auto'}} /></Link>
                            <div className="block-marker">
                                <i className="fa fa-map-marker" style={{fontSize: '15px', paddingRight: '5px'}} />{this.state.gouvernorat}</div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <Link to={"/"+i18next.language+this.state.url} style={{color: '#ED2829', borderBottom: '1px solid #ED2829', fontFamily: 'calibri', fontWeight: 700, textTransform: 'uppercase'}}><Markup markup={this.state.title} /></Link>
                            <br />
                            <div style={{textAlign: 'justify'}}><Markup markup={this.state.content} /> <br /></div>
                            <div className="col-md-9" style={{}}>
                            <br /> 
                            <b>{t("Secteur d'activité")} :</b>{this.state.secteur_activite}<br />
                            {this.props.date_expire?<><b style={{color:"red"}}>{t("Expiré le")} :</b><span style={{color:"red"}}>{this.state.date_fin}</span></>:<><b>{t("Expire le")} :</b><span>{this.state.date_fin}</span></>}
                            </div>
                            <div className="col-md-12" style={{textAlign: 'justify'}}>
                            <Link to={"/"+i18next.language+this.state.url} style={{border: '1px solid #ED2829', padding: '0 10px', color: '#ED2829', fontFamily: 'calibri', marginBottom: '5px', float: 'right'}}> {t("Voir")} +</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
class ItemLoader extends Component
{
    render() {

        return ( 
            <div className="row">
                <div className="col-md-12 " style={{backgroundColor: '#fff', marginBottom: '10px', paddingTop: '10px', border: '1px solid #b8c7c8'}}>
                    <div className="row">
                    <div className="col-md-3" style={{paddingLeft: 0, textAlign: 'center'}}>
                        <div className="cont-img-offre"> 
                        <a href="#">  
                            <div className="loder-image-offre" ></div>
                        </a>
                        <div className="block-marker block-marker-loder">
                            <i className="fa fa-map-marker" style={{fontSize: '15px', paddingRight: '5px'}} /><span className="loder-span ml-0"></span></div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <a href="#" style={{color: '#ED2829', borderBottom: '1px solid #ED2829', fontFamily: 'calibri', fontWeight: 700, textTransform: 'uppercase'}}><span className="loder-span  ml-0"></span></a>
                        <br />
                        <div style={{textAlign: 'justify'}}>
                            <span className="loder-p"></span>
                            <span className="loder-p"></span>
                        </div>
                        <div className="col-md-9" style={{}}>
                        <br /> 
                        <b>Secteur d'activité :</b>
                        <span className="loder-p"></span>
                        <span className="loder-p"></span>
                        </div>
                        <div className="col-md-12" style={{textAlign: 'justify'}}>
                        <a href="" style={{border: '1px solid #ED2829', padding: '0 10px', color: '#ED2829', fontFamily: 'calibri', marginBottom: '5px', float: 'right'}}> Voir +</a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}
