
import '../App.css';
import React, { Component } from 'react';
import Slider from './slider';
import EntreprisesEnVedette from './entreprisesEnVedette';
import ProduitsEnVedette from './produitsEnVedette';
// import Stat from './stat';
import { b2bNameSite, b2bDescriptionSite } from "../../config.json";
import MetaTags from 'react-meta-tags';
import Actualites from './actualites';
import Recommande from './recommande';
import EntrepriseParSecteurs from './entrepriseParSecteurs';
import EntrepriseParRegion from './entrepriseParRegion';
import i18next from 'i18next';
import ReactGA from 'react-ga'
import { useParams } from "react-router-dom";
const Index = () => {
    var { langb2b } = useParams();
    if (langb2b == "en") {
        i18next.changeLanguage("en");
    } else {
        i18next.changeLanguage("fr");
    }
    return <App />;
}
export default Index;
class App extends Component {
    constructor(props) {
        ReactGA.pageview(window.location.pathname);
        window.scrollTo(0, 0);
        super(props)
        this.state = {
        }
    }


    render() {
        return (<>
            <MetaTags>
                <title>{localStorage.getItem("langb2b") === "en" ? "Home" : "Accueil"} | {b2bNameSite} | {localStorage.getItem("langb2b") === "en" ? "B2B marketplace and B2B networking platform in Tunisia and abroad" : b2bDescriptionSite}</title>
            </MetaTags>
            <div class="page-content">
                <Slider />
                <EntreprisesEnVedette />

                <ProduitsEnVedette />
                <Actualites />
                <Recommande />
                <EntrepriseParSecteurs />
                <EntrepriseParRegion />
            </div>
        </>);
    }
}




