
import React, { Component } from 'react';
import './style.css';
import AliceCarousel from 'react-alice-carousel';
import Login from './login';
import Auth from '../../services/Auth';
import SerchHome from './serchHome';
import jwt_decode from "jwt-decode";
import {b2bNameSite} from '../../config.json';
import { withTranslation } from 'react-i18next';
import 'react-alice-carousel/lib/alice-carousel.css';

class Slider extends Component
{
    constructor (props) {
        super(props);
        var userLogin= false;
        var dataUser=null;
        if(localStorage.getItem('tokenb2b') && localStorage.getItem('tokenb2b')!="undefined" && localStorage.getItem('tokenb2b')!=""){
            userLogin=true;
            dataUser=jwt_decode(localStorage.getItem('tokenb2b'));
        }
        this.state={
            isLogin:userLogin
        }
        setTimeout(() => {
            Auth.isLogin().then((res)=>{
                if(res.data!=false){
                    this.setState({isLogin:true});
                }else{
                    this.setState({isLogin:false});
                }
            });
        }, 10);
    }
    
    render() {
        const { t } = this.props;
        const items = [
            <Item nameImage="slide3.webp" titre1={t("DÉVELOPPEZ")} titre2={t("VOTRE  NOTORIÉTÉ")} text={t("textb2bsliderhome1")} />,
            <Item nameImage="slide1.webp" titre1={t("AMÉLIOREZ")} titre2={t("VOTRE VISIBILITÉ")} text={t("textb2bsliderhome2")} />,
            <Item nameImage="slide2.webp" titre1={t("RÉUSSISSEZ")} titre2={t("VOTRE PROJET")} text={t("textb2bsliderhome3")} />,
        ];
        return (
            <div class="main-slider style-two default-banner w-slide-b2b" style={{height:"400px",position: "relative"}}>
                <div class="tp-banner-container">
                    <div class="tp-banner" >
                        <AliceCarousel 
                            mouseTracking 
                            autoPlay={true}
                            items={items}
                            infinite 
                            animationDuration={1500}
                            controlsStrategy="alternate"
                            disableSlideInfo={true}
                            renderDotsItem={false}
                            autoPlayInterval = {5000}
                            />
                    </div> 
                </div>
                {this.state.isLogin?<SerchHome/>:<Login/>}
            </div> 
        );
    }
 
}
export default withTranslation()(Slider)
    
class Item extends Component
{
    constructor (props) {
        super(props);

    }
    
    render() {

        return ( 
            <div className="item-slide-home">
                <figure>
                    <img onContextMenu={(e)=> e.preventDefault()} src={"/assets/img/slide/"+this.props.nameImage} className="" alt="b2b tunise" />
                </figure>
                <div className="text-slide-home">
                    <h2><span>{this.props.titre1}</span> {this.props.titre2}:</h2>
                    <p>{this.props.text}</p>
                </div>
            </div>
            
        );
    }
}
   