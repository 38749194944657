

import './style.css';
import React, { Component } from 'react';
import Slider from '../Slider/SliderEnVedette';
import Home from '../../services/home';
import { Markup } from 'react-render-markup';
import i18next from 'i18next';
import { Link } from 'react-router-dom';

export default class Index extends Component
{
    constructor (props) {
        super(props)
        window.scrollTo(0, 0);
        const urlParams = new URLSearchParams(window.location.search);
        const search = urlParams.get('s');
        this.state = {
            search : search,
            resaltCategory : {display: 'none'},
            resaltEntreprise : {display: 'none'},
            resaltProduit : {display: 'none'},
            activeCategory : "",
            activeEntreprise : "",
            activeProduit : "active",
            itemsResaltSearchProduit : [],
            itemsResaltSearchEntreprise : [],
            itemsResaltSearchCategory : [],
            nbItemsResalt: 0,
        }
        Home.getSearch(search,"all").then((res)=>{
            if(res.data.get){
                console.log(res.data.resalt);
                var itemsResaltSearchProduit =[];
                var itemsResaltSearchEntreprise =[];
                var itemsResaltSearchCategory =[];
                this.setState({
                    itemsResaltSearchProduit:itemsResaltSearchProduit,
                    itemsResaltSearchEntreprise:itemsResaltSearchEntreprise,
                    itemsResaltSearchCategory:itemsResaltSearchCategory,
                });
                res.data.resalt.forEach(element => {
                    if(element.type=="place"){
                        itemsResaltSearchEntreprise.push({
                            image : element.image,
                            url : element.url,
                            title : element.title,
                            address : element.address,
                            content : element.content,
                            sponsoriser : element.sponsoriser,
                        });
                    }else if(element.type=="produit"){
                        itemsResaltSearchProduit.push({
                            image : element.image,
                            url : element.url,
                            title : element.title,
                            entreprise : element.entreprise,
                            content : element.content,
                            sponsoriser : element.sponsoriser,
                        });
                    }else if (element.type=="place_category"){
                        itemsResaltSearchCategory.push({
                            image : element.image,
                            url : element.url,
                            title : element.title
                        });
                    }
                });
                if(res.data.resalt.length>0){
                    this.setState({
                        itemsResaltSearchProduit:itemsResaltSearchProduit,
                        itemsResaltSearchEntreprise:itemsResaltSearchEntreprise,
                        itemsResaltSearchCategory:itemsResaltSearchCategory,
                        resaltCategory : {display: 'none'},
                        resaltEntreprise : {display: 'none'},
                        resaltProduit : {display: 'block'},
                        activeCategory : "",
                        activeEntreprise : "",
                        activeProduit : "active",
                        nbItemsResalt : res.data.resalt.length
                    });
                }else{
                    this.setState({
                        itemsResaltSearchProduit:[],
                        itemsResaltSearchEntreprise:[],
                        itemsResaltSearchCategory:[],
                        resaltCategory : {display: 'none'},
                        resaltEntreprise : {display: 'none'},
                        resaltProduit : {display: 'none'},
                        activeCategory : "",
                        activeEntreprise : "",
                        activeProduit : "",
                        nbItemsResalt : 0
                    });
                }
            }
        });
        
    }
    clickProduits = (e)=>{
        this.setState({
            resaltCategory : {display: 'none'},
            resaltEntreprise : {display: 'none'},
            resaltProduit : {display: 'block'},
            activeCategory : "",
            activeEntreprise : "",
            activeProduit : "active",
        });
    }
    clickEntreprises = (e)=>{
        this.setState({
            resaltCategory : {display: 'none'},
            resaltEntreprise : {display: 'block'},
            resaltProduit : {display: 'none'},
            activeCategory : "",
            activeEntreprise : "active",
            activeProduit : "",
        });
    }
    clickCategories = (e)=>{
        this.setState({
            resaltCategory : {display: 'block'},
            resaltEntreprise : {display: 'none'},
            resaltProduit : {display: 'none'},
            activeCategory : "active",
            activeEntreprise : "",
            activeProduit : "",
        });
    }
    
    render() {
        return ( 
            <div className="page-content">
                <section id="list-places-wrapper" style={{background: '#fff', marginTop: 0}}>
                    <div className="container" style={{backgroundColor: '#fff'}}>
                        <div className="row">
                            <div className="col-md-9 px-1">
                                <div className="tab">
                                    <button className={"tablinks "+this.state.activeProduit} onClick={this.clickProduits}>Produits ({this.state.itemsResaltSearchProduit.length})</button>
                                    <button className={"tablinks "+this.state.activeEntreprise} onClick={this.clickEntreprises}>Entreprises ({this.state.itemsResaltSearchEntreprise.length})</button>
                                    <button className={"tablinks "+this.state.activeCategory} onClick={this.clickCategories}>Catégories ({this.state.itemsResaltSearchCategory.length}) </button>
                                </div>
                                <div id="produits" className="tabcontent borde-block" style={this.state.resaltProduit}>
                                    <h3>Produits</h3>
                                    <ItemsProduit data={this.state.itemsResaltSearchProduit} />
                                </div>
                                <div id="entreprises" className="tabcontent borde-block" style={this.state.resaltEntreprise}>
                                    <h3>Entreprises </h3>
                                    <ItemsEntreprise data={this.state.itemsResaltSearchEntreprise} />
                                </div>
                                <div id="categories" className="tabcontent borde-block" style={this.state.resaltCategory}>
                                    <h3>Catégories </h3>
                                    <ItemsCategory data={this.state.itemsResaltSearchCategory} />
                                </div>
                            </div>
                            <div className="col-md-3 px-1 borde-block">
                                <Slider event={false} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}


class ItemsCategory extends Component
{
    constructor (props) {
        super(props)
    }
    render() {
        var items = [];
        this.props.data.forEach(element => {
            items.push(
                <li>
                    <div className="place-wrapper">
                    <div className="place-detail-wrapper" style={{height: '50px', padding: '5px'}}>
                        <h2 className="title-place">
                        <Link to={"/"+i18next.language+"/entreprises?categorie="+element.url}><Markup markup={element.title}/></Link>
                        </h2>
                        <div className="content-place">
                        </div>
                    </div>
                    </div>
                </li>
            );
        });
        return ( 
            <ul className="list-places list-posts fullwidth" id="publish-places" data-list="publish" data-thumb="medium_post_thumbnail">
                 {items}
            </ul>
        );
    }
}

class ItemsEntreprise extends Component
{
    constructor (props) {
        super(props)
    }
    render() {
        var items = [];
        this.props.data.forEach(element => {
            items.push(
                <li>
                    <div className="place-wrapper row position-relative" style={{display: 'flex'}}>
                        {element.sponsoriser?<div className='contint-pack sponsoriser' style={{borderLeft:"solid 1px red" , borderTop: "solid 1px red"}}>Annonce</div>:<></>}
                        <div className="col-md-12 row">  
                            <div className="hidden-img col-md-4">
                                <Link to={"/"+i18next.language+element.url} className="img-place">
                                    <img onContextMenu={(e)=> e.preventDefault()} className="lazy" src={element.image} style={{display: 'inline', width: '100%', borderRight: '1px solid #eee'}} />
                                </Link>
                            </div>
                            <div className="place-detail-wrapper col-md-8">
                                <h2 className="title-place">
                                    <Link to={"/"+i18next.language+element.url} title={element.title}><Markup markup={element.title}/></Link>
                                </h2>
                                <span className="address-place"><i className="fa fa-map-marker" /><Markup markup={element.address} /></span>
                                <div className="content-place"><Markup markup={element.content} /></div>
                            </div>
                        </div>
                    </div>
                </li>
            );
        });
        return ( 
            <ul className="list-places list-posts fullwidth" id="publish-places" data-list="publish" data-thumb="medium_post_thumbnail">
               {items}
            </ul>                        
        );
    }
}

class ItemsProduit extends Component
{
    constructor (props) {
        super(props)
    }
    render() {
        var items = [];
        this.props.data.forEach(element => {
            items.push(
                <li>
                    <div className="place-wrapper position-relative row">
                        {element.sponsoriser?<div className='contint-pack sponsoriser' style={{borderLeft:"solid 1px red" , borderTop: "solid 1px red"}}>Annonce</div>:<></>}
                        <div className="hidden-img col-md-4">
                            <Link to={"/"+i18next.language+element.url} className="img-place">
                                <img onContextMenu={(e)=> e.preventDefault()} className="lazy" src={element.image} style={{display: 'inline', width: '100%', borderRight: '1px solid #eee'}} />
                            </Link>
                        </div>
                        <div className="place-detail-wrapper col-md-8">
                            <h2 className="title-place">
                                <Link to={"/"+i18next.language+element.url} title={element.title}><Markup markup={element.title} /></Link>
                            </h2>
                            <span className="address-place"><i className="fa fa-building" /><Markup markup={element.entreprise} /></span>
                            <div className="content-place"><Markup markup={element.content} /></div>
                        </div>
                    </div>
                </li>
            )
        });
        return ( 
            <ul className="list-places list-posts fullwidth" id="publish-places" data-list="publish" data-thumb="medium_post_thumbnail">
                 {items}
            </ul>          
        );
    }
}
