import React, { Component, createRef } from "react";
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import i18next from 'i18next';
import Searchpro from './header/serchpro';
import {b2bNameSite,b2bDescriptionSite} from '../config.json'
import './App.css'
import ReactGA from 'react-ga'
export default class page404 extends Component {
  constructor (props) {
    ReactGA.pageview(window.location.pathname);
    window.scrollTo(0, 0);
    super(props)
    this.state = {
       
    }  
  }

  render() {
    return (
      <>
       <MetaTags>
            <title>404 | {b2bNameSite} | {b2bDescriptionSite}</title>
        </MetaTags>
        <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="img-404" style={{padding: '50px 0'}}>
              <img onContextMenu={(e)=> e.preventDefault()} src="/assets/img/404-icon.png" style={{width: '50px', marginBottom: '40px'}} />
              <p>Vous recherchez une page ? <br />
                L'adresse internet que vous avez entrée dans votre navigateur ne correspond à aucune page active de notre site.</p><br />
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-5">
                  <div className="search-input-404">
                    <Searchpro placeholder="Entrez votre mot-clé Entreprise" type="entreprise"/>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="search-input-404">
                    <Searchpro placeholder="Entrez votre mot-clé produit" type="produit"/>
                  </div>
                </div>
                <div className="col-md-1"></div>
              </div>
              <br /><br /><br /><br />
              <div className="col-md-12">
                <Link to="/" style={{textTransform: 'uppercase', color: '#ED2829'}}>RETOUR À LA PAGE D'ACCUEIL</Link> &nbsp;&nbsp;&nbsp;
                <Link to="/contactez-nous/" style={{textTransform: 'uppercase', color: '#ED2829'}}>besoin d'aide contacter nous</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}