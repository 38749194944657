const initState = {
    showModaleLogin:false,
    htmlModaleLogin:<></>
}

const reducer = (state = initState ,action)=>{
    if(action.type == "SHOWLOGIN"){
        return {showModaleLogin : true }
    }else if(action.type == "HIDELOGIN"){
        return {showModaleLogin : false }
    }else if(action.type == "HTMLLOGIN"){
        return {htmlModaleLogin : action.htmlLogin }
    }
    return state
}

export default reducer;