import React, { Component } from 'react';
import DataHome from '../../services/home';
import i18next from 'i18next';
import './style.css';
import { Link, NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
class Recommande extends Component
{
    
    constructor (props) {
        super(props)
        this.state = {
            items1 : [<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>],
            items2 : [<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>],
            items3 : [<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>,<ItemLoader/>]
        }
        DataHome.getRecommande().then((res)=>{
            if(res.data.get){
                var items1=[];
                var items2=[];
                var items3=[];
                var i=0;
                res.data.categorie1.forEach(region => {
                    if (i18next.language == "fr" || i18next.language == "fr-FR") {
                        items1.push(<Item key={i++} name={region.title} url={region.href} image={region.image}/>);
                    } else if (i18next.language == "en") {
                        items1.push(<Item key={i++} name={region.en_title} url={region.href} image={region.image}/>);
                    } else {
                        items1.push(<Item key={i++} name={region.title} url={region.href} image={region.image}/>);
                    }
                    
                });
                res.data.categorie2.forEach(region => {
                    if (i18next.language == "fr" || i18next.language == "fr-FR") {
                        items2.push(<Item key={i++} name={region.title} url={region.href} image={region.image}/>);
                    } else if (i18next.language == "en") {
                        items2.push(<Item key={i++} name={region.en_title} url={region.href} image={region.image}/>);
                    } else {
                        items2.push(<Item key={i++} name={region.title} url={region.href} image={region.image}/>);
                    }
                    
                });
                res.data.categorie3.forEach(region => {
                    if (i18next.language == "fr" || i18next.language == "fr-FR") {
                        items3.push(<Item key={i++} name={region.title} url={region.href} image={region.image}/>);
                    } else if (i18next.language == "en") {
                        items3.push(<Item key={i++} name={region.en_title} url={region.href} image={region.image}/>);
                    } else {
                        items3.push(<Item key={i++} name={region.title} url={region.href} image={region.image}/>);
                    }
                    
                });
                setTimeout(() => {
                    this.setState({items1:[],items2:[],items3:[]});
                    this.setState({items1:items1,items2:items2,items3:items3});
                }, 1000);
                
            }
        });
    }
    
    render() {
        const { t } = this.props;
        return ( 
        <>
           <div className="section-full bg-white content-inner-1" style={{"backgroundRepeat":"repeat-x","backgroundPosition":"left bottom -37px","paddingTop":"0"}}>
                <div className="section-full dlab-we-find bg-white p-t50 p-b10 ">
                    <div className="container">
                        <div className="section-head text text-center" style={{"marginBottom":"0","marginTop":"0px"}}>
                            <h2 className="text-uppercase"> {t("RECOMMANDÉ POUR VOUS")}</h2>
                            <div className="dlab-separator-outer ">
                                <div className="dlab-separator bg-secondry style-skew"></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-wide b2b-outer" style={{"backgroundImage":"url(/assets/img/recommande/services-informatique.jpg)","height":"191px","marginTop":"29px","backgroundSize":"cover"}}>
                                <h4 style={{"color":"#fff","bottom":"15px","position":"absolute","zIndex":"99","marginLeft":"25px"}}> {t("SERVICES INFORMATIQUES")} </h4>
                            </div>
                            <div className="col-md-9 col-wide row" style={{"padding":"20px","backgroundColor":"#ffffff"}}>
                                {this.state.items1}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full bg-white content-inner-1" style={{"backgroundRepeat":"repeat-x","backgroundPosition":"left bottom -37px","paddingTop":"0","paddingBottom":"30px"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-wide b2b-outer" style={{"backgroundImage":"url(/assets/img/recommande/batiment.jpg)","height":"191px","marginTop":"29px","backgroundSize":"cover"}}>
                            <h4 style={{"color":"#fff","bottom":"15px","position":"absolute","zIndex":"99","marginLeft":"25px"}}> {t("INDUSTRIES MÉCANIQUES")}, {t("MÉTALLIQUES")},<br/>  {t("MÉTALLURGIQUES ET ÉLECTRIQUES")}</h4>
                        </div>
                        <div className="col-md-9 col-wide row" style={{"padding":"20px","backgroundColor":"#ffffff"}}>
                            {this.state.items2}
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-full bg-white content-inner-1" style={{"backgroundRepeat":"repeat-x","backgroundPosition":"left bottom -37px","paddingTop":"0","paddingBottom":"10px"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-wide b2b-outer" style={{"backgroundImage":"url(/assets/img/recommande/agence-de-publicite.jpg)","backgroundSize":"cover","height":"191px","marginTop":"29px"}}>
                                <h4 style={{"color":"#fff","bottom":"15px","position":"absolute","zIndex":"99","marginLeft":"25px"}}> {t("BTP")} –  {t("ARCHITECTURE")} –  {t("CONSTRUCTION")}</h4>
                            </div>
                            <div className="col-md-9 col-wide row" style={{"padding":"20px","backgroundColor":"#ffffff"}}>
                                {this.state.items3}
                            </div>
                        </div>
                    </div>
                </div> 
        </>
        );
    }
}
export default withTranslation()(Recommande);


class Item extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            name:props.name,
            url:props.url,
            image:props.image,
        }
    }
  
    render() {

        return ( 
            <div className="col-md-4 col-wide b2b-inner row" >
                <div className="col-md-4" style={{padding: "10px"}}>
                    <Link to={"/"+i18next.language+this.state.url}>
                        <img onContextMenu={(e)=> e.preventDefault()} src={this.state.image} style={{width: "60px"}} />
                    </Link>
                </div>
                <div className="col-md-8" style={{padding: "10px"}}>
                    <Link to={"/"+i18next.language+this.state.url}>
                        <h5 style={{"color":"#222","fontWeight":"700","lineHeight":"1em","height":"4em","overflow":"hidden",display:"flex",alignItems:"center"}}>{this.state.name}</h5>
                    </Link>
                </div>
            </div>
        );
    }
}



class ItemLoader extends Component
{
    render() {
        return ( 
            <div className="col-md-4 col-wide b2b-inner row" >
                <div className="col-md-4" style={{padding: "10px"}}>
                    <a href="javascript:void(0)">
                        <div style={{width: "60px"}} className="loader-recommande-image"/>
                    </a>
                </div>
                <div className="col-md-8" style={{padding: "10px"}}>
                    <a href="javascript:void(0)">
                        <h5 className="loader-recommande-name" style={{"color":"#222","fontWeight":"700","lineHeight":"1em","height":"4em","overflow":"hidden"}}></h5>
                    </a>
                </div>
            </div>
        );
    }
}