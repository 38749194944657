
import './styleSlider.css';
import React, { Component } from 'react';
import DataSlider from '../../services/home';
import { NavLink,Link } from 'react-router-dom';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import i18next from 'i18next';
import { Markup } from 'react-render-markup';
import { withTranslation } from 'react-i18next';
class SliderEnVedette extends Component
{
    constructor (props) {
        super(props)
        var event = true;
        if(props.event==false) event = false;
        this.state = {
            entreprisesItems : [<ItemEntreprise/>,<ItemEntreprise/>,<ItemEntreprise/>,<ItemEntreprise/>],
            produitsItems : [<ItemProduit/>,<ItemProduit/>,<ItemProduit/>,<ItemProduit/>],
            evennementsItems : [<ItemEvennement t={this.props.t}/>,<ItemEvennement t={this.props.t}/>,<ItemEvennement t={this.props.t}/>,<ItemEvennement t={this.props.t}/>],
            showevent : event
        }
        setTimeout(() => {
            DataSlider.getEntreprisesEnVedette().then((res)=>{
                if(res.data.get){
                    var entreprises=[];
                    var i = 0;
                    var item1 = null;
                    var item2 = null;
                    var item3 = null;
                    var item4 = null;
                    res.data.data.forEach(entreprise => {
                        i++;
                        if(i==1){
                            item1 = entreprise;
                        }else if(i==2){
                            item2 = entreprise;
                        }else if(i==3){
                            item3 = entreprise;
                        }else if(i==4){
                            item4 = entreprise;
                            i = 0;
                            entreprises.push(<ItemEntreprise e1={item1} e2={item2} e3={item3} e4={item4}/>);
                        }
                        
                    });
                    this.setState({entreprisesItems: entreprises});
                }
            });
        }, 1000);
        setTimeout(() => {
            DataSlider.getEvennements().then((res)=>{
                if(res.data.get){
                    var evennementsItems=[];
                    res.data.data.forEach(event => {
                        evennementsItems.push(<ItemEvennement t={this.props.t} data={event}/>);
                    });
                    this.setState({evennementsItems: evennementsItems});
                }
            });
        }, 2000);
        setTimeout(() => {
            DataSlider.getProduitsEnVedette().then((res)=>{
                if(res.data.get){
                    var produits=[];
                    var i = 0;
                    var item1 = null;
                    var item2 = null;
                    var item3 = null;
                    var item4 = null;
                    res.data.data.forEach(produit => {
                        i++;
                        if(i==1){
                            item1 = produit;
                        }else if(i==2){
                            item2 = produit;
                        }else if(i==3){
                            item3 = produit;
                        }else if(i==4){
                            item4 = produit;
                            i = 0;
                            produits.push(<ItemProduit p1={item1} p2={item2} p3={item3} p4={item4}/>);
                        }
                        
                    });
                    this.setState({produitsItems: produits});
                }
            });
        }, 3000);
        
    }
    render() {
        const { t } = this.props;
        var lang="fr";
        if(i18next.language=="en")lang="en";
        return ( 
            <>
                <div class="top-entreprise  mb-3" style={{"height":"282px"}}>
                    <div class="top-entreprise-header">
                        <img onContextMenu={(e)=> e.preventDefault()} src={lang=="en"?"/upload/image/en_entreprises_en_vedette.png":"/upload/image/entreprises_en_vedette.png"} />
                    </div>
                    <div class="top-entreprise-body">
                        <AliceCarousel 
                        mouseTracking 
                        autoPlay={true}
                        items={this.state.entreprisesItems}
                        infinite
                        renderDotsItem={false}
                        disableButtonsControls={true}
                        autoPlayInterval = {4000}
                        responsive={{1200: {items: 1},1000: {items: 1},800: {items: 1},300: {items: 1}}} />
                    </div>
                </div>
                {this.state.showevent?
                <div class="top-entreprise  mb-3" style={{"height":"282px"}}>
                    <div class="top-entreprise-header">
                        <img onContextMenu={(e)=> e.preventDefault()} src={lang=="en"?"/upload/image/en_evennements_b2b.png":"/upload/image/evennements_b2b.png"} />
                    </div>
                    <div class="top-entreprise-body top-evennementsItems">
                    <AliceCarousel 
                        mouseTracking 
                        autoPlay={true}
                        items={this.state.evennementsItems}
                        infinite
                        renderDotsItem={false}
                        autoPlayInterval = {2000}
                        responsive={{1200: {items: 1},1000: {items: 1},800: {items: 1},300: {items: 1}}} />
                    </div>
                    <div class="top-entreprise-footer"> 
                        <Link to={"/"+i18next.language+"/evenements"}>{t("VOIR TOUS")} ...</Link>
                    </div>
                </div>
                :<></>}
                <div class="top-entreprise  mb-3" style={{"height":"282px"}}>
                    <div class="top-entreprise-header">
                        <img onContextMenu={(e)=> e.preventDefault()} src={lang=="en"?"/upload/image/en_produits_en_vedette.png":"/upload/image/produits_en_vedette.png"} />
                    </div>
                    <div class="top-entreprise-body">
                    <AliceCarousel 
                        mouseTracking 
                        autoPlay={true}
                        items={this.state.produitsItems}
                        infinite
                        renderDotsItem={false}
                        disableButtonsControls={true}
                        autoPlayInterval = {2000}
                        responsive={{1200: {items: 1},1000: {items: 1},800: {items: 1},300: {items: 1}}} />
                    </div>
                </div>
            </>
        );
    }
}
export default withTranslation()(SliderEnVedette);




class ItemEntreprise extends Component
{
    constructor (props) {
        super(props);
        this.state = {
          
        }
    }
  
    render() {
        if(this.props.e1){
            return ( 
                <div class="top-entreprise-item item">
                    {this.props.e1?
                    <a href={"/"+i18next.language+this.props.e1.url}>
                        <div class="top-entreprise-sue-item">
                            <figure>
                            <img onContextMenu={(e)=> e.preventDefault()} src={this.props.e1.img} />
                            </figure>
                            <span><Markup markup={this.props.e1.title} /></span> 
                        </div>
                    </a>
                    :<></>}
                   {this.props.e2?
                    <a href={"/"+i18next.language+this.props.e2.url}>
                        <div class="top-entreprise-sue-item">
                            <figure>
                            <img onContextMenu={(e)=> e.preventDefault()} src={this.props.e2.img} />
                            </figure>
                            <span><Markup markup={this.props.e2.title} /></span> 
                        </div>
                    </a>
                    :<></>}
                    {this.props.e3?
                    <a href={"/"+i18next.language+this.props.e3.url}>
                        <div class="top-entreprise-sue-item">
                            <figure>
                            <img onContextMenu={(e)=> e.preventDefault()} src={this.props.e3.img} />
                            </figure>
                            <span><Markup markup={this.props.e3.title} /></span> 
                        </div>
                    </a>
                    :<></>}
                    {this.props.e4?
                    <a href={"/"+i18next.language+this.props.e4.url}>
                        <div class="top-entreprise-sue-item">
                            <figure>
                            <img onContextMenu={(e)=> e.preventDefault()} src={this.props.e4.img} />
                            </figure>
                            <span><Markup markup={this.props.e4.title} /></span> 
                        </div>
                    </a>
                    :<></>}
                </div>
            );
        }else{
            return ( 
                <div class="item-top-loader"></div>
            );
        }
    }
}

class ItemProduit extends Component
{
    constructor (props) {
        super(props);
        this.state = {
        }
    }
  
    render() {
        if(this.props.p1){
            return ( 
                <div class="top-entreprise-item item">
                    {this.props.p1?
                    <a href={"/"+i18next.language+this.props.p1.url}>
                        <div class="top-entreprise-sue-item">
                            <figure>
                            <img onContextMenu={(e)=> e.preventDefault()} src={this.props.p1.img} />
                            </figure>
                            <span><Markup markup={this.props.p1.title} /></span> 
                        </div>
                    </a>
                    :<></>}
                   {this.props.p2?
                    <a href={"/"+i18next.language+this.props.p2.url}>
                        <div class="top-entreprise-sue-item">
                            <figure>
                            <img onContextMenu={(e)=> e.preventDefault()} src={this.props.p2.img} />
                            </figure>
                            <span><Markup markup={this.props.p2.title} /></span> 
                        </div>
                    </a>
                    :<></>}
                    {this.props.p3?
                    <a href={"/"+i18next.language+this.props.p3.url}>
                        <div class="top-entreprise-sue-item">
                            <figure>
                            <img onContextMenu={(e)=> e.preventDefault()} src={this.props.p3.img} />
                            </figure>
                            <span><Markup markup={this.props.p3.title} /></span> 
                        </div>
                    </a>
                    :<></>}
                    {this.props.p4?
                    <a href={"/"+i18next.language+this.props.p4.url}>
                        <div class="top-entreprise-sue-item">
                            <figure>
                            <img onContextMenu={(e)=> e.preventDefault()} src={this.props.p4.img} />
                            </figure>
                            <span><Markup markup={this.props.p4.title} /></span> 
                        </div>
                    </a>
                    :<></>}
                </div>
            );
        }else{
            return ( 
                <div class="item-top-loader"></div>
            );
        }
    }
}
class ItemEvennement extends Component
{
    constructor (props) {
        super(props);
        this.state = {
        }
    }
  
    render() {
        const { t } = this.props;
        if(this.props.data){
            return ( 
                <div class="top-entreprise-item item px-3"> 
                    <NavLink to={"/"+i18next.language+this.props.data.url}>
                        <div class="top-event-sue-item px-2"> 
                            <span class="title-event"><Markup markup={this.props.data.title} /></span>
                            <figure> 
                                <img onContextMenu={(e)=> e.preventDefault()} src={this.props.data.img} />
                            </figure> 
                            <span class="date-event"> 
                                <span>{t("DATE")}  :</span> {t("Du")} {this.props.data.data_du} {t("au")} {this.props.data.data_au} 
                            </span> 
                            <span class="adresse-event">
                                <span>{t("LIEU")} :</span> <Markup markup={this.props.data.lieu} />
                            </span>
                        </div> 
                    </NavLink>
                </div>
            );
        }else{
            return ( 
                <div class="item-top-loader"></div>
            );
        }
    }
}
class ItemLoader extends Component
{
    render() {

        return ( 
            <li className=" block-company-mobile place-item post-1809 place type-place status-publish has-post-thumbnail hentry place_category-industrie location-tunis col-md-3 col-xs-6" style={{"padding-left":"0px","padding-right":"11px"}}>
            <div className="place-wrapper" style={{"background-color": "#ffffff","border": "1px solid #b8c7c8","box-shadow": "none"}}>
                <div className="title-place notranslate loader-entreprise-title"><p></p></div>
                <div className="hidden-img">
                    <div className="img-place loader-entreprise-image" ><p></p></div>
                </div>
                <div className="place-detail-wrapper details-list" style={{"height":"auto"}}>
                    <span className="address-place" adresse="" itemprop="addressLocality"><i className="fa fa-phone"></i> {localStorage.getItem("langb2b")==="en" ? "Phone" : "Tél" } : +216 <span className="loader-entreprise-tel-add-prod"></span></span>
                    <span className="address-place" adresse="" itemprop="addressLocality"><i className="fa fa-map-marker"></i> <span className="loader-entreprise-tel-add-prod"></span></span>
                    <span className="address-place" adresse="" itemprop="addressLocality"><i className="fa fa-newspaper-o"></i> <span className="loader-entreprise-tel-add-prod"></span></span>
                </div>
            </div>
        </li>
        );
    }
}
