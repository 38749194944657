import './style.css';
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Auth from '../../../services/Auth'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import Experts from "../../../services/experts"
import i18next from 'i18next';
import Header from './header';
import { useForm } from "react-hook-form";
import Devis from "../../../services/devis"
import { useEffect } from "react";
import { useState } from "react";
import Button from './button.js';
import Compitonse from './compitonse.js';
export default class Index extends Component
{
    constructor (props) {
        super(props)
        this.state = {
            isLogin:true
        }
        Auth.isLogin().then((res)=>{
            if(res.data!=false){
                this.setState({isLogin:true});
            }else{
                this.setState({isLogin:false});
            }
        });
        window.scrollTo(0, 0);
    }
    render() {
        if (this.state.isLogin) {
            // setTimeout(() => {
            //    window.location.href="/b2b/admin/expert/inscription?token="+localStorage.getItem("tokenb2b"); 
            // }, 2000);
            // return ( <></> );
            return ( <Inscription/> );
        } else {
            return ( <Redirect to={"/"+i18next.language}/> );
        }
    }
}
function useScrollToError(errors) {
    useEffect(() => {
      const errorsvalues = Object.values(errors)
      if (errorsvalues.length > 0) {
        errorsvalues[0].ref.scrollIntoView(false)
      }
    }, [errors])
}

function Inscription() {       
    const [formations, setFormations] = useState([{
        "id":0,
        "diplome":"",
        "etablissement":"",
        "data_debM":"",
        "data_debA":"",
        "data_finM":"",
        "data_finA":"",
        "encours":"",
    }]);      
    const [experiences, setExperiences] = useState([{
        "id":0,
        "fonction":"",
        "societe":"",
        "data_deb":"",
        "data_fin":"",
        "encours":"",
        "description":""
    }]);
    const [loderFrome, setLoderFrome] = useState("d-none");
    const [finInscription, setFinInscription] = useState(false);
    const [errorExperiences, setErrorExperiences] = useState(false);
    const [textErrorExperiences, setTextErrorExperiences] = useState("");
    const [errorFormations, setErrorFormations] = useState(false);
    const [textErrorFormations, setTextErrorFormations] = useState("");
    const [demandeur_radio, setDemandeurRadio] = useState("");
    const [selectCompetencesFreelance, setSelectCompetencesFreelance] = useState([]);
    const [interes_freelance, setInteresFreelance] = useState("");
    const [interes_emploi, setInteresEmploi] = useState("");
    const [optionTypeContrat, setOptionTypeContrat] = useState(null);
    const [selectOptionTypeContrat, setSelectTypeContrat] = useState([]);
    const [optionDomainesTravail, setOptionDomainesTravail] = useState(null);
    const [optionCompetencesFreelance, setOptionCompetencesFreelance] = useState([]);
    const [optionDomainesFreelance, setOptionDomainesFreelance] = useState(null);
    const [competences, setCompetences] = useState([{"id":0,"competence":"","p":"1"}]);
    const [domainesFreelance, setDomainesFreelance] = useState(null);
    const [optionSalaires, setOptionSalaires] = useState(null);
    const [disponibilite, setDisponibilite] = useState();
    const { register, handleSubmit, reset , formState: { errors } } = useForm();
    useScrollToError(errors);
    const onSubmit = (data) => 
    {
        setErrorFormations(false);
        setErrorExperiences(false);
        setTextErrorFormations("");
        var listeFormations = true;
        formations.forEach(f => {
            if(f.diplome=="" || f.etablissement=="" || f.data_debM=="" || f.data_debA=="" ){
                listeFormations = false;
                
            }
            if((f.encours=="non" || f.encours=="" ) && (f.data_finM=="" ||f.data_finA=="") ){
                listeFormations = false;
            }
            if((f.encours=="non" || f.encours=="" ) && (f.data_debA > f.data_finA) ){
                listeFormations = false;
                setTextErrorFormations("deb>fin");
            }
            if((f.encours=="non" || f.encours=="" ) && (f.data_debA == f.data_finA) && (f.data_debM > f.data_finM)){
                listeFormations = false;
                setTextErrorFormations("deb>fin");
            }
            if(!listeFormations){
                
               var element = document.getElementById("formation_"+f.id);
               if (element) {
                   element.scrollIntoView(false);
               }
            }
        });
        var listeExperiences = true;
        setTextErrorExperiences("");
        
        experiences.forEach(e => {
            if(e.fonction=="" || e.societe=="" ||e.data_deb=="" ||e.description==""){
                listeExperiences = false;
            }
            if((e.encours=="non" || e.encours=="" ) && e.data_fin=="" ){
                listeExperiences = false;
            }
            if((e.encours=="non" || e.encours=="" ) && (e.data_deb > e.data_fin) ){
                listeExperiences = false;
                
                setTextErrorExperiences("deb>fin");
            }
            if(!listeExperiences){
                var element = document.getElementById("experience_"+e.id);
                if (element) {
                    element.scrollIntoView(false);
                }
            }
        });
        if(!listeFormations){
            setErrorFormations(true);
            return false;
        } 
        if(!listeExperiences){
            setErrorExperiences(true);
            return false;
        }
        var dataexperiences = [];
        experiences.forEach(e => {
            var experience =[];
            experience.push(e.fonction.replace(/,/g,"&#44;"));//0
            experience.push(e.societe.replace(/,/g,"&#44;"));//1
            experience.push(e.description.replace(/,/g,"&#44;"));//2
            experience.push(e.data_deb);//3
            experience.push(e.data_fin);//4
            experience.push(e.encours);//5
            experience.push("####");
            dataexperiences.push(experience);
        });
        var dataformations = [];
        formations.forEach(e => {
            var formation =[];
            formation.push(e.diplome.replace(/,/g,"&#44;"));//0
            formation.push(e.etablissement.replace(/,/g,"&#44;"));//1
            formation.push(e.data_debM);//2
            formation.push(e.data_debA);//3
            formation.push(e.data_finM);//4
            formation.push(e.data_finA);//5
            formation.push(e.encours);//6
            formation.push("####");
            dataformations.push(formation);
        });
        setLoderFrome("loder-from");
        console.log("-------------------dataexperiences-------------------");
        console.log(dataexperiences);
        console.log("-------------------dataexperiences-------------------");
        console.log("-------------------experiences-------------------");
        console.log(experiences);
        console.log("-------------------experiences-------------------");
        var competence_emploi = [];
        var competence_p_emploi = [];
        var number_of_competence_emploi = competences.length;
        competences.forEach(c => {
            competence_emploi.push(c.competence);
            competence_p_emploi.push(c.p);
        });
        Auth.inscriptionExpert3(data,dataformations,dataexperiences,selectCompetencesFreelance,selectOptionTypeContrat,competence_emploi,competence_p_emploi,number_of_competence_emploi,disponibilite,interes_freelance,interes_emploi).then((res)=>{
            console.log(res);
            setLoderFrome("d-none");
            if(res.data.register){

                setLoderFrome("");
                Auth.inscriptionExpertMail();
                setFinInscription(true);
            }
        });
    }
    const addFormation = ()=>{
        var newFormations=[];
        var add = true;
        setErrorFormations(false);
        formations.forEach(f => {
            newFormations.push(f);
            setTextErrorFormations("");
            if(f.diplome=="" || f.etablissement=="" || f.data_debM=="" || f.data_debA=="" ){
                add = false;
            }
            if((f.encours=="non" || f.encours=="" ) && (f.data_finM=="" ||f.data_finA=="") ){
                add = false;
            }
            if((f.encours=="non" || f.encours=="" ) && (f.data_debA > f.data_finA) ){
                add = false;
                setTextErrorFormations("deb>fin");
            }
            if((f.encours=="non" || f.encours=="" ) && (f.data_debA == f.data_finA) && (f.data_debM > f.data_finM)){
                add = false;
                setTextErrorFormations("deb>fin");
            }
        });
        if(add){
            newFormations.push({
                "id":formations.length,
                "diplome":"",
                "etablissement":"",
                "data_debM":"",
                "data_debA":"",
                "data_finM":"",
                "data_finA":"",
                "encours":"",
            });
        }else{
            setErrorFormations(true);
        }
        setFormations(newFormations);
    }
    const addExperience = ()=>{
        var newExperiences=[];
        var add = true;
        setErrorExperiences(false);
        experiences.forEach(experience => {
            newExperiences.push(experience);
            setTextErrorExperiences("");
            if(experience.fonction=="" || experience.societe=="" ||experience.data_deb=="" ||experience.description==""){
                add = false;
            }
            if((experience.encours=="non" || experience.encours=="" ) && experience.data_fin=="" ){
                add = false;
            }
            if((experience.encours=="non" || experience.encours=="" ) && (experience.data_deb > experience.data_fin) ){
                add = false;
                setTextErrorExperiences("deb>fin");
                
            }
        });
        if(add){
            newExperiences.push({
                "id":experiences.length,
                "fonction":"",
                "societe":"",
                "data_deb":"",
                "data_fin":"",
                "encours":"",
                "description":""
            });
        }else{
            setErrorExperiences(true);
        }
       
        setExperiences(newExperiences);
    }
    const removeFormation = ()=>{
        setFormations(formations.slice(0, -1));
    }
    const removeExperience = ()=>{
        setExperiences(experiences.slice(0, -1));
    }
    const UpdateFormations=(formation)=>{
        var newFormations = [];
        formations.forEach(f => {
            if(f.id==formation.id){
                newFormations.push(formation)
            }else{
                newFormations.push(f);
            }
            setFormations(newFormations);
        });
        
    }
    const UpdateExperiences=(experience)=>{
        var newExperiences = [];
        experiences.forEach(c => {
            if(c.id==experience.id){
                newExperiences.push(experience)
            }else{
                newExperiences.push(c);
            }
        });
        setExperiences(newExperiences);
    }

    const chingeEmploi  =(e)=>{
        if(e.target.value=="oui"){
             setInteresEmploi(true)
        }else{
            setInteresEmploi(false)
        }
    }
    const chingeFreelance =(e)=>{
        if(e.target.value=="oui"){
            setInteresFreelance(true)
        }else{
            setInteresFreelance(false)
        }
    }
    const updateCompetencesFreelance= (val,stat)=>{
        var tab =[];
        if(stat=="add"){
            tab = selectCompetencesFreelance;
            tab.push(val);
        }else if(stat=="del"){
            var index = selectCompetencesFreelance.indexOf(val);
            tab = selectCompetencesFreelance;
            tab.splice(index, 1)
        } 
        setSelectCompetencesFreelance(tab);
    }
    const updateDomainesFreelance = (id)=>{
        domainesFreelance.forEach(domaine => {
            if(domaine.id==id){
                var tags=[];
                domaine.tags.forEach(tag => {
                    tags.push(<Button tag={tag} id={tag} update={updateCompetencesFreelance}/> ) 
                });
                setSelectCompetencesFreelance([]);
                setOptionCompetencesFreelance(tags);
            }
        });

    }
    const removeCompetence = ()=>{
        setCompetences(competences.slice(0, -1))
    } 
    const addCompetence= ()=>{
        var newCompetences = [];
        var add = true;
        competences.forEach(c => {
            newCompetences.push(c);
            if(c.competence=="" || c.p==0){
                add = false;
            }
        });
        if(add){
            newCompetences.push({"id":competences.length,"competence":"","p":"0"});
        }
        setCompetences(newCompetences);
    }
    
    const updateCompetence= (competence,p,key)=>{
        var newCompetences = [];
        competences.forEach(c => {
            if(c.id==key){
                newCompetences.push({
                    "id":key,
                    "competence":competence,
                    "p":p
                })
            }else{
                newCompetences.push(c);
            }
        });
        setCompetences(newCompetences);
    }

    const updateTypeContrat= (val,stat)=>{
       
        var tab =[];
        if(stat=="add"){
            tab = selectOptionTypeContrat;
            tab.push(val);
        }else if(stat=="del"){
            var index = selectOptionTypeContrat.indexOf(val);
            tab = selectOptionTypeContrat;
            tab.splice(index, 1)
        } 
        setSelectTypeContrat(tab);
    }
    useEffect(() => {
        Auth.getDataInscriptionExpert().then((res)=>{
            if(res.data.get){
                console.log(res.data.data);
                setDemandeurRadio(res.data.data.type_compte[0]=="non"?res.data.data.type_compte[1]:res.data.data.type_compte[0]);
            }
        });
        Experts.getInfoInscription().then((res)=>{
            if(res.data.get){
              

                var items = [];
                res.data.domaines_de_travail.forEach(item => {
                    items.push(<option value={item.id}>{item.name}</option>);
                });
                setOptionDomainesTravail(items);

                var items = [];
                res.data.domaines_freelance.forEach(item => {
                    items.push(<option value={item.id}>{item.name}</option>);
                });
                setDomainesFreelance(res.data.domaines_freelance);
                setOptionDomainesFreelance(items);

                // var items = [];
                // res.data.experience.forEach(item => {
                //     items.push(<option value={item.id}>{item.name}</option>);
                // });
                // setOptionExperience(items);

                var items = [];
                res.data.type_contrat.forEach(item => {
                    items.push(<Button tag={item.name} id={item.id} update={updateTypeContrat} type="cont"/>);
                });
                setOptionTypeContrat(items);

                var items = [];
                res.data.salaires.forEach(item => {
                    items.push(<option value={item.id}>{item.name}</option>);
                });
                setOptionSalaires(items);
            }
        });
    }, []);
    if(finInscription){
        return (
            <Redirect to={"/"+i18next.language+"/expert/inscription-mail"}/>
        );
    }else{
        return (
            <div className="container dashboard-content mt-3 block-expert-inscription inscription3">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="section-block" id="basicform">
                        </div>
                        <Header active="3"/>
                        <div className="card">
                            <div className={loderFrome}>
                                <span className='loading-login'></span>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit(onSubmit)} className="f1 row m-form-b2b ">
                                    <div className="col-md-12 " id="block3">
                                        <h4 className="test-titel test-formation"><span />ÉDUCATION &amp; FORMATION:</h4>
                                        <div id="formations_div" style={{}}>
                                            <div id="formations_div_inner">
                                                {formations.map((formation)=>{
                                                    return <Formations formation={formation} update={UpdateFormations}/>
                                                })}
                                            </div>
                                            {errorFormations?<span className='error d-block text-transform-unset'>Les champs « Education &amp; formation » sont obligatoires {textErrorFormations!=""?"et vérifié la date du fin!":""}</span>:<></>}
                                            <button className="btn btn-add-formation" onClick={addFormation} type="button">Ajouter une autre formation</button>
                                            <button className="btn btn-remove-formation" onClick={removeFormation} type="button" style={formations.length>1?{display: ''}:{display: 'none'}}>Supprimer</button>
                                        </div>
                                        <h4 className="test-titel test-experience show-desktop"><span />EXPÉRIENCE PROFESSIONNELLE :</h4>
                                        <h4 className="test-titel test-experience show-mobile"><span />EXPÉRIENCE :</h4>
                                        <div className="para-test-experience">
                                            <label><i>Important:</i></label>
                                            <p> <i>Classez vos expériences de la plus ancienne (votre première expérience) à la plus récente,</i><span>✓</span></p>
                                            <p> <i>Puis cliquez sur le bouton "Ajouter une nouvelle Expérience" pour ajouter d'autres.</i><span>✓</span></p>
                                        </div>
                                        <div id="experiences_div" style={{}}>
                                            <div id="experiences_div_inner">
                                                <input id="number_of_experiences" name="number_of_experiences" type="hidden" defaultValue={1} />
                                                {experiences.map((experience)=>{
                                                    return <Experience experience={experience} update={UpdateExperiences}/>
                                                })}
                                            </div>
                                            {errorExperiences?<span className='error d-block text-transform-unset'>Les champs « Expérience professionnelle » sont obligatoires {textErrorExperiences!=""?"et vérifié la date du fin!":""}</span>:<></>}
                                            <button className="btn btn-add-formation" onClick={addExperience} type="button">Ajouter une autre experience</button>
                                            <button className="btn btn-remove-formation" onClick={removeExperience} type="button" style={experiences.length>1?{display: ''}:{display: 'none'}}>Supprimer</button>
                                        </div>
                                        {demandeur_radio=="freelancer"?
                                        <div className="interesse-offres-freelance">
                                            <div className="form-group">
                                                <div className="row">
                                                    <label className="col-md-6">Vous êtes intéressé par des nouvelles opportunités d'emploi ?</label>
                                                    <label className="col-md-2">
                                                        <input type="radio" id="radio_4_" className="interes_emploi" value="oui" {...register("interes_emploi", { required: demandeur_radio=="freelancer"?true:false,onChange:(e) => {chingeEmploi(e)} })}  />
                                                        <label htmlFor="radio_4_" className="d-inline">Oui</label>
                                                    </label>
                                                    <label className="col-md-2">
                                                        <input type="radio" id="radio_3_" className="interes_emploi"  value="non" {...register("interes_emploi", { required: demandeur_radio=="freelancer"?true:false,onChange:(e) => {chingeEmploi(e)} })} />
                                                        <label htmlFor="radio_3_" className="d-inline">Non</label>
                                                    </label>
                                                </div>
                                                {errors.interes_emploi?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                            </div>
                                            {/* -------------------------interes_emploi --------------------------- */}
                                            {interes_emploi?<>
                                                <div className={"form-group "} id="domaine_emploi">                                
                                                    <p>Choisissez une catégorie correspondant à votre domaine d'activité.</p>
                                                    <div className="form-group row">
                                                        <label className="col-md-6">Catégorie emploi:   <span className="star">*</span></label>
                                                        <div className='col-md-6'>
                                                            <select className="form-control bs-select-hidden" {...register("interes_emploi_domaine", { required: (interes_emploi?true:false) })} style={{color: '#000', backgroundColor: '#fff', display: 'block !important'}}>
                                                                <option value="">-Sélectionner-</option>
                                                                {optionDomainesTravail}
                                                            </select>
                                                            {errors.domaine?<span className="error d-block">Catégorie emploi est obligatoire</span>:<></>}
                                                        </div>
                                                        
                                                    </div>
                                                    <label className="col-md-6 pl-0">Compétences Techniques :   <span className="star">*</span></label>
                                                    <span className="error-competences-techniques">Remplir toute les Compétences</span>
                                                    <div id="competences_div" style={{}}>
                                                        <div id="competences_div_inner" className="row">
                                                            { competences.map(c => {
                                                                return <Compitonse competence={c.competence} p={c.p} id={c.id} update={updateCompetence} />
                                                            })}
                                                        </div>
                                                        <button className="btn btn-add-formation" onClick={addCompetence} type="button">Ajouter une autre compétence</button>
                                                        <button className="btn btn-remove-formation" onClick={removeCompetence} type="button">Supprimer</button>
                                                    </div>
                                                </div>
                                                <div className={"form-group "} id="div_type_emploi" >                                
                                                    <div className="form-group row">
                                                        <label className="col-md-6">Type de contrat souhaité ?   <span className="star">*</span> <span id="error_input_type_emploi" className="star" /></label>  
                                                        
                                                        <div className="row div_type_contrat w-100">
                                                            {optionTypeContrat}
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div className={"form-group "} id="div_disponibilite">
                                                    <label>Disponibilité:  <span className="star">*</span> <span id="error_input_disponibilite" className="star" /></label>
                                                    <div className="row">
                                                        <div className="col-md-4 disponibilite">
                                                            <button type="button" onClick={(e)=>{setDisponibilite("disponibilite_immediatement")}} className={"btn btn-outline-secondary btn-min-width mr-1 mb-1 "+(disponibilite=="disponibilite_immediatement"?"btn_clicked":"btn_not_clicked")} >
                                                            Je suis disponible <br />immédiatement
                                                            </button>
                                                        </div>
                                                        <div className="col-md-4 disponibilite">
                                                            <button type="button" onClick={(e)=>{setDisponibilite("preavis")}} className={"btn btn-outline-secondary btn-min-width mr-1 mb-1 "+(disponibilite=="preavis"?"btn_clicked":"btn_not_clicked")}>
                                                            Avec préavis
                                                            </button>
                                                            <input type="text" {...register("disponibilite_duree", { required: (disponibilite=="preavis" && interes_emploi)?true:false })} placeholder="Durée" style={disponibilite=="preavis"?{display: 'block'}:{display: 'none'}} />
                                                            {errors.disponibilite_duree?<span className="error d-block">Disponibilité "Avec préavis" est obligatoire</span>:<></>}
                                                        </div>
                                                        <div className="col-md-4 disponibilite">
                                                            <button type="button" onClick={(e)=>{setDisponibilite("disponibilite_fin")}}  className={"btn btn-outline-secondary btn-min-width mr-1 mb-1 "+(disponibilite=="disponibilite_fin"?"btn_clicked":"btn_not_clicked")} >
                                                            Fin du contrat
                                                            </button>
                                                            <input type="date" {...register("disponibilite_datefin", { required: (disponibilite=="disponibilite_fin" && interes_emploi)?true:false })} placeholder="Date de fin" style={disponibilite=="disponibilite_fin"?{display: 'block'}:{display: 'none'}} />
                                                            {errors.disponibilite_datefin?<span className="error d-block">Disponibilité "Fin du contrat" est obligatoire</span>:<></>}
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div className={"row "} id="salaire_emploi">                                    
                                                    <div className={"col-md-6 salaire-actuel "}>
                                                        <div className="form-group row">
                                                            <label className="col-md-6">Salaire actuel:</label>    
                                                            <input className="form-control selectpicker col-md-6" {...register("salaire_actuel" )}  />
                                                            {errors.salaire_actuel?<span className="error d-block">Salaire actuel est obligatoire</span>:<></>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 salaire-souhaite" >
                                                        <div className="form-group row">
                                                            <label className="col-md-6">Salaire souhaité  <span className="star">*</span></label>    
                                                            <select className="form-control selectpicker col-md-6 bs-select-hidden" {...register("salaire", { required: (interes_emploi?true:false) })}  style={{display: 'block !important', color: '#000', backgroundColor: '#fff'}}>
                                                                <option value="">-Sélectionner-</option>
                                                                {optionSalaires}
                                                            </select>
                                                            {errors.salaire?<span className="error d-block">Salaire souhaité est obligatoire</span>:<></>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>:<></>}
                                             {/* ------------------------- end interes_emploi --------------------------- */}
                                        </div>
                                        :demandeur_radio=="demandeurdemploi"?<div className="interesse-offres-freelance">
                                            <div className="form-group">
                                                <div className="row">
                                                    <label className="col-md-6">Vous êtes intéressé par des offres en freelance ?</label>
                                                    <label className="col-md-2">
                                                        <input type="radio" id="radio_4_" className="interes_freelance" value="oui" {...register("interes_freelance", { required: demandeur_radio=="demandeurdemploi"?true:false,onChange:(e) => {chingeFreelance(e)} })}  />
                                                        <label htmlFor="radio_4_" className="d-inline">Oui</label>
                                                    </label>
                                                    <label className="col-md-2">
                                                        <input type="radio" id="radio_3_" className="interes_freelance"  value="non" {...register("interes_freelance", { required: demandeur_radio=="demandeurdemploi"?true:false,onChange:(e) => {chingeFreelance(e)} })} />
                                                        <label htmlFor="radio_3_" className="d-inline">Non</label>
                                                    </label>
                                                </div>
                                                {errors.interes_freelance?<span className="error d-block">Ce champ est obligatoire</span>:<></>}
                                            </div>
                                             {/* ------------------------- interes_freelance --------------------------- */}
                                            {interes_freelance?<>
                                            <div className={"form-group "} id="domaine_freelance" >                                
                                                <p>Choisissez une catégorie correspondant à votre domaine d'activité.</p>
                                                <div className="form-group row">
                                                    <label className="col-md-6">Domaine Freelance:<span className="star">*</span></label>    
                                                    <select className="form-control col-md-6 bs-select-hidden" {...register("interes_freelance_domaine", { required: (interes_freelance?true:false) ,onChange: (e) => {updateDomainesFreelance(e.target.value)} })}  style={{display: 'block !important', color: '#000', backgroundColor: '#fff'}}>
                                                        <option value="">-Sélectionner-</option>
                                                        {optionDomainesFreelance}
                                                    </select>
                                                    {errors.domaine_freelance?<span className="error d-block">Domaine Freelance est obligatoire</span>:<></>}
                                                </div>
                                            </div>
                                            <div className={"col-md-12 "} id="competences_freelance" >                                
                                                <div className="form-group row">
                                                    <label htmlFor="shortDescription3">Compétences Freelance:</label>
                                                    <div id="responsecontainer" align="center">
                                                        {optionCompetencesFreelance}
                                                        <button type="button" className="btn btn-outline-secondary btn-min-width mr-1 mb-1 btn_not_clicked add_autre" name="add_autre" id="add_autre">Ajouter autre</button>
                                                    </div>
                                                    <div id="competences_container" align="center">
                                                    </div>
                                                </div>
                                            </div>
                                            </>:<></>}
                                            {/* -------------------------end interes_freelance --------------------------- */}
                                        </div>:<></>}
                                        <div className="custom-form row block-btn-precedent-etape-suivant">
                                            <div className="col-md-4">
                                                <Link to={"/"+i18next.language+"/expert/inscription-deuxieme"} className="btn btn-b2b suivant"  style={{float:"left"}}>Précédent</Link>
                                            </div>
                                            <div className="col-md-4">
                                                <span className="suivant-text text-center w-100">etape 3/3</span>
                                            </div>
                                            <div className="col-md-4">
                                                <input type="submit" name="ok" value="Valider" className="btn btn-b2b suivant"  style={{width:"auto",height:"auto"}} />
                                            </div>
                                            {/* <div className='col-md-12'>
                                                {errorFormations?<span className='error d-block'>Remplir toutes les entrées précédentes du formations {textErrorFormations!=""?"et vérifié la date du fin!":""}</span>:<></>}
                                                {errorExperiences?<span className='error d-block'>Remplir toutes les entrées précédentes du l'experiences {textErrorExperiences!=""?"et vérifié la date du fin!":""}</span>:<></>}
                                                {errors.domaine_freelance?<span className="error d-block">Domaine Freelance est obligatoire</span>:<></>}
                                                {errors.domaine?<span className="error d-block">Catégorie emploi est obligatoire</span>:<></>}
                                                {errors.disponibilite_datefin?<span className="error d-block">Disponibilité "Fin du contrat" est obligatoire</span>:<></>}
                                                {errors.disponibilite_duree?<span className="error d-block">Disponibilité "Avec préavis" est obligatoire</span>:<></>}
                                                {errors.salaire_actuel?<span className="error d-block">Salaire actuel est obligatoire</span>:<></>}
                                                {errors.salaire?<span className="error d-block">Salaire souhaité est obligatoire</span>:<></>}
                                            </div> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ); 
    }
    
}

class Experience extends Component
{
    constructor (props) {
        super(props)
        const currentDate= new Date();
        const Year=currentDate.getFullYear();
        const Month=currentDate.getMonth()+1;
        const Day=currentDate.getDate();
        const annee =[];
        const annee_fin=[];
        for (let index = (2022); index >= 1900; index--) {
            annee.push(<option value={index}>{index}</option>); 
            annee_fin.push(<option value={index}>{index}</option>); 
        }
        const maxdate=Year+"-"+(Month<10?"0"+Month:Month)+"-"+(Day<10?"0"+Day:Day);
        const data_deb_Array = props.experience.data_deb.split('-');
        const data_fin_Array = props.experience.data_fin.split('-');
        this.state={
            id:"e"+props.experience.id,
            fonction:props.experience.fonction,
            societe:props.experience.societe,
            data_deb:props.experience.data_deb,
            data_fin:props.experience.data_fin,
            encours:props.experience.encours,
            description:props.experience.description,
            data_deb_j:data_deb_Array[2]?data_deb_Array[2]:"",
            data_deb_m:data_deb_Array[1]?data_deb_Array[1]:"",
            data_deb_a:data_deb_Array[0]?data_deb_Array[0]:"",
            data_fin_j:data_fin_Array[2]?data_fin_Array[2]:"",
            data_fin_m:data_fin_Array[1]?data_fin_Array[1]:"",
            data_fin_a:data_fin_Array[0]?data_fin_Array[0]:"",
            disabled:"",
            maxdate:maxdate,
            mindate:"",
            annee:annee,
            annee_fin:annee_fin
        }
        console.log(props.experience);
    }
    ChangeFonction = (e)=>{
        this.setState({fonction:e.target.value});
        this.props.update({
            "id":this.props.experience.id,
            "fonction":e.target.value,
            "societe":this.state.societe,
            "data_deb":this.state.data_deb,
            "data_fin":this.state.data_fin,
            "encours":this.state.encours,
            "description":this.state.description
        });
    }
    ChangeSociete = (e)=>{
        this.setState({societe:e.target.value});
        this.props.update({
            "id":this.props.experience.id,
            "fonction":this.state.fonction,
            "societe":e.target.value,
            "data_deb":this.state.data_deb,
            "data_fin":this.state.data_fin,
            "encours":this.state.encours,
            "description":this.state.description
        });
    }
    // ChangeDateDeb = (e)=>{
    //     this.setState({
    //         data_deb:e.target.value,
    //         mindate:e.target.value
    //     });
    //     this.props.update({
    //         "id":this.props.experience.id,
    //         "fonction":this.state.fonction,
    //         "societe":this.state.societe,
    //         "data_deb":e.target.value,
    //         "data_fin":this.state.data_fin,
    //         "encours":this.state.encours,
    //         "description":this.state.description
    //     });
    // }
    ChangeDateDebJ = (e)=>{
        this.setState({
            data_deb_j:e.target.value,
        });
        if(e.target.value!="" && this.state.data_deb_m!="" && this.state.data_deb_a!=""){
            const data_deb = this.state.data_deb_a+"-"+this.state.data_deb_m+"-"+e.target.value;
            this.setState({
                data_deb:data_deb,
            });
            this.props.update({
                "id":this.props.experience.id,
                "fonction":this.state.fonction,
                "societe":this.state.societe,
                "data_deb":data_deb,
                "data_fin":this.state.data_fin,
                "encours":this.state.encours,
                "description":this.state.description
            });
        }
    }
    ChangeDateDebM = (e)=>{
        this.setState({
            data_deb_m:e.target.value,
        });
        if(e.target.value!="" && this.state.data_deb_j!="" && this.state.data_deb_a!=""){
            const data_deb = this.state.data_deb_a+"-"+e.target.value+"-"+this.state.data_deb_j;
            this.setState({
                data_deb:data_deb,
            });
            this.props.update({
                "id":this.props.experience.id,
                "fonction":this.state.fonction,
                "societe":this.state.societe,
                "data_deb":data_deb,
                "data_fin":this.state.data_fin,
                "encours":this.state.encours,
                "description":this.state.description
            });
        }
    }
    ChangeDateDebA = (e)=>{
        const annee_fin=[];
        for (let index = (2022); index >= parseInt(e.target.value); index--) {
            annee_fin.push(<option value={index}>{index}</option>); 
        }
        this.setState({
            data_deb_a:e.target.value,
            annee_fin:annee_fin
        });
        if(e.target.value!="" && this.state.data_deb_j!="" && this.state.data_deb_m!=""){
            const data_deb = e.target.value+"-"+this.state.data_deb_m+"-"+this.state.data_deb_j;
            this.setState({
                data_deb:data_deb,
            });
            this.props.update({
                "id":this.props.experience.id,
                "fonction":this.state.fonction,
                "societe":this.state.societe,
                "data_deb":data_deb,
                "data_fin":this.state.data_fin,
                "encours":this.state.encours,
                "description":this.state.description
            });
        }
    }
    // ChangeDateFin = (e)=>{
    //     this.setState({data_fin:e.target.value});
    //     this.props.update({
    //         "id":this.props.experience.id,
    //         "fonction":this.state.fonction,
    //         "societe":this.state.societe,
    //         "data_deb":this.state.data_deb,
    //         "data_fin":e.target.value,
    //         "encours":this.state.encours,
    //         "description":this.state.description
    //     });
    // }
    ChangeDateFinJ = (e)=>{
        this.setState({data_fin_j:e.target.value});
        if(e.target.value!="" && this.state.data_fin_m!="" && this.state.data_fin_a!=""){
            const data_fin = this.state.data_fin_a+"-"+this.state.data_fin_m+"-"+e.target.value;
            this.setState({
                data_fin:data_fin,
            });
            this.props.update({
                "id":this.props.experience.id,
                "fonction":this.state.fonction,
                "societe":this.state.societe,
                "data_deb": this.state.data_deb,
                "data_fin": data_fin,
                "encours":this.state.encours,
                "description":this.state.description
            });
        }
    }
    ChangeDateFinM = (e)=>{
        this.setState({data_fin_m:e.target.value});
        if(e.target.value!="" && this.state.data_fin_j!="" && this.state.data_fin_a!=""){
            const data_fin = this.state.data_fin_a+"-"+e.target.value+"-"+this.state.data_fin_j;
            this.setState({
                data_fin:data_fin,
            });
            this.props.update({
                "id":this.props.experience.id,
                "fonction":this.state.fonction,
                "societe":this.state.societe,
                "data_deb": this.state.data_deb,
                "data_fin": data_fin,
                "encours":this.state.encours,
                "description":this.state.description
            });
        }
    }
    ChangeDateFinA = (e)=>{
        this.setState({data_fin_a:e.target.value});
        if(e.target.value!="" && this.state.data_fin_m!="" && this.state.data_fin_j!=""){
            const data_fin = e.target.value+"-"+this.state.data_fin_m+"-"+this.state.data_fin_j;
            this.setState({
                data_fin:data_fin,
            });
            this.props.update({
                "id":this.props.experience.id,
                "fonction":this.state.fonction,
                "societe":this.state.societe,
                "data_deb": this.state.data_deb,
                "data_fin": data_fin,
                "encours":this.state.encours,
                "description":this.state.description
            });
        }
    }
    ChangeEnCours = (e)=>{
        var encours ="non";
        if(e.target.checked){
            this.setState({encours:"oui",disabled:"disabled"});
            encours ="oui";
        }else{
            this.setState({encours:"non",disabled:""});
            encours ="non";
        }
        this.props.update({
            "id":this.props.experience.id,
            "fonction":this.state.fonction,
            "societe":this.state.societe,
            "data_deb":this.state.data_deb,
            "data_fin":this.state.data_fin,
            "encours":encours,
            "description":this.state.description
        });
    }
    ChangeDescription = (e)=>{
        this.setState({description:e.target.value});
        this.props.update({
            "id":this.props.experience.id,
            "fonction":this.state.fonction,
            "societe":this.state.societe,
            "data_deb":this.state.data_deb,
            "data_fin":this.state.data_fin,
            "encours":this.state.encours,
            "description":e.target.value
        });
    }
    
    render() {
        return (<>
            <div className="form-group row" id={"experience_"+this.props.experience.id}>
                <div className="col-md-6">
                    <label>Fonction<span className="star">*</span></label>
                    <input type="text" className="form-control input-required" value={this.state.fonction} onChange={this.ChangeFonction} placeholder="Veuillez saisir votre fonction"/>
                </div>
                <div className="col-md-6">
                    <label>Societé<span className="star">*</span></label>
                    <input type="text" className="form-control input-required" value={this.state.societe} onChange={this.ChangeSociete} placeholder="Veuillez saisir le nom de la société"/> 
                </div>
                <div className="col-md-12 ml-2 mt-2">
                    <div className="row">
                        <div className="col-md-2 px-2 d-flex align-items-center" >
                            <label>Période<span className="star">*</span>: De</label>
                        </div>
                        <div className="col-md-3 pl-0 leftcontact d-flex align-items-center border-0">
                            {/* <input type="date" value={this.state.data_deb} max={this.state.maxdate} className="form-control input-required" onChange={this.ChangeDateDeb}/> */}
                            <div className="date_naissance">
                                <select className="form-control date_naissance_j bs-select-hidden" style={{padding:"0px"}} onChange={this.ChangeDateDebJ}>
                                    <option value="" disabled selected hidden>Jours</option>
                                    {[...Array(32)].map((_, index) => index>0?<option value={index<10?"0"+index:index}>{index}</option>:<></>)}
                                </select>
                                <select style={{margin:"0px 2px",padding:"0px",margin:"2px"}} className="form-control date_naissance_m bs-select-hidden"  onChange={this.ChangeDateDebM}>
                                    <option value="" disabled selected hidden>Mois</option>
                                    <option value="01">Janvier</option>
                                    <option value="02">Février</option>
                                    <option value="03">Mars</option>
                                    <option value="04">Avril</option>
                                    <option value="05">Mai</option>
                                    <option value="06">Juin</option>
                                    <option value="07">Juillet</option>
                                    <option value="08">Août</option>
                                    <option value="09">Septembre</option>
                                    <option value="10">Octobre</option>
                                    <option value="11">Novembre</option>
                                    <option value="12">Décembre</option>                                  
                                </select>
                                <select className="form-control date_naissance_a bs-select-hidden" style={{padding:"0px"}} onChange={this.ChangeDateDebA}>
                                    <option value="" disabled selected hidden>Anneé</option>
                                    {this.state.annee}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-1 pr-0 d-flex align-items-center">
                            <label>Au</label>
                        </div>
                        <div className="col-md-3 rightcontact d-flex align-items-center pl-2">
                            {/* <input type="date" value={this.state.data_fin} max={this.state.maxdate} min={this.state.mindate} className="form-control" onChange={this.ChangeDateFin} disabled={this.state.disabled}/> */}
                            <div className="date_naissance">
                                <select className="form-control date_naissance_j bs-select-hidden" style={{padding:"0px"}} disabled={this.state.disabled} onChange={this.ChangeDateFinJ}>
                                    <option value=""  disabled selected hidden>Jours</option>
                                    {[...Array(32)].map((_, index) => index>0?<option value={index<10?"0"+index:index}>{index}</option>:<></>)}
                                </select>
                                <select style={{margin:"0px 2px",padding:"0px",margin:"2px"}} className="form-control date_naissance_m bs-select-hidden" disabled={this.state.disabled} onChange={this.ChangeDateFinM}>
                                    <option value=""  disabled selected hidden>Mois</option>
                                    <option value="01">Janvier</option>
                                    <option value="02">Février</option>
                                    <option value="03">Mars</option>
                                    <option value="04">Avril</option>
                                    <option value="05">Mai</option>
                                    <option value="06">Juin</option>
                                    <option value="07">Juillet</option>
                                    <option value="08">Août</option>
                                    <option value="09">Septembre</option>
                                    <option value="10">Octobre</option>
                                    <option value="11">Novembre</option>
                                    <option value="12">Décembre</option>                                  
                                </select>
                                <select className="form-control date_naissance_a bs-select-hidden" style={{padding:"0px"}} disabled={this.state.disabled} onChange={this.ChangeDateFinA}>
                                    <option value=""  disabled selected hidden>Anneé</option>
                                    {this.state.annee_fin}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex align-items-center">
                            <input type="checkbox" className="_encours" id={this.state.id} style={{opacity: 0}} onChange={this.ChangeEnCours} />
                            <label for={this.state.id}>En cours</label>      
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <label>Description<span className="star">*</span></label>
                    <textarea className="form-control input-required" placeholder='Veuillez ajouter une description de ce poste(Pourquoi êtes-vous le candidat idéal ? Qu’est-ce qui vous démarque des autres candidats ? Quels sont vos qualités uniques ?)' onChange={this.ChangeDescription} rows={14}>{this.state.description}</textarea>
                </div>
            </div>
        </>);  
    }
}

class Formations extends Component
{
    constructor (props) {
        super(props)
        const currentYear= new Date().getFullYear(); 
        const annee =[];
        for (let index = currentYear; index >= 1900; index--) {
            annee.push(<option value={index}>{index}</option>); 
        }
        const anneefin =[];
        for (let index = currentYear; index >= 1900; index--) {
            anneefin.push(<option value={index}>{index}</option>); 
        }
        this.state={
            id:"f"+props.formation.id,
            diplome:props.formation.diplome,
            etablissement:props.formation.etablissement,
            data_debM:props.formation.data_debM,
            data_debA:props.formation.data_debA,
            data_finM:props.formation.data_finM,
            data_finA:props.formation.data_finA,
            encours:props.formation.encours,
            disabled:"",
            annee:annee,
            anneefin:anneefin,
            currentYear:currentYear
        }
    }
    ChangeDiplome = (e)=>{
        this.setState({diplome:e.target.value});
        this.props.update({
            "id":this.props.formation.id,
            "diplome":e.target.value,
            "etablissement":this.state.etablissement,
            "data_debM":this.state.data_debM,
            "data_debA":this.state.data_debA,
            "encours":this.state.encours,
            "data_finM":this.state.data_finM,
            "data_finA":this.state.data_finA,
        });
    }
    ChangeEtablissement = (e)=>{
        this.setState({etablissement:e.target.value});
        this.props.update({
            "id":this.props.formation.id,
            "diplome":this.state.diplome,
            "etablissement":e.target.value,
            "data_debM":this.state.data_debM,
            "data_debA":this.state.data_debA,
            "encours":this.state.encours,
            "data_finM":this.state.data_finM,
            "data_finA":this.state.data_finA,
        });
    }
    ChangeDateDebM = (e)=>{
        this.setState({data_debM:e.target.value});
        this.props.update({
            "id":this.props.formation.id,
            "diplome":this.state.diplome,
            "etablissement":this.state.etablissement,
            "data_debM":e.target.value,
            "data_debA":this.state.data_debA,
            "encours":this.state.encours,
            "data_finM":this.state.data_finM,
            "data_finA":this.state.data_finA,
        });
    }
    ChangeDateDebA = (e)=>{
        const anneefin =[];
        for (let index = this.state.currentYear; index >= e.target.value; index--) {
            anneefin.push(<option value={index}>{index}</option>); 
        }
        this.setState({
            data_debA:e.target.value,
            anneefin:anneefin
        });
        this.props.update({
            "id":this.props.formation.id,
            "diplome":this.state.diplome,
            "etablissement":this.state.etablissement,
            "data_debM":this.state.data_debM,
            "data_debA":e.target.value,
            "encours":this.state.encours,
            "data_finM":this.state.data_finM,
            "data_finA":this.state.data_finA,
        });
        
    }
    ChangeDateFinM = (e)=>{
        this.setState({data_finM:e.target.value});
        this.props.update({
            "id":this.props.formation.id,
            "diplome":this.state.diplome,
            "etablissement":this.state.etablissement,
            "data_debM":this.state.data_debM,
            "data_debA":this.state.data_debA,
            "encours":this.state.encours,
            "data_finM":e.target.value,
            "data_finA":this.state.data_finA,
        });
    }
    ChangeDateFinA = (e)=>{
        this.setState({data_finA:e.target.value});
        this.props.update({
            "id":this.props.formation.id,
            "diplome":this.state.diplome,
            "etablissement":this.state.etablissement,
            "data_debM":this.state.data_debM,
            "data_debA":this.state.data_debA,
            "encours":this.state.encours,
            "data_finM":this.state.data_finM,
            "data_finA":e.target.value,
        });
    }
    ChangeEnCours = (e)=>{
        var encours ="non";
        if(e.target.checked){
            this.setState({encours:"oui",disabled:"disabled"});
            encours ="oui";
        }else{
            this.setState({encours:"non",disabled:""});
            encours ="non";
        }
        this.props.update({
            "id":this.props.formation.id,
            "diplome":this.state.diplome,
            "etablissement":this.state.etablissement,
            "data_debM":this.state.data_debM,
            "data_debA":this.state.data_debA,
            "encours":encours,
            "data_finM":this.state.data_finM,
            "data_finA":this.state.data_finA,
        });
    }
    render() {
        
        return (<>
            <div className="form-group row" id={"formation_"+this.props.formation.id}>
                <div className="col-md-6">
                    <label>Diplôme obtenu<span className="star">*:</span></label>
                    <input value={this.state.diplome} type="text" className="form-control input-required" onChange={this.ChangeDiplome} placeholder="Veuillez saisir votre diplôme obtenu"/> 
                </div>
                <div className="col-md-6">
                    <label>Etablissement<span className="star">*:</span></label>
                    <input value={this.state.etablissement} type="text" className="form-control input-required" onChange={this.ChangeEtablissement} placeholder="Veuillez saisir votre établissement"/> 
                </div>
                <div className="col-md-12 ml-2 mt-2">
                    <div className="row">
                        <div className="col-md-2 px-2 d-flex align-items-center">
                            <label>Période<span className="star">*</span>: De</label>
                        </div>
                        <div className="col-md-3 pl-0 leftcontact d-flex align-items-center border-0">
                            <select className="form-control input-required bs-select-hidden" style={{width: '49%', margin: '5px', display: 'block !important'}} onChange={this.ChangeDateDebM}>
                                <option value="">Mois</option>
                                <option value="1">Janvier</option>
                                <option value="2">Février</option>
                                <option value="3">Mars</option>
                                <option value="4">Avril</option>
                                <option value="5">Mai</option>
                                <option value="6">Juin</option>
                                <option value="7">Juillet</option>
                                <option value="8">Août</option>
                                <option value="9">Septembre</option>
                                <option value="10">Octobre</option>
                                <option value="11">Novembre</option>
                                <option value="12">Décembre</option>                                  
                            </select>
                            <select className="form-control input-required bs-select-hidden" style={{width: '49%', margin: '5px', display: 'block !important'}} onChange={this.ChangeDateDebA}>
                                <option value="">Anneé</option>
                                {this.state.annee}
                            </select>
                        </div>
                        <div className="col-md-1 pr-0 d-flex align-items-center">
                            <label>Au</label>
                        </div>
                        <div className="col-md-3 rightcontact d-flex align-items-center pl-0">
                            <select className="form-control f_datafin1 bs-select-hidden" style={{width: '49%', margin: '5px', display: 'block !important'}} disabled={this.state.disabled} onChange={this.ChangeDateFinM}>
                                <option value>Mois</option>
                                <option value="1">Janvier</option>
                                <option value="2">Février</option>
                                <option value="3">Mars</option>
                                <option value="4">Avril</option>
                                <option value="5">Mai</option>
                                <option value="6">Juin</option>
                                <option value="7">Juillet</option>
                                <option value="8">Août</option>
                                <option value="9">Septembre</option>
                                <option value="10">Octobre</option>
                                <option value="11">Novembre</option>
                                <option value="12">Décembre</option>                             
                            </select>
                            <select className="form-control f_datafin1 bs-select-hidden" style={{width: '49%', margin: '5px', display: 'block !important'}} disabled={this.state.disabled} onChange={this.ChangeDateFinA}>
                                <option value>Anneé</option>
                                {this.state.anneefin}
                            </select>
                        </div>
                        <div className="col-md-3 d-flex align-items-center">
                            <input type="checkbox" className="f_encours" id={this.state.id} style={{opacity: 0}} onChange={this.ChangeEnCours}/><label for={this.state.id}>En cours</label>      
                        </div>
                    </div>
                </div>
            </div>
        </>);  
    }
}



