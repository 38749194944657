import http from "./httpService";
import { apiUrl } from  "../config.json";

const getTestOnline = (postid) => {
    const apiEndoint =  apiUrl+"/testOnline/getTestOnline.php";
    const formData = new FormData();
    formData.append("postid", postid);
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}
const registerTestOnline = (postid,reponse) => {
    const apiEndoint =  apiUrl+"/testOnline/registerTestOnline.php";
    const formData = new FormData();
    formData.append("postid", postid);
    formData.append("reponse", reponse);
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}

const getPostByID = (postid) => {
    const apiEndoint =  apiUrl+"/testOnline/getPostByID.php";
    const formData = new FormData();
    formData.append("postid", postid);
    formData.append("token", localStorage.getItem("tokenb2b"));
    return http.post(apiEndoint,formData);
}
export default 
{
    getTestOnline,
    registerTestOnline,
    getPostByID
}