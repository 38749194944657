import axios from "axios";

axios.interceptors.response.use(null,error=> {
    const expectedError = 
    error.response &&
    error.response.status >= 400 && 
    error.response.status < 500;
    var div_hors_ligne_b2b = document.getElementById("div_hors_ligne_b2b");
    if(!expectedError){
        console.log(error);
        if(div_hors_ligne_b2b){
            div_hors_ligne_b2b.style.display="block";
        }
    }else{
        if(div_hors_ligne_b2b){
            div_hors_ligne_b2b.style.display="none";
        }
    }
    return Promise.reject(error);
});
export default {
    get : axios.get,
    post : axios.post,
    put : axios.put,
    delete : axios.delete
}